import { Input, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

import { clearSearch, setSearchQuery } from '../../../actions/searchActions';
import Icon from '../Icon';
import * as T from '../Typography';
import * as S from './style';

import MaxWidthContainer from '../MaxWidthContainer';

import * as Yup from 'yup';

const { Search } = Input;
const { Option } = Select;

const SearchResultsMessage = ({
  hasSearchResults,
  categorySearchState,
  loading,
  hasNoResults,
  hasError
}) => {
  const resultsCount = categorySearchState?.data?.length;

  return (
    <div>
      {hasSearchResults && (
        <T.P color="gray">
          {resultsCount} result{resultsCount !== 1 ? 's' : ''} found:
        </T.P>
      )}
      {loading && <T.P>Loading...</T.P>}
      {hasNoResults && <T.P>No results found</T.P>}
      {hasError && (
        <T.P color="error">
          There was an error fetching the data: {categorySearchState?.error}
        </T.P>
      )}
    </div>
  );
};

const Title = ({ title, isDesktop, color }) => {
  const TitleComponent = isDesktop ? T.H2W : T.H3W;
  const SpanFontSize = isDesktop ? '32px' : '24px';

  return (
    <S.TitleWrapper>
      <TitleComponent mb={5}>
        {title}
        <span style={{ color, fontSize: SpanFontSize }}>...</span>
      </TitleComponent>
    </S.TitleWrapper>
  );
};

const SearchLayoutWrapper = ({ children, fullWidth }) =>
  !fullWidth ? (
    <MaxWidthContainer>{children}</MaxWidthContainer>
  ) : (
    <>{children}</>
  );

const SearchFilter = ({
  setSearchQueryAction,
  clearSearchAction,
  loading,
  category,
  filters,
  title,
  showSearch,
  showFilters,
  searchState,
  additionalFilters = {},
  searchBarTransparentBg,
  fullWidth = false,
  onlyEmployerOrgEvents,
  employerOrgId
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilters, setSelectedFilters] = useState({}); // State to manage selected filters
  const [validationErr, setValidationErr] = useState(null);
  const { colors, breakpoints } = useTheme();

  const isDesktop = useMediaQuery({
    query: breakpoints.laptop
  });
  const searchStringSchema = Yup.string()
    .min(2, 'Search query must be at least 2 characters')
    .max(150, 'Search query must be at most 50 characters');

  const triggerSearchQueryAction = useCallback(() => {
    const formattedFilters = Object.entries(selectedFilters).reduce(
      (acc, [filterName, value]) => {
        let formattedFilter = {
          [filterName]: value
        };

        const filter = filters.find(_filter => _filter.name === filterName);

        if (filter.customMapping) {
          formattedFilter = {
            [filter.customMapping.fieldName]:
              filter.customMapping.valueMap[value]
          };
        }

        return {
          ...acc,
          ...formattedFilter
        };
      },
      {}
    );

    const _filters = {
      ...formattedFilters,
      ...additionalFilters
    };

    if (!searchQuery && !Object.entries(_filters).length) {
      return clearSearchAction(category);
    }

    setSearchQueryAction({
      query: searchQuery,
      filters: _filters,
      category,
      onlyEmployerOrgEvents,
      employerOrgId
    });
  }, [
    additionalFilters,
    category,
    filters,
    searchQuery,
    selectedFilters,
    setSearchQueryAction,
    clearSearchAction,
    onlyEmployerOrgEvents,
    employerOrgId
  ]);

  const handleSearch = () => {
    try {
      setValidationErr(null);
      searchStringSchema.validateSync(searchQuery);
      triggerSearchQueryAction();
    } catch (error) {
      if (error.name === 'ValidationError') {
        setValidationErr(error.message);
      }
      return false;
    }
  };

  const handleFilterChange = (filterName, values) => {
    const selectedValues = Array.isArray(values) ? values : [values];
    setSelectedFilters(prevFilters => {
      const newFilters = { ...prevFilters, [filterName]: selectedValues };
      if (selectedValues.length === 0) {
        delete newFilters[filterName];
      }

      return newFilters;
    });
  };

  const handleClearFilters = useCallback(() => {
    clearSearchAction(category);
    setSearchQuery('');
    setSelectedFilters({});
    setValidationErr(null);
  }, [category, clearSearchAction]);

  useEffect(() => {
    return () => {
      handleClearFilters();
    };
  }, [handleClearFilters]);

  useEffect(() => {
    if (Object.keys(selectedFilters).length !== 0) {
      triggerSearchQueryAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  const categorySearchState = searchState?.[category];
  const hasSearchResults = categorySearchState?.msg === 'search_success';
  const hasNoResults = categorySearchState?.msg === 'search_no_results';
  const hasError = categorySearchState?.msg === 'search_failed';

  return (
    <S.Wrapper>
      {title && (
        <Title title={title} isDesktop={isDesktop} color={colors.mainPrimary} />
      )}
      {showSearch && (
        <S.SearchContainer
          searchBarTransparentBg={searchBarTransparentBg}
          fullWidth={fullWidth}
        >
          <SearchLayoutWrapper fullWidth={fullWidth}>
            <Search
              loading={loading}
              placeholder="Type..."
              enterButton="Search"
              size="large"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              onSearch={handleSearch}
              prefix={<Icon icon="search" />}
            />
            {validationErr && (
              <T.P mt="2" color="error">
                {validationErr}
              </T.P>
            )}
          </SearchLayoutWrapper>
        </S.SearchContainer>
      )}

      {showFilters && (
        <SearchLayoutWrapper fullWidth={fullWidth}>
          <S.FilterContainer fullWidth={fullWidth}>
            <S.DropdownsWrapper fullWidth={fullWidth}>
              {filters.map(filter => (
                <Select
                  mode={filter?.multi ? 'multiple' : 'default'}
                  placeholder={`Select ${filter.label}`}
                  value={selectedFilters[filter.name] || []}
                  onChange={values => handleFilterChange(filter.name, values)}
                  // onBlur={handleFilterBlur}
                  key={filter.name}
                  showArrow
                  style={{ maxWidth: '380px' }}
                  maxTagTextLength={8}
                  maxTagCount={2}
                >
                  {filter.options.map(({ label, value }) => (
                    <Option key={label} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              ))}
            </S.DropdownsWrapper>
            <S.ClearFiltersButton
              onClick={handleClearFilters}
              aria-label="Clear Results"
            >
              Clear Results
            </S.ClearFiltersButton>
          </S.FilterContainer>
          <SearchResultsMessage
            hasSearchResults={hasSearchResults}
            categorySearchState={categorySearchState}
            loading={loading}
            hasNoResults={hasNoResults}
            hasError={hasError}
          />
        </SearchLayoutWrapper>
      )}
    </S.Wrapper>
  );
};

SearchFilter.propTypes = {
  setSearchQueryAction: PropTypes.func.isRequired,
  clearFiltersAction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  category: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.string).isRequired,
      multi: PropTypes.bool
    })
  ),
  showSearch: PropTypes.bool.isRequired,
  showFilters: PropTypes.bool.isRequired
};

const mapActionsToProps = {
  setSearchQueryAction: setSearchQuery,
  clearSearchAction: clearSearch
};
const mapStateToProps = state => ({
  loading: state.loading.searchLoading,
  searchState: state.search
});

export default connect(mapStateToProps, mapActionsToProps)(SearchFilter);
