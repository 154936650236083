import { useReducer, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';
import { Modal as AntModal, message, Spin, Icon as AntIcon } from 'antd';
import Icon from '../../common/Icon';
import * as S from './style.js';
import {
  updateMyAccount,
  checkAuth as checkAuthAction
} from '../../../actions/authAction';
import {
  generateAuthCode,
  fetchEmployerOrganisations
} from '../../../actions/employerOrganisationActions';
import { fetchProgrammes } from '../../../actions/programmesActions';

import Layout from '../../Layouts';
import Button from '../../common/Button';
import AccountPageHeader from '../../common/AccountPageHeader';
import * as CompleteYourAccount from '../CompleteYourAccount/CompleteYourAccount.style';

import { BasicInput, Dropdown, Checkbox, TextArea } from '../../common/Inputs';
import { updateMyAccount as validate } from '../../../validation/schemas';
import { deleteAccountAction } from '../../../actions/deleteAccountAction';
import {
  userRoles,
  tpOrgTypes,
  employerOrgTypes,
  organisationSizes,
  organisationLocations,
  otherLocationWording
} from '../../../constants';
import {
  cleanEmail,
  extractLocationExtra,
  shouldLocationHaveExtra
} from '../../../helpers';

const stateReducer = (state, newState) => {
  return { ...state, ...newState };
};

const OTHER_ORG_LABEL = 'Other - specify below';

const getOrgData = (role, tpOrganisationData, employerOrganisationData) => {
  let orgData = {};
  let orgTypes = [];
  let otherOrgTypeWording = '';
  if (role === userRoles.tpOrgAdmin) {
    orgData = tpOrganisationData || {};
    orgTypes = tpOrgTypes;
    otherOrgTypeWording = tpOrgTypes.OTHER_PLEASE_STATE;
  } else if (role === userRoles.employerOrgAdmin) {
    orgData = employerOrganisationData || {};
    orgTypes = employerOrgTypes;
    otherOrgTypeWording = employerOrgTypes.OTHER_PLEASE_STATE;
  }

  const isOrgTypeOther =
    orgData.type &&
    (orgData.type === otherOrgTypeWording ||
      !Object.values(orgTypes).includes(orgData.type));

  return {
    orgTypes,
    orgData,
    otherOrgTypeWording,
    isOrgTypeOther
  };
};

const initialState = ({
  role,
  userEmail,
  userName,
  userPhone,
  userOrganisation = {},
  userLocalAuthorities = [],
  userJobRole,
  userTeam,
  userDepartment,
  userNewsletterSubscription,
  employerOrganisation,
  userEmployerOrgAuthCode,
  userFinanceContactEmails,
  userFinanceContactInfo,
  tpOrganisationData,
  employerOrganisationData,
  userProgrammesCanDeliver
}) => {
  const { orgData, otherOrgTypeWording, isOrgTypeOther } = getOrgData(
    role,
    tpOrganisationData,
    employerOrganisationData
  );

  const extractedLocationOther = extractLocationExtra(
    employerOrganisationData?.location
  );

  const formattedLocation =
    employerOrganisationData?.location ===
    organisationLocations.OTHER_PLEASE_SPECIFY_BELOW
      ? organisationLocations.OTHER_PLEASE_SPECIFY_BELOW
      : extractedLocationOther
      ? employerOrganisationData?.location?.replace(
          ` - ${extractedLocationOther}`,
          ''
        )
      : employerOrganisationData?.location;

  const isLocationOther =
    employerOrganisationData?.location &&
    !Object.values(organisationLocations).includes(formattedLocation);

  return {
    email: userEmail,
    name: userName,
    phone: userPhone,
    localAuthorities: userLocalAuthorities,
    organisation: userOrganisation,
    employerOrganisation:
      userOrganisation?.category === 'custom'
        ? OTHER_ORG_LABEL
        : employerOrganisation,
    organisationOther:
      userOrganisation?.category === 'custom' ? userOrganisation?.value : '',
    submitAttempt: false,
    validationErrors: {},
    showOrgOther: userOrganisation?.category === 'custom',
    updated: false,
    getLoading: true,
    jobRole: userJobRole,
    team: userTeam,
    department: userDepartment,
    newsletterSubscription: userNewsletterSubscription,
    employerOrgAuthCode: userEmployerOrgAuthCode,
    financeContactEmails: userFinanceContactEmails,
    financeContactInfo: userFinanceContactInfo,
    orgName: orgData.name,
    orgType: isOrgTypeOther ? otherOrgTypeWording : orgData.type,
    otherOrgType: isOrgTypeOther ? orgData.type : '',
    orgSize: orgData.size,
    location: isLocationOther
      ? organisationLocations.OTHER_PLEASE_SPECIFY_BELOW
      : formattedLocation,
    locationOther: extractedLocationOther || formattedLocation,
    staffContactEmail: employerOrganisationData?.staffContactEmail,
    programmesCanDeliver: userProgrammesCanDeliver
  };
};

const { confirm } = AntModal;
const MyAccount = ({
  userEmail,
  userName,
  userPhone,
  loading,
  updateMyAccount,
  httpError,
  deleteAccountAction: deleteAccountActionCreator,
  deleteAccountLoading,
  credentialId,
  userOrganisation = {},
  userLocalAuthorities = [],
  role,
  checkAuthAction,
  userJobRole,
  userTeam,
  userDepartment,
  userNewsletterSubscription,
  fetchEmployerOrganisations,
  employerOrganisations,
  employerOrganisation: _employerOrganisation,
  generateAuthCode,
  userEmployerOrgAuthCode,
  userFinanceContactEmails,
  userFinanceContactInfo,
  tpOrganisationData,
  employerOrganisationData,
  userProgrammesCanDeliver,
  programmes,
  fetchProgrammesAction,
  roles
}) => {
  const [state, setState] = useReducer(
    stateReducer,
    initialState({
      role,
      userEmail,
      userName,
      userPhone,
      userOrganisation,
      userLocalAuthorities,
      userJobRole,
      userTeam,
      userDepartment,
      userNewsletterSubscription,
      employerOrganisation: _employerOrganisation,
      userEmployerOrgAuthCode,
      userFinanceContactEmails,
      userFinanceContactInfo,
      tpOrganisationData,
      employerOrganisationData,
      userProgrammesCanDeliver
    })
  );
  const {
    email,
    name,
    phone,
    localAuthorities,
    organisation,
    organisationOther,
    submitAttempt,
    validationErrors,
    showOrgOther,
    getLoading,
    jobRole,
    team,
    department,
    newsletterSubscription,
    employerOrganisation,
    employerOrgAuthCode,
    financeContactEmails,
    financeContactInfo,
    orgName,
    orgType,
    otherOrgType,
    orgSize,
    location,
    locationOther,
    staffContactEmail,
    programmesCanDeliver
  } = state;
  const [authCodeReloading, setAuthCodeReloading] = useState(false);
  const history = useHistory();
  const validateForm = () => {
    try {
      validate({
        email: cleanEmail(email),
        role,
        name,
        phone,
        organisation,
        localAuthorities,
        showOrgOther,
        organisationOther,
        jobRole,
        team,
        department,
        newsletterSubscription,
        employerOrgAuthCode,
        financeContactEmails,
        financeContactInfo,
        orgName,
        orgType,
        otherOrgType,
        orgSize,
        location,
        locationOther,
        staffContactEmail,
        programmesCanDeliver
      });

      setState({ validationErrors: {} });

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrors: error.inner });
      }
      return false;
    }
  };
  useEffect(() => {
    const getInfo = async () => {
      await checkAuthAction();
      setState({ getLoading: true });
    };
    getInfo();
  }, [checkAuthAction]);

  useEffect(() => {
    if (submitAttempt) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    email,
    phone,
    localAuthorities,
    organisationOther,
    jobRole,
    orgName,
    orgType,
    otherOrgType,
    orgSize,
    location,
    locationOther,
    financeContactEmails,
    financeContactInfo
  ]);

  const handleSubmit = e => {
    e.preventDefault();
    setState({ submitAttempt: true });

    const isValid = validateForm();
    if (isValid) {
      handleUpdate();
    }
  };

  const handleAddNewEmployerOrganisation = e => {
    setState({
      employerOrganisation: OTHER_ORG_LABEL,
      showOrgOther: true
    });
  };

  const handleUpdate = async e => {
    setState({ loading: true });

    const updatedOrgData = {
      name: orgName,
      type: orgType,
      size: orgSize
    };
    const { isOrgTypeOther } = getOrgData(role, updatedOrgData, {
      ...updatedOrgData,
      location
    });
    const isLocationOther =
      location === organisationLocations.OTHER_PLEASE_SPECIFY_BELOW;

    await updateMyAccount({
      email: cleanEmail(email),
      name,
      phone,
      employerOrganisation:
        employerOrganisation === OTHER_ORG_LABEL
          ? undefined
          : employerOrganisation,
      organisation: organisationOther &&
        employerOrganisation === OTHER_ORG_LABEL && {
          value: organisationOther,
          category: 'custom'
        },
      localAuthorities,
      jobRole,
      team,
      department,
      newsletterSubscription,
      financeContactEmails,
      financeContactInfo,
      employerOrgAuthCode,
      orgName,
      orgType: isOrgTypeOther ? otherOrgType : orgType,
      orgSize,
      location: isLocationOther
        ? locationOther
        : shouldLocationHaveExtra(location)
        ? `${location} - ${locationOther}`
        : location,
      staffContactEmail,
      programmesCanDeliver,
      role
    });
    message.success(
      'Done!, Your account details have been updated successfully'
    );
  };

  const deleteAccount = () => {
    confirm({
      title: 'Are you sure that you want to delete your account?',
      content:
        'By doing this you will not be able to log into any of your Connect 5 accounts. This cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      okButtonProps: {
        loading: deleteAccountLoading
      },
      loading: true,
      onOk() {
        deleteAccountActionCreator(credentialId, history);
      },
      centered: true,
      icon: null
    });
  };

  useEffect(() => {
    fetchEmployerOrganisations({ access: 'public' });
  }, [fetchEmployerOrganisations]);

  const { orgTypes, otherOrgTypeWording } = getOrgData(
    role,
    tpOrganisationData,
    _employerOrganisation
  );
  const orgTypesOptions = Object.values(orgTypes).map(type => ({
    value: type,
    label: type
  }));
  const organisationSizesOptions = Object.values(organisationSizes).map(
    size => ({
      value: size,
      label: size
    })
  );
  const organisationLocationsOptions = Object.values(organisationLocations).map(
    type => {
      if (shouldLocationHaveExtra(type)) {
        return {
          value: type,
          label: `${type} - ${otherLocationWording}`
        };
      }
      return {
        value: type,
        label: type
      };
    }
  );

  useEffect(() => {
    fetchProgrammesAction();
  }, [fetchProgrammesAction]);

  const handleReloadAuthCode = async () => {
    setAuthCodeReloading(true);
    const authCode = await generateAuthCode();

    if (authCode) {
      setState({ employerOrgAuthCode: authCode });
    }
    setAuthCodeReloading(false);
  };

  const handleChangeFinanceContactEmail = ({ email, index }) => {
    const newFinanceContactEmails = [...financeContactEmails];
    const cleanedEmail = cleanEmail(email);
    newFinanceContactEmails[index] = cleanedEmail;

    setState({
      financeContactEmails: newFinanceContactEmails
    });
  };

  const handleRemoveFinanceContactEmail = index => {
    const newFinanceContactEmails = financeContactEmails.filter(
      (item, i) => i !== index
    );
    setState({
      financeContactEmails: newFinanceContactEmails
    });
  };
  const handleAddAnotherFinanceContactEmail = () => {
    setState({
      financeContactEmails: [...financeContactEmails, '']
    });
  };
  return (
    <Layout>
      <AccountPageHeader title="My Account Details" />

      <Row>
        <Col w={[4, 12, 6]}>
          <BasicInput
            value={name}
            handleChange={value => setState({ name: value })}
            label="Name"
            placeholder="Type your name here"
            required
            mb={6}
            error={validationErrors.name}
          />
        </Col>
        <Row>
          <Col w={[4, 12, 6]}>
            <BasicInput
              value={email}
              handleChange={value => setState({ email: value })}
              label="Email"
              id="email"
              required
              placeholder="Type your email here"
              mb={6}
              error={
                validationErrors.email ||
                (httpError?.response?.status === 409 &&
                  httpError?.response?.data?.error)
              }
            />
          </Col>
        </Row>
        {role === userRoles.participant && (
          <>
            <Row>
              <Col w={[4, 12, 6]}>
                <BasicInput
                  value={phone}
                  handleChange={value => setState({ phone: value })}
                  mb={3}
                  placeholder="Phone"
                  label="Phone"
                  required
                  error={validationErrors.phone}
                />
              </Col>
            </Row>
            <Row>
              <Col w={[4, 12, 6]}>
                <BasicInput
                  value={jobRole}
                  handleChange={value => setState({ jobRole: value })}
                  mb={3}
                  label="Job Title"
                  placeholder="Job title"
                  required
                  error={validationErrors.jobRole}
                />
              </Col>
            </Row>
          </>
        )}
      </Row>
      <Row>
        {[userRoles.trainer, userRoles.participant].includes(role) && (
          <Col w={[4, 12, 6]}>
            <ConfigProvider renderEmpty={() => null}>
              <Dropdown
                m={{ mb: '4' }}
                label="Organisation"
                placeholder="Select"
                required
                options={employerOrganisations?.map(employerOrganisation => ({
                  label: employerOrganisation.name,
                  value: employerOrganisation._id.toString()
                }))}
                multi={false}
                selected={employerOrganisation}
                handleChange={employerOrganisation =>
                  setState({ employerOrganisation, showOrgOther: false })
                }
                showSearch={true}
                dropdownRender={menu => (
                  <>
                    {menu}
                    <div
                      style={{
                        padding: '8px 12px',
                        cursor: 'pointer'
                      }}
                      onMouseDown={handleAddNewEmployerOrganisation}
                    >
                      Other - specify below
                    </div>
                  </>
                )}
                error={validationErrors.employerOrganisation}
              />
            </ConfigProvider>
            {showOrgOther && (
              <BasicInput
                value={organisationOther}
                autoFocus
                handleChange={value => setState({ organisationOther: value })}
                placeholder="Type organisation here..."
                mb={6}
                error={
                  validationErrors.organisation ||
                  validationErrors.organisationOther
                }
              />
            )}
          </Col>
        )}
        {role === userRoles.trainer && (
          <>
            <Col w={[4, 12, 6]}>
              <BasicInput
                value={jobRole}
                handleChange={value => setState({ jobRole: value })}
                mb={4}
                label="Job role"
                placeholder="Type your job role here"
                error={validationErrors.jobRole}
              />{' '}
            </Col>
            {![
              userRoles.admin,
              userRoles.programmeManager,
              userRoles.trainer
            ].includes(role) && (
              <>
                <Col w={[4, 12, 6]}>
                  <BasicInput
                    value={team}
                    handleChange={value => setState({ team: value })}
                    mb={4}
                    label="team"
                    placeholder="Type your team here"
                    error={validationErrors.team}
                  />{' '}
                </Col>
                <Col w={[4, 12, 6]}>
                  <BasicInput
                    value={department}
                    handleChange={value => setState({ department: value })}
                    mb={4}
                    label="Business Unit / Department"
                    placeholder="Type your Business Unit / Department  here"
                    error={validationErrors.department}
                  />{' '}
                </Col>
              </>
            )}
          </>
        )}
      </Row>
      <Row>
        <Checkbox
          checked={newsletterSubscription}
          label={<T.P color="gray">Subscribe to Boost monthly bulletin</T.P>}
          handleChange={value => setState({ newsletterSubscription: value })}
        />
      </Row>
      {roles?.includes(userRoles.trainer) && (
        <Row>
          <Col w={[4, 12, 8]}>
            <T.H3 mt={6} mb={5} color={'gray'}>
              Trainer Settings
            </T.H3>
            <Dropdown
              selected={programmesCanDeliver}
              m={{ mb: 4 }}
              label="Programme(s)"
              placeholder="Select"
              required
              options={programmes
                ?.sort((a, b) => a.titleShort.localeCompare(b.titleShort))
                ?.map(programme => ({
                  label: programme.titleShort,
                  value: programme._id.toString()
                }))}
              multi
              handleChange={value => setState({ programmesCanDeliver: value })}
              error={validationErrors.programmesCanDeliver}
            />
          </Col>
        </Row>
      )}

      {[userRoles.employerOrgAdmin, userRoles.tpOrgAdmin].includes(role) && (
        <Row>
          <Col w={[4, 12, 8]}>
            <T.H3 mt={6} mb={5} color={'gray'}>
              Organisation Settings
            </T.H3>

            {role === userRoles.employerOrgAdmin && (
              <>
                <S.AuthCodeContainer>
                  <T.P weight={600} mb={2}>
                    Paid-for Training Authorisation Code
                  </T.P>
                  <S.AuthCodeWrapper>
                    <S.AuthCodeDisplay>{employerOrgAuthCode}</S.AuthCodeDisplay>
                    <S.AuthCodeActions onClick={handleReloadAuthCode}>
                      {authCodeReloading && (
                        <Spin
                          indicator={
                            <AntIcon
                              type="loading"
                              style={{
                                fontSize: 24,
                                color: '#000',
                                marginLeft: '5px'
                              }}
                              spin
                            />
                          }
                        />
                      )}
                      {!authCodeReloading && (
                        <>
                          <Icon
                            icon="reload"
                            color={'darkGray'}
                            mb={'5px'}
                          ></Icon>
                          <T.P color="darkGray" small>
                            Refresh code
                          </T.P>
                        </>
                      )}
                    </S.AuthCodeActions>
                  </S.AuthCodeWrapper>
                  <T.P color="gray" small>
                    Please remember to notify your staff whenever you update
                    your authorisation code.
                  </T.P>
                </S.AuthCodeContainer>
              </>
            )}

            {[userRoles.tpOrgAdmin, userRoles.employerOrgAdmin].includes(
              role
            ) && (
              <>
                <BasicInput
                  value={orgName}
                  handleChange={value => setState({ orgName: value })}
                  mb={4}
                  label="Organisation Name"
                  placeholder="Type your organisation name here"
                  required
                  error={validationErrors.orgName}
                />
                <Dropdown
                  selected={orgType}
                  m={{ mb: 4 }}
                  label="Organisation Type"
                  placeholder="Select"
                  required
                  options={orgTypesOptions}
                  handleChange={value => setState({ orgType: value })}
                  error={validationErrors.orgType}
                />
                {orgType === otherOrgTypeWording && (
                  <BasicInput
                    value={otherOrgType}
                    handleChange={value => setState({ otherOrgType: value })}
                    mb={4}
                    mt="-15px"
                    placeholder="State your organisation type here"
                    required
                    error={validationErrors.otherOrgType}
                  />
                )}
                <Dropdown
                  selected={orgSize}
                  m={{ mb: 4 }}
                  label="Size of Organisation"
                  placeholder="Select"
                  required
                  options={organisationSizesOptions}
                  handleChange={value => setState({ orgSize: value })}
                  error={validationErrors.orgSize}
                />
              </>
            )}
            {role === userRoles.employerOrgAdmin && (
              <>
                <Dropdown
                  selected={location}
                  m={{ mb: 4 }}
                  label="Where Your Organisation Is Based"
                  placeholder="Select"
                  required
                  options={organisationLocationsOptions}
                  handleChange={value => setState({ location: value })}
                  error={validationErrors.location}
                />
                {(location ===
                  organisationLocations.OTHER_PLEASE_SPECIFY_BELOW ||
                  shouldLocationHaveExtra(location) ||
                  extractLocationExtra(location)) && (
                  <BasicInput
                    value={locationOther}
                    handleChange={value => setState({ locationOther: value })}
                    mb={4}
                    mt="-15px"
                    placeholder={
                      location ===
                      organisationLocations.OTHER_PLEASE_SPECIFY_BELOW
                        ? 'Specify here'
                        : 'Specify extra information here'
                    }
                    required
                    error={validationErrors.locationOther}
                  />
                )}
                <BasicInput
                  value={staffContactEmail}
                  handleChange={value => setState({ staffContactEmail: value })}
                  label="Staff Contact Email"
                  placeholder="Type staff contact email here"
                  mb={2}
                  required
                  error={validationErrors.staffContactEmail}
                />
                <T.P color="gray" small mb={4}>
                  This is the contact email your staff should reach out to if
                  they have questions about training.
                </T.P>
                {financeContactEmails?.map((financeEmail, index) => (
                  <CompleteYourAccount.InputWrapper
                    style={{ width: '100%' }}
                    key={`finance-contact-email-${index}`}
                  >
                    <BasicInput
                      value={financeContactEmails[index]}
                      handleChange={value =>
                        handleChangeFinanceContactEmail({ email: value, index })
                      }
                      label={`Finance Contact Email ${
                        index ? `#${index + 1}` : ''
                      }`}
                      placeholder="Type finance contact email here"
                      mb={2}
                      required={index === 0}
                      error={validationErrors[`financeContactEmails[${index}]`]}
                      autoComplete="off"
                    />
                    {index === 0 && (
                      <T.P color="gray" small mb={4}>
                        This enables Training Providers to contact you if you
                        register for any paid-for training events, such as
                        Oliver McGowan.
                      </T.P>
                    )}
                    {index !== 0 && (
                      <T.TextBtn
                        alignSelf="end"
                        onClick={() => handleRemoveFinanceContactEmail(index)}
                      >
                        Remove
                      </T.TextBtn>
                    )}
                  </CompleteYourAccount.InputWrapper>
                ))}
                <CompleteYourAccount.TextWithIcon
                  type="button"
                  onClick={handleAddAnotherFinanceContactEmail}
                  mb="5"
                >
                  <Icon icon="add" color="primary" />
                  <T.P color="darkGray" small weight={600}>
                    Add another finance contact email address
                  </T.P>
                </CompleteYourAccount.TextWithIcon>
                <TextArea
                  label="Add any useful finance contact information "
                  placeholder="e.g. We automatically cover paid-for training for Oliver McGowan and MECC courses. For any other training, please make sure you first send a request to the HR department on hr@yallacooperative.com."
                  handleChange={value =>
                    setState({ financeContactInfo: value })
                  }
                  value={financeContactInfo}
                  mb="6"
                  rows="3"
                  error={validationErrors.financeContactInfo}
                />
              </>
            )}
          </Col>
        </Row>
      )}

      <Row>
        <Col w={[4, 6, 6]} style={{ position: 'relative' }}>
          <Button
            onClick={handleSubmit}
            type="primary"
            label="Save changes"
            loading={loading}
            mt={5}
          />
          <S.DeleteAccountButton
            onClick={deleteAccount || getLoading}
            mt={8}
            color="gray"
          >
            Delete account
          </S.DeleteAccountButton>
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    httpError: state.auth.error,
    credentialId: state.auth.credentialId,
    userOrganisation: state.auth?.organisation,
    userLocalAuthorities: state.auth.localAuthorities,
    userEmail: state.auth.email,
    userName: state.auth.name,
    userPhone: state.auth.phone,
    employerOrganisation: state.auth.employerOrganisation,
    role: state.auth.role,
    loading: state.loading.updateMyAccount,
    userJobRole: state.auth.jobRole,
    userTeam: state.auth.team,
    userDepartment: state.auth.department,
    userNewsletterSubscription: state.auth.newsletterSubscription,
    employerOrganisations: state.employerOrganisations.employerOrganisations,
    userEmployerOrgAuthCode:
      state.auth.employerOrganisationData?.authorisationCode,
    userFinanceContactEmails: state.auth.employerOrganisationData
      ?.financeContactEmails || [''],
    userFinanceContactInfo:
      state.auth.employerOrganisationData?.financeContactInfo,
    tpOrganisationData: state.auth.tpOrganisationData,
    employerOrganisationData: state.auth.employerOrganisationData,
    userProgrammesCanDeliver: state.auth.programmesCanDeliver,
    programmes: state.programmes.programmes,
    roles: state.auth.userRoles
  };
};

export default connect(mapStateToProps, {
  updateMyAccount,
  deleteAccountAction,
  checkAuthAction,
  generateAuthCode,
  fetchProgrammesAction: fetchProgrammes,
  fetchEmployerOrganisations
})(MyAccount);
