import { connect, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../Layouts';
import AccountPageHeader from '../../common/AccountPageHeader';
import { fetchEmployerOrganisation } from '../../../actions/employerOrganisationActions';

import * as T from '../../common/Typography';
import { Checkbox } from '../../common/Inputs';
import Button from '../../common/Button';

import { DASHBOARD_URL } from '../../../constants/navigationRoutes';

import { CodeContainer, StyledTP, StyledNote } from './style';

function AuthCode({ fetchEmployerOrganisation }) {
  const employerOrganisations = useSelector(
    state => state.employerOrganisations
  );

  const employerOrganisation = useSelector(
    state => state.auth.employerOrganisation
  );

  const history = useHistory();

  useEffect(() => {
    fetchEmployerOrganisation(employerOrganisation);
  }, [fetchEmployerOrganisation, employerOrganisation]);

  const [checked, setIsChecked] = useState(false);

  const naviagteToDashboard = () => {
    history.push(DASHBOARD_URL);
  };

  return (
    <Layout>
      <AccountPageHeader title={'Authorising Paid-for Training'} />
      <div style={{ maxWidth: '550px' }}>
        <div>
          <T.P color="neutralGray" mb="2rem">
            For any open training events that require payment, your staff will
            be required to enter your organisation’s{' '}
            <strong>Unique Authorisation Code </strong>
            provided below.
          </T.P>
          <T.P color="neutralGray" mb="2rem">
            You can refresh this code whenever you like in your Account details.
          </T.P>
          <T.P color="neutralGray" mb="40px" weight={700}>
            Please remember to share this code with your staff.
          </T.P>
          <CodeContainer>
            <T.H1>
              {employerOrganisations?.employerOrganisation?.authorisationCode}
            </T.H1>
          </CodeContainer>
          <StyledTP color="darkGray" weight={600} mb={1}>
            If you like, please provide details for your staff on how you
            generally intend to cover paid-for training, such as whether you
            expect them to pay and put it in their expenses or whether you want
            them to notify anyone.
          </StyledTP>
          <StyledNote>
            <p>
              e.g. We automatically cover paid-for training for Oliver McGowan
              and MECC courses. For any other training, please make sure you
              first send a request tothe HR department on email address.
            </p>
          </StyledNote>
          <Checkbox
            mb={4}
            checked={checked}
            label="I understand how to use my authorisation code"
            handleChange={e => setIsChecked(prev => !prev)}
          />
          <div style={{ maxWidth: '200px' }}>
            <Button
              disabled={!checked}
              label={'Continue'}
              type="primary"
              onClick={naviagteToDashboard}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default connect(() => ({}), {
  fetchEmployerOrganisation: fetchEmployerOrganisation
})(AuthCode);
