const modulesCategories = {
  ALCOHOL_AWARENESS: 'ALCOHOL_AWARENESS',
  ALCOHOL_IDENTIFICATION: 'ALCOHOL_IDENTIFICATION',
  MANAGEMENT_OF_ALCOHOL: 'MANAGEMENT_OF_ALCOHOL',
  POVERTY_AWARENESS: 'POVERTY_AWARENESS',
  POVERTY_IDENTIFICATION_AND_SUPPORT: 'POVERTY_IDENTIFICATION_AND_SUPPORT',
  POVERTY_PROOFING_INTERVENTIONS: 'POVERTY_PROOFING_INTERVENTIONS',
  TOBACCO_AWARENESS: 'TOBACCO_AWARENESS',
  TOBACCO_IDENTIFICATION: 'TOBACCO_IDENTIFICATION',
  TOBACCO_TREATMENT: 'TOBACCO_TREATMENT',
  TOBACCO_CONTROL: 'TOBACCO_CONTROL'
};

export const tpOrgTypes = {
  PRIMARY_CARE_GP_SERVICES_AND_COMMUNITY_NURSING:
    'Primary Care - GP services and Community Nursing',
  SOCIAL_CARE_COMMUNITY_AND_SOCIAL_SERVICES:
    'Social Care - community and social services',
  SECONDARY_CARE_NHS_TRUSTS_AND_SERVICES:
    'Secondary Care - NHS Trusts and services',
  MENTAL_HEALTH_TRUSTS_AND_SERVICES: 'Mental Health Trusts and services',
  VOLUNTARY_COMMUNITY_AND_SOCIAL_ENTERPRISE_ORGANISATIONS:
    'voluntary, community and social enterprise organisations',
  LOCAL_AUTHORITY_AND_SERVICES: 'Local Authority and Services',
  NHS_ENGLAND: 'NHS England',
  EDUCATION_ORGANISATION: 'Education Organisation',
  OTHER_PLEASE_STATE: 'Other - please state'
};

export const employerOrgTypes = {
  PRIMARY_CARE_GP_SERVICES_AND_COMMUNITY_NURSING:
    'Primary Care - GP services and Community Nursing',
  SOCIAL_CARE_COMMUNITY_AND_SOCIAL_SERVICES:
    'Social Care - community and social services',
  SECONDARY_CARE_NHS_TRUSTS_AND_SERVICES:
    'Secondary Care - NHS Trusts and services',
  MENTAL_HEALTH_TRUSTS_AND_SERVICES: 'Mental Health Trusts and services',
  AMBULANCE_TRUST_AND_SERVICES: 'Ambulance Trust and Services',
  VOLUNTARY_ORGANISATIONS: 'Voluntary Organisations',
  CHARITABLE_ORGANISATIONS: 'Charitable Organisations',
  LOCAL_AUTHORITY_AND_SERVICES: 'Local Authority and Services',
  INTEGRATED_CARE_BOARD: 'Integrated Care Board',
  NHS_ENGLAND: 'NHS England',
  EDUCATION_ORGANISATION: 'Education Organisation',
  OTHER_PLEASE_STATE: 'Other - please state',
  I_AM_NOT_IN_WORK: 'I am not in work'
};

export const organisationSizes = {
  EMPLOYEES_1_TO_10: '1-10 employees',
  EMPLOYEES_11_TO_50: '11-50 employees',
  EMPLOYEES_51_TO_200: '51-200 employees',
  EMPLOYEES_201_TO_500: '201-500 employees',
  EMPLOYEES_501_TO_1000: '501-1,000 employees',
  EMPLOYEES_1001_TO_5000: '1,001-5,000 employees',
  EMPLOYEES_5000_PLUS: '5,000+ employees'
};

export const otherLocationWording = 'please specify below';
export const organisationLocations = {
  COUNTY_DURHAM_AND_DARLINGTON_NHS_FOUNDATION_TRUST:
    'County Durham and Darlington NHS Foundation Trust',
  CUMBERLAND_COUNCIL: 'Cumberland Council',
  CUMBRIA_NORTHUMBERLAND_TYNE_AND_WEAR_NHS_FOUNDATION_TRUST:
    'Cumbria, Northumberland, Tyne and Wear NHS Foundation Trust',
  DARLINGTON_BOROUGH_COUNCIL: 'Darlington Borough Council',
  DURHAM_COUNTY_COUNCIL: 'Durham County Council',
  GATESHEAD_COUNCIL: 'Gateshead Council',
  GATESHEAD_HEALTH_NHS_FOUNDATION_TRUST:
    'Gateshead Health NHS Foundation Trust',
  GP_PRACTICE_PLEASE_SPECIFY_BELOW: 'GP Practice',
  HARTLEPOOL_BOROUGH_COUNCIL: 'Hartlepool Borough Council',
  MEMBER_OF_THE_PUBLIC: 'Member of the public',
  MIDDLESBROUGH_COUNCIL: 'Middlesbrough Council',
  NEWCASTLE_CITY_COUNCIL: 'Newcastle City Council',
  NORTH_CUMBRIA_INTEGRATED_CARE_NHS_FOUNDATION_TRUST:
    'North Cumbria Integrated Care NHS Foundation Trust',
  NORTH_EAST_AMBULANCE_SERVICE: 'North East Ambulance Service',
  NORTH_EAST_AND_NORTH_CUMBRIA_ICB:
    'North East and North Cumbria ICB - please specify directorate below',
  CONTRACTING_AND_PROCUREMENT: 'Contracting and procurement',
  CORPORATE_GOVERNANCE_COMMUNICATIONS_AND_INVOLVEMENT:
    'Corporate governance, communications, and involvement',
  DIGITAL_AND_INFRASTRUCTURE: 'Digital and infrastructure',
  FINANCE: 'Finance',
  LOCAL_DELIVERY: 'Local delivery',
  MEDICAL: 'Medical',
  NURSING_AHP_AND_PEOPLE_AND_OD: 'Nursing, AHP, and people and OD',
  STRATEGY_AND_TRANSFORMATION: 'Strategy and transformation',
  NORTH_OF_ENGLAND_COMMISSIONING_SUPPORT_UNIT:
    'North of England Commissioning Support Unit',
  NORTH_TEES_AND_HARTLEPOOL_NHS_FOUNDATION_TRUST:
    'North Tees and Hartlepool NHS Foundation Trust',
  NORTH_TYNESIDE_COUNCIL: 'North Tyneside Council',
  NORTH_WEST_AMBULANCE_SERVICE: 'North West Ambulance Service',
  NORTHUMBERLAND_COUNTY_COUNCIL: 'Northumberland County Council',
  NORTHUMBRIA_HEALTHCARE_NHS_FOUNDATION_TRUST:
    'Northumbria Healthcare NHS Foundation Trust',
  REDCAR_AND_CLEVELAND_BOROUGH_COUNCIL: 'Redcar and Cleveland Borough Council',
  SOUTH_TEES_HOSPITALS_NHS_FOUNDATION_TRUST:
    'South Tees Hospitals NHS Foundation Trust',
  SOUTH_TYNESIDE_AND_SUNDERLAND_NHS_FOUNDATION_TRUST:
    'South Tyneside and Sunderland NHS Foundation Trust',
  SOUTH_TYNESIDE_COUNCIL: 'South Tyneside Council',
  STOCKTON_ON_TEES_BOROUGH_COUNCIL: 'Stockton-on-Tees Borough Council',
  SUNDERLAND_CITY_COUNCIL: 'Sunderland City Council',
  TEES_ESK_AND_WEAR_VALLEY_NHS_FOUNDATION_TRUST:
    'Tees, Esk and Wear Valley NHS Foundation Trust',
  THE_NEWCASTLE_UPON_TYNE_HOSPITALS_NHS_FOUNDATION_TRUST:
    'The Newcastle Upon Tyne Hospitals NHS Foundation Trust',
  UNIVERSITY_HOSPITALS_OF_MORECAMBE_BAY_NHS_FOUNDATION_TRUST:
    'University Hospitals of Morecambe Bay NHS Foundation Trust',
  VOLUNTARY_THIRD_SECTOR_ORGANISATION_PLEASE_SPECIFY_BELOW:
    'Voluntary/third sector organisation',
  WESTMORLAND_AND_FURNESS_COUNCIL: 'Westmorland and Furness Council',
  OTHER_PLEASE_SPECIFY_BELOW: 'Other - please specify below',
  HOUSING_ORGANISATION_PLEASE_SPECIFY_BELOW: 'Housing organisation',
  EDUCATION_FURTHER_HIGHER_PLEASE_SPECIFY_BELOW: 'Education (Further/Higher)',
  EDUCATION_SECONDARY_PLEASE_SPECIFY_BELOW: 'Education (Secondary)',
  EDUCATION_PRIMARY_PLEASE_SPECIFY_BELOW: 'Education (Primary)',
  EDUCATION_EARLY_YEARS_PLEASE_SPECIFY_BELOW: 'Education (Early Years)'
};

export default modulesCategories;
