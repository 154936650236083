import axios from 'axios';

import {
  LOADING_TRUE,
  LOADING_FALSE,
  FETCH_TP_ORGANISATIONS_SUCCESS,
  FETCH_TP_ORGANISATION_SUCCESS,
  FETCH_TP_ORGANISATION_FAIL,
  FETCH_TP_ORGANISATIONS_FAIL,
  CREATE_TP_ORGANISATION_SUCCESS,
  CREATE_TP_ORGANISATION_FAIL,
  UPDATE_TP_ORGANISATION_SUCCESS,
  UPDATE_TP_ORGANISATION_FAIL,
  UPDATE_MY_ACCOUNT_SUCCESS
} from '../constants/actionTypes';

export const fetchTPOrganisations = (options = {}) => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'fetchTPOrganisationsLoading'
    });
    const res = await axios.get('/api/tp-organisations', { params: options });
    const responseData = res.data[0];
    const data = responseData?.data || [];
    const total = responseData?.totalCount?.[0]?.count || 0;

    dispatch({
      type: FETCH_TP_ORGANISATIONS_SUCCESS,
      payload: { data, total }
    });
    return {};
  } catch (error) {
    dispatch({
      type: FETCH_TP_ORGANISATIONS_FAIL,
      payload: error.response.data.error
    });
    return { error };
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'fetchTPOrganisationsLoading'
    });
  }
};

export const fetchTPOrganisation = (id, options) => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'fetchTPOrganisationLoading'
    });
    const res = await axios.get(`/api/tp-organisations/${id}`, {
      params: options
    });
    dispatch({
      type: FETCH_TP_ORGANISATION_SUCCESS,
      payload: res.data
    });
    return {};
  } catch (error) {
    dispatch({
      type: FETCH_TP_ORGANISATION_FAIL,
      payload: error.response.data.error
    });
    return { error };
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'fetchTPOrganisationLoading'
    });
  }
};

export const createTPOrganisation = payload => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'createTPOrganisationLoading'
    });
    const res = await axios.post('/api/tp-organisations', payload);
    dispatch({
      type: CREATE_TP_ORGANISATION_SUCCESS,
      payload: res.data
    });
    return {};
  } catch (error) {
    dispatch({
      type: CREATE_TP_ORGANISATION_FAIL,
      payload: error.response.data.error
    });
    return { error };
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'createTPOrganisationLoading'
    });
  }
};

export const updateTPOrganisation = (id, payload) => async (
  dispatch,
  getState
) => {
  try {
    const authUser = getState().auth;

    dispatch({
      type: LOADING_TRUE,
      payload: 'updateTPOrganisationLoading'
    });
    const res = await axios.patch(`/api/tp-organisations/${id}`, payload);
    dispatch({
      type: UPDATE_TP_ORGANISATION_SUCCESS,
      payload: res.data
    });
    if (authUser?.tpOrganisation === id) {
      dispatch({
        type: UPDATE_MY_ACCOUNT_SUCCESS,
        payload: { ...authUser, tpOrganisationData: payload }
      });
    }
    return {};
  } catch (error) {
    dispatch({
      type: UPDATE_TP_ORGANISATION_FAIL,
      payload: error.response.data.error
    });
    return { error };
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'updateTPOrganisationLoading'
    });
  }
};
