import { useState, useEffect, useRef } from 'react';
import { Select as AntdSelect } from 'antd';
import * as T from '../../Typography';
import Icon from '../../Icon';
import * as S from './style';
import * as CS from './../style';

const { OptGroup: AntdOptGroup } = AntdSelect;

const Dropdown = ({
  handleChange,
  label,
  color,
  placeholder = 'Select...',
  error,
  helper,
  w,
  disabled,
  options = [],
  groupedOptions,
  selected,
  multi,
  m,
  bold,
  search,
  addNew,
  allowClear,
  name,
  required,
  customOptions,
  focused,
  assessmentOptions,
  showSearch,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);

  const selectRef = useRef(null);

  useEffect(() => {
    setFocus(focused);

    if (!focused) {
      selectRef?.current.blur();
    }
  }, [focused]);

  const decideColor = () => {
    if (error) return 'error';

    return color;
  };

  const isSelected = option =>
    selected === option || (selected && selected.toString().includes(option));

  const renderAssessmentOptions = () => {
    const optionsArray = options.map((opt, index) => (
      <S.Option
        key={`${index}_${opt.text}`}
        value={opt.text}
        isCorrect={opt.isCorrect}
      >
        {opt.text}
      </S.Option>
    ));

    return optionsArray;
  };

  const renderOptions = () => {
    if (groupedOptions) {
      return options.map(({ groupLabel, options: _options }) => (
        <AntdOptGroup
          label={
            <T.P m="0" mt="4" color="primary">
              {groupLabel}
            </T.P>
          }
          key={groupLabel}
        >
          {_options.map(opt => (
            <S.Option
              key={`${groupLabel}_${opt.label}`}
              value={opt.value}
              points={opt.points}
              isSelected={isSelected(opt.value)}
              {...options}
            >
              {opt.label}
            </S.Option>
          ))}
        </AntdOptGroup>
      ));
    }
    if (customOptions) {
      return options;
    }

    return options.map(options => {
      const { value: _value, label: _label } = options;

      return (
        <S.Option key={_value} value={_value} label={_label} {...options}>
          {_label}
        </S.Option>
      );
    });
  };

  const handleSearch = (input, option) => {
    return option.props.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const decideMode = () => {
    if (addNew) return 'tags';
    if (multi) return 'multiple';
    return;
  };

  return (
    <S.Field
      w={w}
      disabled={disabled}
      open={open}
      focus={focus}
      multi={multi}
      color={decideColor()}
      error={error}
      search={search}
      {...m}
    >
      {label && (
        <CS.Label htmlFor={name}>
          <T.P color={color} m="0" mb="2" weight="700">
            {required && '*'} {label}
          </T.P>
        </CS.Label>
      )}
      <S.Answer>
        <S.AntdSelectWrapper
          showSearch={showSearch}
          suffixIcon={<Icon icon="chevron" color="midGray" />}
          value={selected || undefined}
          onChange={(val, option) => handleChange(val, option, name)}
          mode={decideMode()}
          placeholder={placeholder || 'Type here...'}
          showArrow
          filterOption={addNew || handleSearch}
          onDropdownVisibleChange={open => setOpen(open)}
          disabled={disabled}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          ref={selectRef}
          allowClear={allowClear}
          id={name}
          aria-label={label || name || 'dropdown'}
          {...props}
        >
          {!assessmentOptions ? renderOptions() : renderAssessmentOptions()}
        </S.AntdSelectWrapper>
        {helper && (
          <T.P color={color} mt="2">
            {helper}
          </T.P>
        )}
        {error && (
          <T.P color="error" m="0" mt="1">
            {error}
          </T.P>
        )}
      </S.Answer>
    </S.Field>
  );
};

export default Dropdown;
