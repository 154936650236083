import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  fetchTPOrganisation,
  updateTPOrganisation
} from '../../../actions/tpOrganisationActions';
import useFormData from '../../../hooks/useFormData';
import useValidateFormData from '../../../hooks/useValidateFormData';
import validate from '../../../validation/schemas/completeYourAccount';
import {
  tpOrgTypes,
  organisationSizes,
  userRoles,
  navRoutes as R
} from '../../../constants';
import * as T from '../../common/Typography';
import Button from '../../common/Button';
import Spin from '../../common/Spin';
import { Col, Row } from '../../common/Grid';
import { BasicInput, Dropdown } from '../../common/Inputs';
import Layout from '../../Layouts';

import * as S from './CompleteYourAccount.style';

const organisationTypesOptions = Object.values(tpOrgTypes).map(type => ({
  value: type,
  label: type
}));
const organisationSizesOptions = Object.values(organisationSizes).map(size => ({
  value: size,
  label: size
}));

const initialFormState = {
  name: '',
  type: '',
  otherOrgType: '',
  size: ''
};

const TpOrganisation = ({
  authUser,
  httpError,
  fetchTPOrganisation,
  fetchTPOrganisationLoading,
  fetchedTPOrganisation,
  updateTPOrganisation,
  updateTPOrganisationLoading
}) => {
  const { formState, setFormData } = useFormData({ initialFormState });
  const submitAttempt = useRef(false);

  const {
    validateForm,
    validationErrors,
    finalSubmissionData
  } = useValidateFormData({
    formState: { ...formState, role: userRoles.tpOrgAdmin },
    validateFn: validate,
    submitAttempt
  });

  const history = useHistory();

  const { name, type, otherOrgType, size } = formState;

  useEffect(() => {
    if (fetchedTPOrganisation) {
      const isOrgTypeOther =
        fetchedTPOrganisation.type &&
        !Object.values(tpOrgTypes).includes(fetchedTPOrganisation.type);

      setFormData({
        name: fetchedTPOrganisation.name,
        type: isOrgTypeOther
          ? tpOrgTypes.OTHER_PLEASE_STATE
          : fetchedTPOrganisation.type,
        otherOrgType: isOrgTypeOther ? fetchedTPOrganisation.type : '',
        size: fetchedTPOrganisation.size
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedTPOrganisation]);

  useEffect(() => {
    if (authUser?.tpOrganisation) {
      fetchTPOrganisation(authUser.tpOrganisation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser?.tpOrganisation]);

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, type, otherOrgType, size]);

  const handleSubmit = e => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      handleSignup();
    }
  };

  const handleSignup = async () => {
    const { current: finalFormState } = finalSubmissionData;

    const isOrgTypeOther =
      finalFormState.type === tpOrgTypes.OTHER_PLEASE_STATE;

    const payload = {
      name: finalFormState.name,
      type: isOrgTypeOther ? finalFormState.otherOrgType : finalFormState.type,
      size: finalFormState.size,
      setupCompleted: true
    };

    const result = await updateTPOrganisation(
      fetchedTPOrganisation._id,
      payload
    );

    if (!result?.error) {
      history.push(R.TOUR_URL);
    }
  };

  if (fetchTPOrganisationLoading) {
    return <Spin />;
  }

  return (
    <Layout>
      <S.Wrapper className="sign-up">
        <Row>
          <Col w={[4, 12, 8]}>
            <S.ContentWrapper>
              <T.H1 mb="7">Complete Your Account</T.H1>
              <T.P weight={400} mb="6">
                To complete your organisation account, please answer the
                questions below.
              </T.P>
              <S.Form className="complete-your-account-form">
                <BasicInput
                  value={name}
                  handleChange={value => setFormData({ name: value })}
                  mb={4}
                  label="Organisation Name"
                  placeholder="Type your organisation name here"
                  required
                  error={validationErrors.name}
                />
                <Dropdown
                  selected={type}
                  m={{ mb: 4 }}
                  label="Organisation Type"
                  placeholder="Select"
                  required
                  options={organisationTypesOptions}
                  handleChange={value => setFormData({ type: value })}
                  error={validationErrors.type}
                />
                {type === tpOrgTypes.OTHER_PLEASE_STATE && (
                  <BasicInput
                    value={otherOrgType}
                    handleChange={value => setFormData({ otherOrgType: value })}
                    mb={4}
                    mt="-15px"
                    placeholder="State your organisation type here"
                    required
                    error={validationErrors.otherOrgType}
                  />
                )}
                <Dropdown
                  selected={size}
                  m={{ mb: 4 }}
                  label="Size of Organisation"
                  placeholder="Select"
                  required
                  options={organisationSizesOptions}
                  handleChange={value => setFormData({ size: value })}
                  error={validationErrors.size}
                />

                {httpError && (
                  <T.P color="error" mt={4}>
                    {httpError}
                  </T.P>
                )}
                <Button
                  mt={4}
                  onClick={handleSubmit}
                  type="primary"
                  label="Submit"
                  loading={updateTPOrganisationLoading}
                  disabled={
                    validationErrors.hasError || updateTPOrganisationLoading
                  }
                />
              </S.Form>
            </S.ContentWrapper>
          </Col>
        </Row>
      </S.Wrapper>
    </Layout>
  );
};

const mapStateToProps = state => ({
  authUser: state.auth,
  fetchTPOrganisationLoading: state.loading.fetchTPOrganisationLoading,
  fetchedTPOrganisation: state.tpOrganisations.tpOrganisation,
  updateTPOrganisationLoading: state.loading.updateTPOrganisationLoading,
  httpError: state.tpOrganisations.error
});

export default connect(mapStateToProps, {
  fetchTPOrganisation,
  updateTPOrganisation
})(TpOrganisation);
