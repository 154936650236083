import React from 'react';
import styled, { useTheme } from 'styled-components';
import setMargin from '../../../helpers/setMargin';
import * as T from '../Typography';

// ICONS
import Cross from './icons/Cross';
import Bin from './icons/Bin';
import Calendar from './icons/Calendar';
import Chevron from './icons/Chevron';
import CircleTick from './icons/CircleTick';
import Copy from './icons/Copy';
import Edit from './icons/Edit';
import Info from './icons/Info';
import Question from './icons/Question';
import Time from './icons/Time';
import Menu from './icons/Menu';
import Eye from './icons/Eye';
import EyeCross from './icons/EyeCross';
import Warning from './icons/Warning';
import Open from './icons/open';
import Glass from './icons/Glass';
import Glass2 from './icons/Glass2';
import Chat from './icons/Chat';
import Archive from './icons/Archive';
import Unarchive from './icons/Unarchive';
import Add from './icons/Add';
import Remove from './icons/Remove';
import OpenInNew from './icons/OpenInNew';
import Bulb from './icons/Bulb';
import Calculator from './icons/Calculator';
import Map from './icons/Map';
import Handshake from './icons/Handshake';
import Conversation from './icons/Conversation';
import Calendar2 from './icons/Calendar2';
import Upload from './icons/Upload';
import Search from './icons/Search';
import LevelBars from './icons/LevelBars';
import Clock from './icons/Clock';
import Bulb2 from './icons/Bulb2';
import BoostArrow from './icons/BoostArrow';
import BoostCog from './icons/BoostCog';
import BoostStar from './icons/BoostStar';
import BoostSpeechBubble from './icons/BoostSpeechBubble';
import BoostHome from './icons/BoostHome';
import Arrow from './icons/Arrow';
import Trash from './icons/Trash';
import Reload from './icons/Reload';

const Parent = styled.div`
  ${setMargin}
  display: inline-flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'flex-start'};
  vertical-align: top;
`;

export const IconMap = {
  cross: Cross,
  bin: Bin,
  calendar: Calendar,
  chevron: Chevron,
  circleTick: CircleTick,
  copy: Copy,
  edit: Edit,
  info: Info,
  question: Question,
  time: Time,
  menu: Menu,
  eye: Eye,
  eyeCross: EyeCross,
  warning: Warning,
  open: Open,
  chat: Chat,
  glass: Glass,
  glass2: Glass2,
  archive: Archive,
  unarchive: Unarchive,
  add: Add,
  remove: Remove,
  openInNew: OpenInNew,
  bulb: Bulb,
  bulb2: Bulb2,
  calculator: Calculator,
  map: Map,
  handshake: Handshake,
  conversation: Conversation,
  calendar2: Calendar2,
  search: Search,
  levelBars: LevelBars,
  upload: Upload,
  clock: Clock,
  boostArrow: BoostArrow,
  boostCog: BoostCog,
  boostStar: BoostStar,
  boostSpeechBubble: BoostSpeechBubble,
  boostHome: BoostHome,
  arrow: Arrow,
  trash: Trash,
  reload: Reload
};

const Icon = props => {
  const { icon, color, text, fliped = false, weight = 'bold' } = props;

  const theme = useTheme();

  if (!IconMap[icon]) {
    // eslint-disable-next-line no-console
    console.warn(`<Icon /> called with invalid icon prop "${icon}"`);
    return null;
  }

  const StyledIcon = IconMap[icon];

  return (
    <Parent
      {...props}
      style={{
        transform: fliped ? 'rotate(180deg)' : ''
      }}
    >
      <StyledIcon
        {...props}
        color={theme.colors[color] || color || 'currentColor'}
      />
      {text && (
        <T.P weight={weight} ml="1" color={color}>
          {text}
        </T.P>
      )}
    </Parent>
  );
};

export default React.memo(Icon);
