import { useHistory } from 'react-router-dom';
import { userRoles } from '../constants';
import {
  DASHBOARD_URL,
  INVITATION_SIGN_UP_URL,
  PARTICIPANT_DASHBOARD,
  SURVEY_QUESTIONS_URL
} from '../constants/navigationRoutes';
import useSearchParams from './useSearchParams';

const useRedirectAfterAuth = () => {
  const history = useHistory();
  const searchParams = useSearchParams();

  const redirectAfterAuth = role => {
    if (searchParams.params.source === 'survey') {
      history.push({
        pathname: SURVEY_QUESTIONS_URL.replace(
          ':id',
          searchParams.params.shortId
        )
          .replace(':type', searchParams.params.surveyType)
          .replace(':step', 0)
      });
    } else if (searchParams.params.source === 'invitation') {
      history.push(
        `${INVITATION_SIGN_UP_URL}?token=${searchParams.params.token}&source=invitation`
      );
    } else if (role === userRoles.participant) {
      if (searchParams.params.registerAfterAuth !== 'true') {
        history.push(
          `${PARTICIPANT_DASHBOARD}${
            searchParams.params.initialSignup === 'true'
              ? '?initialSignup=true'
              : ''
          }`
        );
      }
    } else {
      history.push(DASHBOARD_URL);
    }
  };

  return redirectAfterAuth;
};

export default useRedirectAfterAuth;
