import { fields, createSchema, validate as _validate } from '..';
import { userRoles } from '../../constants';

const {
  requiredText,
  tpOrgTypeOther,
  requiredEmployerOrgType,
  employerOrgTypeOther,
  requiredOrganisationSizes,
  employerOrganisationLocationsOther,
  email,
  arrayOfEmailsRequire1,
  optionalText
} = fields;

const validate = data => {
  const tpOrgSchema = createSchema({
    name: requiredText,
    type: requiredText,
    otherOrgType: tpOrgTypeOther,
    size: requiredOrganisationSizes
  });

  const employerOrgSchema = createSchema({
    name: requiredText,
    type: requiredEmployerOrgType,
    otherOrgType: employerOrgTypeOther,
    size: requiredOrganisationSizes,
    location: requiredText,
    locationOther: employerOrganisationLocationsOther,
    staffContactEmail: email,
    financeContactEmails: arrayOfEmailsRequire1,
    financeContactInfo: optionalText
  });

  if (data.role === userRoles.tpOrgAdmin) {
    return _validate(tpOrgSchema, data);
  } else {
    return _validate(employerOrgSchema, data);
  }
};

export default validate;
