import axios from 'axios';

import {
  LOADING_TRUE,
  LOADING_FALSE,
  FETCH_EMPLOYER_ORGANISATIONS_SUCCESS,
  FETCH_EMPLOYER_ORGANISATION_SUCCESS,
  FETCH_EMPLOYER_ORGANISATIONS_FAIL,
  FETCH_EMPLOYER_ORGANISATION_FAIL,
  CREATE_EMPLOYER_ORGANISATION_SUCCESS,
  CREATE_EMPLOYER_ORGANISATION_FAIL,
  UPDATE_EMPLOYER_ORGANISATION_SUCCESS,
  UPDATE_EMPLOYER_ORGANISATION_FAIL,
  UPDATE_MY_ACCOUNT_SUCCESS
} from '../constants/actionTypes';

export const fetchEmployerOrganisations = (options = {}) => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'fetchEmployerOrganisationsLoading'
    });
    const res = await axios.get('/api/employer-organisations', {
      params: options
    });
    dispatch({
      type: FETCH_EMPLOYER_ORGANISATIONS_SUCCESS,
      payload: res.data
    });
    return {};
  } catch (error) {
    dispatch({
      type: FETCH_EMPLOYER_ORGANISATIONS_FAIL,
      payload: error.response.data.error
    });
    return { error };
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'fetchEmployerOrganisationsLoading'
    });
  }
};

export const fetchEmployerOrganisation = (
  id,
  { isPublic, ...options } = {}
) => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'fetchEmployerOrganisationLoading'
    });
    let url = `/api/employer-organisations/${id}`;
    if (isPublic) {
      url = `/api/employer-organisations/${id}/public`;
    }
    const res = await axios.get(url, {
      params: options
    });
    dispatch({
      type: FETCH_EMPLOYER_ORGANISATION_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: FETCH_EMPLOYER_ORGANISATION_FAIL,
      payload: error.response.data.error
    });
    return { error };
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'fetchEmployerOrganisationLoading'
    });
  }
};

export const createEmployerOrganisation = payload => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'createEmployerOrganisationLoading'
    });
    const res = await axios.post('/api/employer-organisations', payload);
    dispatch({
      type: CREATE_EMPLOYER_ORGANISATION_SUCCESS,
      payload: res.data
    });
    return {};
  } catch (error) {
    dispatch({
      type: CREATE_EMPLOYER_ORGANISATION_FAIL,
      payload: error.response.data.error
    });
    return { error };
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'createEmployerOrganisationLoading'
    });
  }
};

export const updateEmployerOrganisation = (id, payload) => async (
  dispatch,
  getState
) => {
  try {
    const authUser = getState().auth;

    dispatch({
      type: LOADING_TRUE,
      payload: 'updateEmployerOrganisationLoading'
    });
    const res = await axios.patch(`/api/employer-organisations/${id}`, payload);
    dispatch({
      type: UPDATE_EMPLOYER_ORGANISATION_SUCCESS,
      payload: res.data
    });

    if (authUser?.employerOrganisation === id) {
      dispatch({
        type: UPDATE_MY_ACCOUNT_SUCCESS,
        payload: { ...authUser, employerOrganisationData: payload }
      });
    }

    return {};
  } catch (error) {
    dispatch({
      type: UPDATE_EMPLOYER_ORGANISATION_FAIL,
      payload: error.response.data.error
    });
    return { error };
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'updateEmployerOrganisationLoading'
    });
  }
};

export const generateAuthCode = () => {
  return async () => {
    try {
      const response = await axios.get(
        '/api/employer-organisations/generate-auth-code'
      );
      return response.data;
    } catch (error) {
      return { error };
    }
  };
};
