import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { fetchProgrammes } from '../../../actions/programmesActions';
import {
  fetchTPOrganisation,
  createTPOrganisation,
  updateTPOrganisation
} from '../../../actions/tpOrganisationActions';
import useFormData from '../../../hooks/useFormData';
import useValidateFormData from '../../../hooks/useValidateFormData';
import { tpOrganisationValidation as validate } from '../../../validation/schemas';
import { MANAGE_USERS_ADD } from '../../../constants/navigationRoutes';
import Layout from '../../Layouts';
import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import * as I from '../../common/Inputs';
import Spin from '../../common/Spin';
import * as T from '../../common/Typography';
import * as S from './style';
import Modal from '../../common/modal';

const initialFormState = {
  name: '',
  programmes: []
};

const TPOrganisationsAddEdit = ({
  fetchTPOrganisationAction,
  fetchTPOrganisationLoading,
  addTPOrganisationAction,
  addTPOrganisationLoading,
  updateTPOrganisationAction,
  updateTPOrganisationLoading,
  tpOrganisation,
  tpOrganisationActionError,
  fetchProgrammesAction,
  fetchProgrammesLoading,
  programmes
}) => {
  const location = useLocation();
  const history = useHistory();

  const isEdit = location.pathname.includes('/edit');
  const { id } = useParams();

  const { formState, setFormData } = useFormData({ initialFormState });

  const submitAttempt = useRef(false);

  const {
    validateForm,
    validationErrors,
    finalSubmissionData
  } = useValidateFormData({
    formState,
    validateFn: validate,
    submitAttempt
  });
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const handleSubmit = async e => {
    e.preventDefault();
    submitAttempt.current = true;

    if (!validateForm()) {
      return;
    }

    const { current: finalFormState } = finalSubmissionData;

    if (isEdit && id) {
      await updateTPOrganisationAction(id, finalFormState);
    } else {
      await addTPOrganisationAction(finalFormState);
    }
    setIsSuccessModalOpen(true);
  };

  useEffect(() => {
    if (id) {
      fetchTPOrganisationAction(id);
    }
  }, [id, fetchTPOrganisationAction]);

  useEffect(() => {
    if (isEdit && tpOrganisation) {
      setFormData({
        ...formState,
        name: tpOrganisation.name,
        programmes: tpOrganisation.programmes
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tpOrganisation, isEdit]);

  useEffect(() => {
    fetchProgrammesAction({ forAdmin: true });
  }, [fetchProgrammesAction]);

  const sortedProgrammes = programmes?.sort((a, b) =>
    a.titleShort.localeCompare(b.titleShort)
  );

  if (
    (isEdit && !tpOrganisation) ||
    fetchTPOrganisationLoading ||
    fetchProgrammesLoading
  ) {
    return <Spin />;
  }

  return (
    <Layout>
      <Row>
        <Col w={[4, 6, 6]}>
          <T.H2W>
            {isEdit ? tpOrganisation?.name : 'Add Training Provider'}
          </T.H2W>
        </Col>
        <S.Divider full />
      </Row>
      <Row>
        <Col w={[4, 6, 6]}>
          <S.Form>
            <I.BasicInput
              label="Name"
              required
              placeholder="Type here..."
              mb="5"
              value={formState.name}
              handleChange={name => setFormData({ name })}
              error={validationErrors.name}
            />

            <I.Dropdown
              m={{
                mb: '5'
              }}
              label="Programme(s)"
              required
              addNew={false}
              search={true}
              options={sortedProgrammes?.map(programme => ({
                label: programme.titleShort,
                value: programme._id.toString()
              }))}
              multi
              selected={formState.programmes}
              handleChange={programmes => setFormData({ programmes })}
              error={validationErrors.programmes}
            />

            <Button
              label={isEdit ? 'Update' : 'Create'}
              type="primary"
              onClick={handleSubmit}
              loading={addTPOrganisationLoading || updateTPOrganisationLoading}
              disabled={addTPOrganisationLoading || updateTPOrganisationLoading}
            />
          </S.Form>
        </Col>
      </Row>

      <Modal
        type={isEdit ? 'success' : 'confirm'}
        title="Success"
        visible={isSuccessModalOpen}
        setModalOpen={setIsSuccessModalOpen}
        text={
          isEdit
            ? 'This Training Provider has been updated.'
            : 'This Training Provider has been created. Please assign an admin user.'
        }
        okLabel="Add Admin User"
        cancelLabel="Close"
        parentFunc={() => {
          history.push(`${MANAGE_USERS_ADD}?tpOrg=${tpOrganisation._id}`);
        }}
      />
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    tpOrganisation: state.tpOrganisations.tpOrganisation,
    fetchTPOrganisationLoading: state.loading.fetchTPOrganisationLoading,
    updateTPOrganisationLoading: state.loading.updateTPOrganisationLoading,
    addTPOrganisationLoading: state.loading.createTPOrganisationLoading,
    tpOrganisationActionError: state.tpOrganisations.error,
    fetchProgrammesLoading: state.loading.fetchProgrammesLoading,
    programmes: state.programmes.programmes
  };
};

const mapActionsToProps = {
  fetchTPOrganisationAction: fetchTPOrganisation,
  addTPOrganisationAction: createTPOrganisation,
  updateTPOrganisationAction: updateTPOrganisation,
  fetchProgrammesAction: fetchProgrammes
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(TPOrganisationsAddEdit);
