import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchTrainerSessions } from '../../../actions/groupSessionsAction';
import { useHistory } from 'react-router-dom';

import { Row, Col } from '../../common/Grid';
import * as T from '../../common/Typography';
import PublicSessionList from '../../common/List/PublicSessionList';

import Layout from '../../Layouts';
import moment from 'moment';

const TrainerSessions = ({ sessions, fetchTrainerSessions, isMobile }) => {
  const [availableSessions, setAvailableSessions] = useState([]);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    fetchTrainerSessions({ id, history });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (sessions?.length > 0) {
      const spaces = sessions
        .filter(
          session =>
            session.spacesLeft > 0 &&
            moment(session.date).isSameOrAfter(moment())
        )
        .sort((a, b) => {
          // sort by date
          return moment(a.date).isSameOrAfter(moment(b.date)) ? 1 : -1;
        });
      setAvailableSessions(spaces);
    }
  }, [sessions]);

  return (
    <Layout>
      <Row mb={7}>
        <Col w={[4, 8, 8]} direction="column" ai="flex-start">
          <T.H1 mb={5}>Available sessions</T.H1>
          <T.P>
            Please click to register on any session you wish to attend.
            <br />
            Remember that for MECC and Connect 5 you must complete the modules
            in order, so please do not attend a session teaching module 2 or 3
            before you’ve done module 1.
          </T.P>
        </Col>
      </Row>
      <Row>
        {availableSessions?.length > 0 ? (
          <PublicSessionList
            dataList={availableSessions}
            linkText={!isMobile && 'View & register'}
          />
        ) : (
          <Col w={[4, 8, 8]}>
            <T.P weight={700}>
              No sessions currently available. Check back soon.
            </T.P>
          </Col>
        )}
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    sessions: state.sessions.sessions,
    isMobile: state.checkBrowserWidth.isMobile
  };
};

const mapActionsToProps = {
  fetchTrainerSessions
};

export default connect(mapStateToProps, mapActionsToProps)(TrainerSessions);
