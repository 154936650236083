import { fields, createSchema, validate as _validate } from '..';

import { userRoles } from '../../constants';
import { phoneNumber } from '../fields';

const {
  email,
  name,
  arrayOfString,
  organisationOther,
  requiredText,
  requiredOrganisationSizes,
  arrayOfEmailsRequire1,
  optionalText,
  tpOrgTypeOther,
  employerOrgTypeOther,
  employerOrganisationLocationsOther
} = fields;

const trainerSchema = createSchema({
  email,
  name,
  localAuthorities: arrayOfString,
  organisationOther,
  jobRole: requiredText
});

const participantSchema = createSchema({
  email,
  name,
  phone: phoneNumber,
  jobRole: requiredText
});

const tpOrgSchema = createSchema({
  name,
  email,
  orgName: requiredText,
  orgType: requiredText,
  otherOrgType: tpOrgTypeOther,
  orgSize: requiredOrganisationSizes
});

const employerOrgSchema = createSchema({
  name,
  email,
  orgName: requiredText,
  orgType: requiredText,
  otherOrgType: employerOrgTypeOther,
  orgSize: requiredOrganisationSizes,
  location: requiredText,
  locationOther: employerOrganisationLocationsOther,
  employerOrgAuthCode: requiredText,
  financeContactEmails: arrayOfEmailsRequire1,
  financeContactInfo: optionalText
});

const validate = data => {
  if (data.role === userRoles.trainer) {
    return _validate(trainerSchema, data);
  } else if (data.role === userRoles.employerOrgAdmin) {
    return _validate(employerOrgSchema, data);
  } else if (data.role === userRoles.tpOrgAdmin) {
    return _validate(tpOrgSchema, data);
  } else {
    return _validate(participantSchema, data);
  }
};

export default validate;
