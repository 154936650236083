import { validate as _validate, createSchema, fields } from '..';
import { eventLocation, optionalDate, optionalLink } from '../fields';

const {
  email,
  arrayOfString,
  requiredDate,
  requiredNumber,
  sessionFormatType,
  partnerTrainer,
  address,
  onlineDetails,
  optionalText,
  requiredText,
  additionalTrainers,
  sessionType,
  sessionContract
} = fields;

const commonFields = {
  title: optionalText,
  description: requiredText,
  videoLink: optionalLink,
  date: requiredDate,
  endDate: requiredDate,
  registrationCutoffDate: optionalDate,
  startTime: optionalText,
  endTime: optionalText,
  format: sessionFormatType,
  modules: arrayOfString,
  selectedProgrammes: arrayOfString,
  capacity: requiredNumber,
  mainTrainer: partnerTrainer,
  additionalTrainers: additionalTrainers,
  location: eventLocation,
  address,
  online: onlineDetails,
  extraInfo: optionalText,
  contactEmail: email,
  audience: optionalText
};

const createSessionSchema = createSchema({
  ...commonFields
});

const createTpSessionSchema = createSchema({
  ...commonFields,
  type: sessionType,
  contract: sessionContract
});

const validate = data => {
  if (data.isTpAdmin) {
    return _validate(createTpSessionSchema, data);
  }

  const x = _validate(createSessionSchema, data);
  return x;
};

export default validate;
