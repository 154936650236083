import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  fetchEmployerOrganisation,
  updateEmployerOrganisation
} from '../../../actions/employerOrganisationActions';
import useFormData from '../../../hooks/useFormData';
import useValidateFormData from '../../../hooks/useValidateFormData';
import validate from '../../../validation/schemas/completeYourAccount';
import {
  cleanEmail,
  extractLocationExtra,
  shouldLocationHaveExtra
} from '../../../helpers';
import {
  employerOrgTypes,
  organisationSizes,
  organisationLocations,
  otherLocationWording,
  userRoles,
  navRoutes as R
} from '../../../constants';
import * as T from '../../common/Typography';
import Button from '../../common/Button';
import Spin from '../../common/Spin';
import Icon from '../../common/Icon';
import { Col, Row } from '../../common/Grid';
import { BasicInput, Dropdown, TextArea } from '../../common/Inputs';
import Layout from '../../Layouts';

import * as S from './CompleteYourAccount.style';

const organisationTypesOptions = Object.values(employerOrgTypes).map(type => ({
  value: type,
  label: type
}));
const organisationSizesOptions = Object.values(organisationSizes).map(size => ({
  value: size,
  label: size
}));
const organisationLocationsOptions = Object.values(organisationLocations).map(
  type => {
    if (shouldLocationHaveExtra(type)) {
      return {
        value: type,
        label: `${type} - ${otherLocationWording}`
      };
    }
    return {
      value: type,
      label: type
    };
  }
);

const initialFormState = {
  name: '',
  type: '',
  otherOrgType: '',
  size: '',
  location: '',
  locationOther: '',
  staffContactEmail: '',
  financeContactEmails: [''],
  financeContactInfo: ''
};

const EmployerOrganisation = ({
  authUser,
  httpError,
  fetchEmployerOrganisation,
  fetchEmployerOrganisationLoading,
  fetchedEmployerOrganisation,
  updateEmployerOrganisation,
  updateEmployerOrganisationLoading
}) => {
  const { formState, setFormData } = useFormData({ initialFormState });
  const submitAttempt = useRef(false);

  const {
    validateForm,
    validationErrors,
    finalSubmissionData
  } = useValidateFormData({
    formState: { ...formState, role: userRoles.employerOrgAdmin },
    validateFn: validate,
    submitAttempt
  });

  const history = useHistory();

  const {
    name,
    type,
    otherOrgType,
    size,
    location,
    locationOther,
    staffContactEmail,
    financeContactEmails,
    financeContactInfo
  } = formState;

  useEffect(() => {
    if (fetchedEmployerOrganisation) {
      const isOrgTypeOther =
        fetchedEmployerOrganisation.type &&
        !Object.values(employerOrgTypes).includes(
          fetchedEmployerOrganisation.type
        );

      const extractedLocationOther = extractLocationExtra(
        fetchedEmployerOrganisation.location
      );

      const formattedLocation =
        fetchedEmployerOrganisation.location ===
        organisationLocations.OTHER_PLEASE_SPECIFY_BELOW
          ? organisationLocations.OTHER_PLEASE_SPECIFY_BELOW
          : extractedLocationOther
          ? fetchedEmployerOrganisation.location.replace(
              ` - ${extractedLocationOther}`,
              ''
            )
          : fetchedEmployerOrganisation.location;

      const isLocationOther =
        fetchedEmployerOrganisation.location &&
        !Object.values(organisationLocations).includes(formattedLocation);
      setFormData({
        name: fetchedEmployerOrganisation.name,
        type: isOrgTypeOther
          ? employerOrgTypes.OTHER_PLEASE_STATE
          : fetchedEmployerOrganisation.type,
        otherOrgType: isOrgTypeOther ? fetchedEmployerOrganisation.type : '',
        size: fetchedEmployerOrganisation.size,
        location: isLocationOther
          ? organisationLocations.OTHER_PLEASE_SPECIFY_BELOW
          : formattedLocation,
        locationOther: isLocationOther
          ? fetchedEmployerOrganisation.location
          : extractedLocationOther,
        staffContactEmail: fetchedEmployerOrganisation.staffContactEmail,
        financeContactEmails: fetchedEmployerOrganisation.financeContactEmails
          ?.length
          ? fetchedEmployerOrganisation.financeContactEmails
          : [''],
        financeContactInfo: fetchedEmployerOrganisation.financeContactInfo
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedEmployerOrganisation]);

  useEffect(() => {
    if (authUser?.employerOrganisation) {
      fetchEmployerOrganisation(authUser.employerOrganisation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser?.employerOrganisation]);

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    type,
    otherOrgType,
    size,
    location,
    locationOther,
    staffContactEmail,
    financeContactEmails,
    financeContactInfo
  ]);

  const handleChangeFinanceContactEmail = ({ email, index }) => {
    const newFinanceContactEmails = [...financeContactEmails];
    const cleanedEmail = cleanEmail(email);
    newFinanceContactEmails[index] = cleanedEmail;

    setFormData({
      financeContactEmails: newFinanceContactEmails
    });
  };

  const handleRemoveFinanceContactEmail = index => {
    const newFinanceContactEmails = financeContactEmails.filter(
      (item, i) => i !== index
    );
    setFormData({
      financeContactEmails: newFinanceContactEmails
    });
  };
  const handleAddAnotherFinanceContactEmail = () => {
    setFormData({
      financeContactEmails: [...financeContactEmails, '']
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      handleSignup();
    }
  };

  const handleSignup = async () => {
    const { current: finalFormState } = finalSubmissionData;

    const isOrgTypeOther =
      finalFormState.type === employerOrgTypes.OTHER_PLEASE_STATE;
    const isLocationOther =
      finalFormState.location ===
      organisationLocations.OTHER_PLEASE_SPECIFY_BELOW;

    const payload = {
      name: finalFormState.name,
      type: isOrgTypeOther ? finalFormState.otherOrgType : finalFormState.type,
      size: finalFormState.size,
      location: isLocationOther
        ? finalFormState.locationOther
        : shouldLocationHaveExtra(finalFormState.location)
        ? `${finalFormState.location} - ${finalFormState.locationOther}`
        : finalFormState.location,
      staffContactEmail: finalFormState.staffContactEmail,
      financeContactEmails: finalFormState.financeContactEmails,
      financeContactInfo: finalFormState.financeContactInfo,
      setupCompleted: true
    };

    const result = await updateEmployerOrganisation(
      fetchedEmployerOrganisation._id,
      payload
    );

    if (!result?.error) {
      history.push(R.TOUR_URL);
    }
  };

  if (fetchEmployerOrganisationLoading) {
    return <Spin />;
  }

  return (
    <Layout>
      <S.Wrapper className="sign-up">
        <Row>
          <Col w={[4, 12, 8]}>
            <S.ContentWrapper>
              <T.H1 mb="7">Complete Your Account</T.H1>
              <T.P weight={400} mb="6">
                To complete your organisation account, please answer the
                questions below.
              </T.P>
              <S.Form className="complete-your-account-form">
                <BasicInput
                  value={name}
                  handleChange={value => setFormData({ name: value })}
                  mb={4}
                  label="Organisation Name"
                  placeholder="Type your organisation name here"
                  required
                  error={
                    httpError?.includes('name already exists')
                      ? httpError
                      : validationErrors.name
                  }
                />

                <Dropdown
                  selected={type}
                  m={{ mb: 4 }}
                  label="Organisation Type"
                  placeholder="Select"
                  required
                  options={organisationTypesOptions}
                  handleChange={value => setFormData({ type: value })}
                  error={validationErrors.type}
                />

                {type === employerOrgTypes.OTHER_PLEASE_STATE && (
                  <BasicInput
                    value={otherOrgType}
                    handleChange={value => setFormData({ otherOrgType: value })}
                    mb={4}
                    mt="-15px"
                    placeholder="State your organisation type here"
                    required
                    error={validationErrors.otherOrgType}
                  />
                )}

                <Dropdown
                  selected={size}
                  m={{ mb: 4 }}
                  label="Size of Organisation"
                  placeholder="Select"
                  required
                  options={organisationSizesOptions}
                  handleChange={value => setFormData({ size: value })}
                  error={validationErrors.size}
                />

                <Dropdown
                  selected={location}
                  m={{ mb: 4 }}
                  label="Where Your Organisation Is Based"
                  placeholder="Select"
                  required
                  options={organisationLocationsOptions}
                  handleChange={value => setFormData({ location: value })}
                  error={validationErrors.location}
                />
                {(location ===
                  organisationLocations.OTHER_PLEASE_SPECIFY_BELOW ||
                  shouldLocationHaveExtra(location) ||
                  extractLocationExtra(location)) && (
                  <BasicInput
                    value={locationOther}
                    handleChange={value =>
                      setFormData({ locationOther: value })
                    }
                    mb={4}
                    mt="-15px"
                    placeholder={
                      location ===
                      organisationLocations.OTHER_PLEASE_SPECIFY_BELOW
                        ? 'Specify here'
                        : 'Specify extra information here'
                    }
                    required
                    error={validationErrors.locationOther}
                  />
                )}

                <BasicInput
                  value={staffContactEmail}
                  handleChange={value =>
                    setFormData({ staffContactEmail: value })
                  }
                  label="Staff Contact Email"
                  placeholder="Type staff contact email here"
                  mb={2}
                  required
                  error={validationErrors.staffContactEmail}
                />
                <T.P color="gray" small mb={4}>
                  This is the contact email your staff should reach out to if
                  they have questions about training.
                </T.P>

                {financeContactEmails.map((financeEmail, index) => (
                  <S.InputWrapper key={`finance-contact-email-${index}`}>
                    <BasicInput
                      value={financeContactEmails[index]}
                      handleChange={value =>
                        handleChangeFinanceContactEmail({ email: value, index })
                      }
                      label={`Finance Contact Email ${
                        index ? `#${index + 1}` : ''
                      }`}
                      placeholder="Type finance contact email here"
                      mb={2}
                      required={index === 0}
                      error={validationErrors[`financeContactEmails[${index}]`]}
                      autoComplete="off"
                    />
                    {index === 0 && (
                      <T.P color="gray" small mb={4}>
                        This enables Training Providers to contact you if you
                        register for any paid-for training events, such as
                        Oliver McGowan.
                      </T.P>
                    )}
                    {index !== 0 && (
                      <T.TextBtn
                        alignSelf="end"
                        onClick={() => handleRemoveFinanceContactEmail(index)}
                      >
                        Remove
                      </T.TextBtn>
                    )}
                  </S.InputWrapper>
                ))}
                <S.TextWithIcon
                  type="button"
                  onClick={handleAddAnotherFinanceContactEmail}
                  mb="5"
                >
                  <Icon icon="add" color="primary" />
                  <T.P color="darkGray" small weight={600}>
                    Add another finance contact email address
                  </T.P>
                </S.TextWithIcon>

                <TextArea
                  label="Add any useful finance contact information "
                  placeholder="e.g. We automatically cover paid-for training for Oliver McGowan and MECC courses. For any other training, please make sure you first send a request to the HR department on hr@yallacooperative.com."
                  handleChange={value =>
                    setFormData({ financeContactInfo: value })
                  }
                  value={financeContactInfo}
                  rows="3"
                  mb="6"
                  error={validationErrors.financeContactInfo}
                />

                {httpError && (
                  <T.P color="error" mt={4}>
                    {httpError}
                  </T.P>
                )}
                <Button
                  mt={4}
                  onClick={handleSubmit}
                  type="primary"
                  label="Submit"
                  loading={updateEmployerOrganisationLoading}
                  disabled={
                    validationErrors.hasError ||
                    updateEmployerOrganisationLoading
                  }
                />
              </S.Form>
            </S.ContentWrapper>
          </Col>
        </Row>
      </S.Wrapper>
    </Layout>
  );
};

const mapStateToProps = state => ({
  authUser: state.auth,
  fetchEmployerOrganisationLoading:
    state.loading.fetchEmployerOrganisationLoading,
  fetchedEmployerOrganisation: state.employerOrganisations.employerOrganisation,
  updateEmployerOrganisationLoading:
    state.loading.updateEmployerOrganisationLoading,
  httpError: state.employerOrganisations.error
});

export default connect(mapStateToProps, {
  fetchEmployerOrganisation,
  updateEmployerOrganisation
})(EmployerOrganisation);
