import * as types from '../constants/actionTypes';

const initState = {
  addTrainerLoading: false,
  changePasswordLoading: false,
  forgetPasswordLoading: false,
  resetPasswordLoading: false,
  loginLoading: false,
  getInvolvedLoading: false,
  sessionEditLoading: false,
  signupLoading: false,
  signupViaInvitationLoading: false,
  updateUserLoading: false,
  deleteAccountLoading: false,
  sendEmail: false,
  updateMyAccount: false,
  createModuleLoading: false,
  fetchModulesLoading: false,
  fetchModuleLoading: false,
  archiveModuleLoading: false,
  completeModuleLoading: false,
  fetchSessions: false,
  fetchParticipantSessions: false,
  fetchSession: false,
  fetchProgrammesLoading: false,
  fetchProgrammeLoading: false,
  updateProgrammeLoading: false,
  fetchUsersLoading: false,
  fetchUserLoading: false,
  deleteUserLoading: false,
  fetchUserInvitationLoading: false,
  sendUserInvitationLoading: false,
  deleteUserInvitationLoading: false,
  updateUserInvitationLoading: false,
  participantResponsesLoading: false,
  withdrawFromModuleLoading: false,
  launchModuleLoading: false,
  withdrawFromSessionLoading: false,
  updateIncompleteProfileLoading: false,
  fetchUserResultsLoading: false
};

export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.LOADING_TRUE:
      return { ...initState, [payload]: true };

    case types.LOADING_FALSE:
      return { ...initState, [payload]: false };

    default:
      return state;
  }
};
