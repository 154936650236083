import { validate as _validate, createSchema, fields } from '..';
import { userRoles } from '../../constants';
import { optionalText } from '../fields';

const { requiredText, arrayOfString, email, arrayOfOptionalStrings } = fields;

export const arrayOfStringWithTPValidation = arrayOfString.test(
  'tp-requires-trainer',
  function(roles) {
    if (
      roles.includes(userRoles.employerOrgAdmin) &&
      (roles.includes(userRoles.programmeManager) ||
        roles.includes(userRoles.admin))
    ) {
      return this.createError({
        message:
          'As an Employer Org admin, you can’t be a Programme Manager or Platform Admin'
      });
    }

    if (
      roles.includes(userRoles.tpOrgAdmin) &&
      (roles.includes(userRoles.programmeManager) ||
        roles.includes(userRoles.admin))
    ) {
      return this.createError({
        message:
          'As a TP Org Admin, you can’t be a Programme Manager or Platform Admin'
      });
    }

    if (
      (!this.parent.userIds || this.parent.userIds.length === 0) &&
      roles.includes(userRoles.employerOrgAdmin) &&
      roles.includes(userRoles.tpOrgAdmin)
    ) {
      return this.createError({
        message:
          "You can't add a user with both Employer Org Admin and TP Org Admin roles, You can add one of them now and edit the user to add the other role later"
      });
    }

    return true;
  }
);

const validate = data => {
  const editUserSchema = createSchema({
    name: requiredText,
    email,
    roles: arrayOfStringWithTPValidation,
    managedProgrammes:
      !data.roles.includes(userRoles.tpOrgAdmin) &&
      (data.roles.includes(userRoles.trainer) ||
        data.roles.includes(userRoles.programmeManager))
        ? arrayOfString
        : arrayOfOptionalStrings,

    employerOrganisationId: data.roles.includes(userRoles.employerOrgAdmin)
      ? requiredText
      : optionalText,

    employerOrganisationName:
      data.roles.includes(userRoles.employerOrgAdmin) &&
      data.employerOrganisationId === 'Add New Organisation'
        ? requiredText
        : optionalText,

    tpOrganisationId: data.roles.includes(userRoles.tpOrgAdmin)
      ? requiredText
      : optionalText,

    tpOrganisationName:
      data.roles.includes(userRoles.tpOrgAdmin) &&
      data.tpOrganisationId === 'Add New Training Provider'
        ? requiredText
        : optionalText,

    tpOrganisationProgrammes:
      data.roles.includes(userRoles.tpOrgAdmin) &&
      data.tpOrganisationId === 'Add New Training Provider'
        ? arrayOfString
        : arrayOfOptionalStrings
  });
  return _validate(editUserSchema, data);
};

export default validate;
