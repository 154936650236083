import React from 'react';
import moment from 'moment';

import * as T from '../../common/Typography';
import * as R from '../../../constants/navigationRoutes';
import * as S from './style';
import { USER_TYPE_LABELS } from '../../../constants/userTypes';

export const TableColumns = ({ isTablet }) => {
  const commonColumns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (name, row) => <T.P color="darkGray">{name || 'N/A'}</T.P>,
      position: 1
    },

    {
      key: 'action',
      render: (_, record) => (
        <S.ActionLink
          to={R.MANAGE_TP_ORGANISATIONS_EDIT.replace(':id', record._id)}
        >
          <T.P color="darkGray" weight={600} mr="4">
            View / Edit
          </T.P>
        </S.ActionLink>
      ),
      position: 5
    }
  ];

  const nonMobileColumns = [
    {
      title: 'Administrators',
      key: 'administrators',
      dataIndex: 'administrators',
      render: (administrators, row) => (
        <T.P color="darkGray">{administrators || '0'}</T.P>
      ),
      position: 2
    },
    {
      title: 'Trainers',
      dataIndex: 'trainers',
      key: 'trainers',
      render: (trainers, row) => <T.P color="darkGray">{trainers || '0'}</T.P>,
      position: 3
    }
  ];
  const columns = !isTablet
    ? commonColumns
    : [...commonColumns, ...nonMobileColumns];

  const sortedColumns = columns.sort((a, b) => a.position - b.position);

  return sortedColumns;
};
