import styled from 'styled-components';
import * as T from '../../common/Typography';

export const CodeContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 1rem 1.5rem;
  width: fit-content;
  border-radius: 0.5rem;
  margin-bottom: 40px;
`;

export const StyledTP = styled(T.P)`
  font-size: 1rem !important;
`;

export const StyledNote = styled.div`
  padding: 12px 13px 20px 16px;
  background: #fcfcfc;
  border: 1px solid #ebeef0;
  border-radius: 4px;
  margin-bottom: 40px;
`;
