const TempVideo = (
  <div
    style={{
      height: '100%',
      width: '100%',
      position: 'relative'
    }}
  >
    <iframe
      width="100%"
      height="100%"
      title="loomvideo"
      src="https://www.loom.com/embed/0281766fa2d04bb788eaf19e65135184?sid=2355307b-153a-4e90-a5f4-f630f80eec65"
      allowFullScreen
    ></iframe>
  </div>
);

export const EMPOrgAdmin = [
  {
    title: 'Managing your organisation',
    description:
      'You can update your organisation details at any times in your Account details. If you wish to add other administrators, you can do this by adding a User on the Users page.',
    slideContent: TempVideo
  },
  {
    title: 'Inviting staff',
    description:
      'Any of your organisation staff can sign up for free on the landing page. However, you can also bulk invite your users, to ensure they sign up. This can also be a great way to quickly add any mandatory training they have already completed or need to complete.',
    slideContent: TempVideo
  },
  {
    title: 'Accessing Training',
    description:
      'You and any of your organisation can find and register for any training programme listed on the Learning Academy.',
    slideContent: TempVideo
  },
  {
    title: 'Requesting Training Providers',
    description:
      'As well as registering onto public events, for specific training, such as Oliver McGowan, you may wish to enlist a certified Training Provider to deliver private sessions just for your organisation. This can be a great way to ensure your staff complete mandatory training on time.',
    slideContent: TempVideo
  },
  {
    title: 'Insights into your staff training',
    description:
      'Every staff member will be able to access their own dashboard to keep track of what training they need to do and what they have done so far. You can also see how your organisation is doing via the Analytics page.',
    slideContent: TempVideo
  }
];

export const TPAdmin = [
  {
    title: 'Managing your organisation',
    description:
      'You can update your organisation details at any times in your Account details. If you wish to add other administrators, you can do this by adding a User on the Users page.',
    slideContent: TempVideo
  },
  {
    title: 'Inviting trainers',
    description:
      'Just like with administrators, you can add trainers via the **Users** page and assign them to training programmes you deliver. You can also quickly organise them into groups to make it easy to organise and view analytics.',
    slideContent: TempVideo
  },
  {
    title: 'Setting up training',
    description:
      'You and your trainers can set up training events via the **Events** page. Once set up, you can then easily send out invites, manage registration and view individual feedback',
    slideContent: TempVideo
  },
  {
    title: 'Training Contracts',
    description:
      'In the Contracts page you can see requests from organisations looking for training providers. View their requests and negotiate the details to agree and deliver private training sessoins.',
    slideContent: TempVideo
  },
  {
    title: 'Insights into your training',
    description:
      'Every trainer will be able to access their own dashboard to see feedback and the impact of their training. You too can drill into this data across your entire organisatoin via the Analytics page.',
    slideContent: TempVideo
  }
];
