import moment from 'moment';
import useDownloadCertificate from '../../../hooks/useDownloadCertificate';

import * as R from '../../../constants/navigationRoutes';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import * as T from '../../common/Typography';
import Layout from '../../Layouts';

import { surveyTypesNew } from '../../../constants';
import * as S from './style';

const CompletedModule = ({ module, email, showPostCourse }) => {
  const { downloading, handleDownload } = useDownloadCertificate();

  const hasCourseMaterials =
    module?.preCourseMaterials?.length > 0 ||
    module?.postCourseMaterials?.length > 0;

  const completionAt =
    module?.participantAttendance?.completedAt ||
    module?.latestMandatoryAttempt?.completedAt;

  const handleClick = () => {
    handleDownload({
      moduleName: module.title,
      moduleId: module._id,
      email: email,
      sessionId: module.participantAttendance.session,
      date: completionAt
    });
  };

  return (
    <Layout>
      <S.Wrapper>
        <T.H1 mb={3}>{module?.title}</T.H1>
        <T.Link
          external
          underline={true}
          color="darkGray"
          to={R.MODULE_URL.replace(':id', module._id)}
        >
          <S.IconWrapper mb={7}>
            <Icon icon="openInNew" mr="1" color="darkGray" />
            <T.P weight={600} small color="darkGray">
              View module details
            </T.P>
          </S.IconWrapper>
        </T.Link>
        <T.H2 mb="2" mt="6" weight={600}>
          Status
        </T.H2>
        <T.P color="gray" mb={4}>
          {showPostCourse && (
            <>
              <T.P mb="3" color="gray">
                Nearly there! You completed this module on{' '}
                <T.P weight={600} d="inline">
                  {moment(completionAt).format('DD/MM/YYYY')}
                </T.P>
                {} and now just need to complete the post-course evaluation form
                to get your certificate (it takes just 2 minutes).
              </T.P>
              <Button
                label="Complete Post Evaluation"
                type="primary"
                style={{
                  maxWidth: 'fit-content',
                  'padding-inline': '20px'
                }}
                to={R.SURVEY_QUESTIONS_URL.replace(
                  ':id',
                  module?.sessions[0]?.shortId
                )
                  .replace(':type', surveyTypesNew.POST)
                  .replace(':step', 0)}
              />
            </>
          )}
          {!showPostCourse && module.enableCertificate && (
            <>
              Congratulations, you successfully completed this module on{' '}
              <T.P weight={600} d="inline">
                {moment(completionAt).format('DD/MM/YYYY')}
              </T.P>
              <Button
                label="Download Your Certificate Of Completion"
                type="primary"
                style={{
                  maxWidth: 'fit-content',
                  'padding-inline': '20px'
                }}
                disabled={downloading}
                loading={downloading}
                onClick={() => handleClick(module)}
              />
            </>
          )}
        </T.P>

        {hasCourseMaterials && (
          <>
            <T.H2 mb="2" mt="6" weight={600}>
              Materials
            </T.H2>
            {module?.preCourseMaterials?.length > 0 && (
              <>
                <T.P mb="2" color="gray">
                  Feel free to review any of the pre-course materials below.
                </T.P>
                {module.preCourseMaterials.map(({ link, title }) => (
                  <T.Link
                    key={link}
                    external
                    to={link}
                    color="darkGray"
                    underline
                    weight={600}
                    d="block"
                  >
                    {title || link}
                  </T.Link>
                ))}
              </>
            )}
            {module?.postCourseMaterials?.length > 0 && (
              <>
                <T.P
                  mb="2"
                  color="gray"
                  mt={module?.preCourseMaterials?.length > 0 ? 5 : 0}
                >
                  Feel free to review any of the post-course materials below.
                </T.P>
                {module.postCourseMaterials.map(({ link, title }) => (
                  <T.Link
                    key={link}
                    external
                    to={link}
                    color="darkGray"
                    underline
                    weight={600}
                    d="block"
                  >
                    {title || link}
                  </T.Link>
                ))}
              </>
            )}
          </>
        )}

        {module?.preRequisiteFor?.length > 0 && (
          <>
            <T.H2 mt="6" weight={600} mb="2">
              Further modules
            </T.H2>
            <T.P mb="3" color="gray">
              Now you have completed this module, you are eligible to register
              for these other modules that may be of interest{' '}
            </T.P>
            {module?.preRequisiteFor?.map(mod => (
              <S.StyledDiv>
                <T.H3 weight={600} color="darkGray" mb="2">
                  {mod.title}
                </T.H3>
                <T.P mb="2" color="gray">
                  {mod.description}
                </T.P>
                <T.Link color="gray" to={R.MODULE_URL.replace(':id', mod._id)}>
                  Read more
                </T.Link>
              </S.StyledDiv>
            ))}
          </>
        )}
      </S.Wrapper>
    </Layout>
  );
};

export default CompletedModule;
