// user results
export const FETCH_USER_RESULTS_REQUEST = 'FETCH_USER_RESULTS_REQUEST';
export const FETCH_USER_RESULTS_SUCCESS = 'FETCH_USER_RESULTS_SUCCESS';
export const FETCH_USER_RESULTS_FAILURE = 'FETCH_USER_RESULTS_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const PARTICIPANT_SIGNUP_FAIL = 'PARTICIPANT_SIGNUP_FAIL';
export const PARTICIPANT_SIGNUP_SUCCESS = 'PARTICIPANT_SIGNUP_SUCCESS';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const FETCH_TRINER_RESULTS_REQUEST = 'FETCH_TRINER_RESULTS_REQUEST';
export const FETCH_TRINER_RESULTS_SUCCESS = 'FETCH_TRINER_RESULTS_SUCCESS';
export const FETCH_TRINER_RESULTS_FAILURE = 'FETCH_TRINER_RESULTS_FAILURE';
export const FETCH_ALL_TRAINERS = 'FETCH_ALL_TRAINERS';
export const ADD_SESSION_SUCCESS = 'ADD_SESSION_SUCCESS';
export const ADD_SESSION_FAIL = 'ADD_SESSION_FAIL';
export const RESET_SESSION_STATE = 'RESET_SESSION_STATE';
export const CHECK_UNIQUE_EMAIL_UNIQUE = 'CHECK_UNIQUE_EMAIL_UNIQUE';
export const CHECK_UNIQUE_EMAIL_UNUNIQUE = 'CHECK_UNIQUE_EMAIL_UNUNIQUE';
export const FETCH_STATS = 'FETCH_STATS';
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const USER_UNAUTHENTICATED = 'USER_UNAUTHENTICATED';
export const FETCH_TRAINERS_SESSIONS = 'FETCH_TRAINERS_SESSIONS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
export const FETCH_ALL_SESSIONS = 'FETCH_ALL_SESSIONS';
export const FETCH_SESSION_FAIL = 'FETCH_SESSION_FAIL';
export const FETCH_SESSION_DETAILS = 'FETCH_SESSION_DETAILS';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const EDIT_SESSION_SUCCESS = 'EDIT_SESSION_SUCCESS';
export const UPDATE_EMAILS_SUCCESS = 'UPDATE_EMAILS_SUCCESS';
export const ADD_TRAINER_TO_GROUP_SUCCESS = 'ADD_TRAINER_TO_GROUP_SUCCESS';
export const ADD_TRAINER_TO_GROUP_FAIL = 'ADD_TRAINER_TO_GROUP_FAIL';
export const RESET_GROUPS_STATE = 'RESET_GROUPS_STATE';
export const RESET_UNIQUE_EMAIL = 'RESET_UNIQUE_EMAIL';
export const CHECK_UNIQUE_EMAIL_ERROR = 'CHECK_UNIQUE_EMAIL_ERROR';
export const UPDATE_VIEW_LEVEL = 'UPDATE_VIEW_LEVEL';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = '  CHANGE_PASSWORD_FAIL';

export const FETCH_PRTICIPENT_SESSIONS_REQUEST =
  'FETCH_PRTICIPENT_SESSIONS_REQUEST';
export const FETCH_PARTICIPANT_SESSIONS_SUCCESS =
  'FETCH_PRTICIPENT_SESSIONS_SUCCESS';

export const FETCH_PARTICIPANT_SESSIONS_BY_EMAIL_SUCCESS =
  'FETCH_PARTICIPANT_SESSIONS_BY_EMAIL_SUCCESS';

export const FETCH_PRTICIPENT_SESSIONS_FAILURE =
  'FETCH_PRTICIPENT_SESSIONS_FAILURE';
export const LOGOUT = 'LOGOUT';

export const EXPORT_DATA_SUCCESS = 'EXPORT_DATA_SUCCESS';
export const GET_PARTICIPANTS_DEMOGRAPHIC_SUCCESS =
  'GET_PARTICIPANTS_DEMOGRAPHIC_SUCCESS';
export const GET_PARTICIPANT_SURVEY_RESPONSES_RESET =
  'GET_PARTICIPANT_SURVEY_RESPONSES_RESET';
export const GET_PARTICIPANT_SURVEY_RESPONSES_SUCCESS =
  'GET_PARTICIPANT_SURVEY_RESPONSES_SUCCESS';
export const REQUEST_USER_PASSWORD_RESET_SUCCESS =
  'REQUEST_USER_PASSWORD_RESET_SUCCESS';
export const REQUEST_USER_PASSWORD_RESET_FAIL =
  'REQUEST_USER_PASSWORD_RESET_FAIL';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const FETCH_MODULES = 'FETCH_MODULES';
export const FETCH_MODULE = 'FETCH_MODULE';

export const FETCH_SURVEY_DATA = 'FETCH_SURVEY_DATA';
export const SURVEY_SUBMISSION_SUCCESS = 'SURVEY_SUBMISSION_SUCCESS';
export const SURVEY_SUBMISSION_FAIL = 'SURVEY_SUBMISSION_FAIL';
export const SURVEY_SUBMISSION_LOADING = 'SURVEY_SUBMISSION_LOADING';
export const GET_SESSION_DETAILS_BY_SHORT_ID =
  'GET_SESSION_DETAILS_BY_SHORT_ID';
export const GET_SESSION_BY_ID = 'GET_SESSION_BY_ID';
export const CONFIRM_REGISTRATION_ERROR = 'CONFIRM_REGISTRATION_ERROR';
export const CONFIRM_REGISTRATION_SUCCESS = 'CONFIRM_REGISTRATION_SUCCESS';
export const CONFIRM_REGISTRATION_RESET = 'CONFIRM_REGISTRATION_RESET';
export const JOIN_WAITING_LIST_SUCCESS = 'JOIN_WAITING_LIST_SUCCESS';
export const ADD_WAITING_TO_ATTENDEES_SUCCESS =
  'ADD_WAITING_TO_ATTENDEES_SUCCESS';
export const RESET_ADD_WAITING_TO_ATTENDEES_FLAG =
  'RESET_ADD_WAITING_TO_ATTENDEES_FLAG';

export const UPDATE_ATTENDEES_SUCCESS = 'UPDATE_ATTENDEES_SUCCESS';
export const UPDATE_ATTENDEES_FAIL = 'UPDATE_ATTENDEES_FAIL';
export const UPDATE_PARTICIPANT_ATTENDANCE_STATUS_SUCCESS =
  'UPDATE_PARTICIPANT_ATTENDANCE_STATUS_SUCCESS';
export const UPDATE_PARTICIPANT_ATTENDANCE_STATUS_FAIL =
  'UPDATE_PARTICIPANT_ATTENDANCE_STATUS_FAIL';

export const GROUP_BOOKING_SUCCESS = 'GROUP_BOOKING_SUCCESS';
export const GROUP_BOOKING_FAIL = 'GROUP_BOOKING_FAIL';

export const GET_PARTICIPANT_SURVEY_RESPONSES_FAIL =
  'GET_PARTICIPANT_SURVEY_RESPONSES_FAIL';
export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';
export const EMAIL_SCHEDULE_SUCCESS = 'EMAIL_SCHEDULE_SUCCESS';
export const STORE_SESSION_DATA = 'STORE_SESSION_DATA';
export const CHECK_BROWSER_WIDTH = 'CHECK_BROWSER_WIDTH';
export const LOADING_TRUE = 'LOADING_TRUE';
export const LOADING_FALSE = 'LOADING_FALSE';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const SEND_SPECIAL_REQUIREMENTS_SUCCESS =
  'SEND_SPECIAL_REQUIREMENTS_SUCCESS';
export const SEND_SPECIAL_REQUIREMENTS_ERROR =
  'SEND_SPECIAL_REQUIREMENTS_ERROR';
export const FETCH_ALL_MANAGERS = 'FETCH_ALL_MANAGERS';

export const CLEAR_SESSION_DATA = 'CLEAR_SESSION_DATA';
export const SET_FILTERS = 'SET_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const FETCH_PARTICIPANT_SESSION_DETAILS =
  'FETCH_PARTICIPANT_SESSION_DETAILS';
export const FETCH_PARTICIPANT_SESSION_DETAILS_SUCCESS =
  'FETCH_PARTICIPANT_SESSION_DETAILS_SUCCESS';
export const FETCH_PARTICIPANT_SESSION_DETAILS_FAIL =
  'FETCH_PARTICIPANT_SESSION_DETAILS_FAIL';
export const FETCH_PARTICIPANT_RESPONSES_SUCCESS =
  'FETCH_PARTICIPANT_RESPONSES_SUCCESS';
export const FETCH_PARTICIPANT_RESPONSES_FAIL =
  'FETCH_PARTICIPANT_RESPONSES_FAIL';
export const UPDATE_MY_ACCOUNT_SUCCESS = 'UPDATE_MY_ACCOUNT_SUCCESS';
export const UPDATE_MY_ACCOUNT_FAIL = 'UPDATE_MY_ACCOUNT_FAIL';
export const SELECT_ROLE_SUCCESS = 'SELECT_ROLE_SUCCESS';
export const RESET_ROLE_SUCCESS = 'RESET_ROLE_SUCCESS';
export const SELECT_ROLE_FAIL = 'SELECT_ROLE_FAIL';
export const RESET_AUTH = 'RESET_AUTH';
export const GET_INVOLVED_SUCCESS = 'GET_INVOLVED_SUCCESS';
export const GET_INVOLVED_FAIL = 'GET_INVOLVED_FAIL';
export const CREATE_MODULE_SUCCESS = 'CREATE_MODULE_SUCCESS';
export const CREATE_MODULE_FAIL = 'CREATE_MODULE_FAIL';
export const FETCH_MODULES_FAIL = 'FETCH_MODULES_FAIL';
export const FETCH_MODULE_FAIL = 'FETCH_MODULE_FAIL';
export const ARCHIVE_MODULES_FAIL = 'ARCHIVE_MODULES_FAIL';
export const COMPLETE_MODULE_FAIL = 'COMPLETE_MODULE_FAIL';
export const CLEAR_MODULE = 'CLEAR_MODULE';
export const WITHDRAW_FROM_MODULE_FAIL = 'WITHDRAW_FROM_MODULE_FAIL';
export const LAUNCH_MODULE_SUCCESS = 'LAUNCH_MODULE_SUCCESS';
export const LAUNCH_MODULE_FAIL = 'LAUNCH_MODULE_FAIL';

export const SET_COURSE_CONTEXT = 'SET_COURSE_CONTEXT';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAIL = 'SEARCH_FAIL';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';

export const FETCH_PROGRAMMES = 'FETCH_PROGRAMMES';
export const FETCH_PROGRAMME = 'FETCH_PROGRAMME';
export const FETCH_PROGRAMMES_FAIL = 'FETCH_PROGRAMMES_FAIL';
export const FETCH_PROGRAMME_FAIL = 'FETCH_PROGRAMME_FAIL';

export const UPDATE_PROGRAMME_SUCCESS = 'UPDATE_PROGRAMME_SUCCESS';
export const UPDATE_PROGRAMME_FAIL = 'UPDATE_PROGRAMME_FAIL';

export const FETCH_PROGRAMME_CATEGORIES = 'FETCH_PROGRAMME_CATEGORIES';
export const FETCH_PROGRAMME_CATEGORIES_FAIL =
  'FETCH_PROGRAMME_CATEGORIES_FAIL';
export const FETCH_PROGRAMME_CATEGORY_SUCCESS =
  'FETCH_PROGRAMME_CATEGORY_SUCCESS';
export const FETCH_PROGRAMME_CATEGORY_FAIL = 'FETCH_PROGRAMME_CATEGORY_FAIL';
export const CREATE_PROGRAMME_CATEGORY_SUCCESS =
  'CREATE_PROGRAMME_CATEGORY_SUCCESS';
export const CREATE_PROGRAMME_CATEGORY_FAIL = 'CREATE_PROGRAMME_CATEGORY_FAIL';
export const UPDATE_PROGRAMME_CATEGORY_SUCCESS =
  'UPDATE_PROGRAMME_CATEGORY_SUCCESS';
export const UPDATE_PROGRAMME_CATEGORY_FAIL = 'UPDATE_PROGRAMME_CATEGORY_FAIL';

export const SET_IS_FONT_LARGE = 'SET_IS_FONT_LARGE';
export const SET_LAYOUT_COLOR = 'SET_LAYOUT_COLOR';
export const SET_LAYOUT_FONT_FAMILY = 'SET_LAYOUT_FONT_FAMILY';

export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAIL = 'FILE_UPLOAD_FAIL';
export const FILE_DELETE_SUCCESS = 'FILE_DELETE_SUCCESS';
export const FILE_DELETE_FAIL = 'FILE_DELETE_FAIL';
export const FILE_LOADING = 'FILE_LOADING';

export const SET_LAST_ACTIVE_FAIL = 'SET_LAST_ACTIVE_FAIL';
export const SET_LAST_ACTIVE_SUCCESS = 'SET_LAST_ACTIVE_SUCCESS';
export const RESET_FILE_UPLOAD_STATE = 'RESET_FILE_UPLOAD_STATE';

export const FETCH_USER_INVITATION_DETAILS_BY_TOKEN =
  'FETCH_USER_INVITATION_DETAILS_BY_TOKEN';
export const FETCH_USER_INVITATION_DETAILS_SUCCESS =
  'FETCH_USER_INVITATION_DETAILS_SUCCESS';
export const FETCH_USER_INVITATION_DETAILS_FAIL =
  'FETCH_USER_INVITATION_DETAILS_FAIL';

export const SEND_USER_INVITATION_SUCCESS = 'SEND_USER_INVITATION_SUCCESS';
export const SEND_USER_INVITATION_FAIL = 'SEND_USER_INVITATION_FAIL';

export const DELETE_USER_INVITATION_SUCCESS = 'DELETE_USER_INVITATION_SUCCESS';
export const DELETE_USER_INVITATION_FAIL = 'DELETE_USER_INVITATION_FAIL';

export const UPDATE_USER_INVITATION_SUCCESS = 'UPDATE_USER_INVITATION_SUCCESS';
export const UPDATE_USER_INVITATION_FAIL = 'UPDATE_USER_INVITATION_FAIL';

export const SIGNUP_VIA_USER_INVITATION_SUCCESS =
  'SIGNUP_VIA_USER_INVITATION_SUCCESS';
export const SIGNUP_VIA_USER_INVITATION_FAIL =
  'SIGNUP_VIA_USER_INVITATION_FAIL';

export const PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_REQUEST =
  'PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_REQUEST';
export const PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_SUCCESS =
  'PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_SUCCESS';
export const PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_FAILURE =
  'PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_FAILURE';

export const UPDATE_NEWSLETTER_DESIGN_TEMPLATE_REQUEST =
  'UPDATE_NEWSLETTER_DESIGN_TEMPLATE_REQUEST';
export const UPDATE_NEWSLETTER_DESIGN_TEMPLATE_SUCCESS =
  'UPDATE_NEWSLETTER_DESIGN_TEMPLATE_SUCCESS';
export const UPDATE_NEWSLETTER_DESIGN_TEMPLATE_FAILURE =
  'UPDATE_NEWSLETTER_DESIGN_TEMPLATE_FAILURE';

export const SUBSCRIBE_NEWSLETTER_REQUEST = 'SUBSCRIBE_NEWSLETTER_REQUEST';
export const SUBSCRIBE_NEWSLETTER_SUCCESS = 'SUBSCRIBE_NEWSLETTER_SUCCESS';
export const SUBSCRIBE_NEWSLETTER_FAILURE = 'SUBSCRIBE_NEWSLETTER_FAILURE';

export const UNSUBSCRIBE_NEWSLETTER_REQUEST = 'SUBSCRIBE_NEWSLETTER_REQUEST';
export const UNSUBSCRIBE_NEWSLETTER_SUCCESS = 'SUBSCRIBE_NEWSLETTER_SUCCESS';
export const UNSUBSCRIBE_NEWSLETTER_FAILURE = 'SUBSCRIBE_NEWSLETTER_FAILURE';

export const FETCH_EMPLOYER_ORGANISATIONS_SUCCESS =
  'FETCH_EMPLOYER_ORGANISATIONS_SUCCESS';
export const FETCH_EMPLOYER_ORGANISATION_SUCCESS =
  'FETCH_EMPLOYER_ORGANISATION_SUCCESS';
export const FETCH_EMPLOYER_ORGANISATIONS_FAIL =
  'FETCH_EMPLOYER_ORGANISATIONS_FAIL';
export const FETCH_EMPLOYER_ORGANISATION_FAIL =
  'FETCH_EMPLOYER_ORGANISATION_FAIL';
export const UPDATE_EMPLOYER_ORGANISATION_SUCCESS =
  'UPDATE_EMPLOYER_ORGANISATION_SUCCESS';
export const UPDATE_EMPLOYER_ORGANISATION_FAIL =
  'UPDATE_EMPLOYER_ORGANISATION_FAIL';
export const CREATE_EMPLOYER_ORGANISATION_SUCCESS =
  'CREATE_EMPLOYER_ORGANISATION_SUCCESS';
export const CREATE_EMPLOYER_ORGANISATION_FAIL =
  'CREATE_EMPLOYER_ORGANISATION_FAIL';
export const DELETE_EMPLOYER_ORGANISATION_SUCCESS =
  'DELETE_EMPLOYER_ORGANISATION_SUCCESS';
export const DELETE_EMPLOYER_ORGANISATION_FAIL =
  'DELETE_EMPLOYER_ORGANISATION_FAIL';

export const FETCH_TP_ORGANISATIONS_SUCCESS = 'FETCH_TP_ORGANISATIONS_SUCCESS';
export const FETCH_TP_ORGANISATION_SUCCESS = 'FETCH_TP_ORGANISATION_SUCCESS';
export const FETCH_TP_ORGANISATIONS_FAIL = 'FETCH_TP_ORGANISATIONS_FAIL';
export const FETCH_TP_ORGANISATION_FAIL = 'FETCH_TP_ORGANISATION_FAIL';
export const CREATE_TP_ORGANISATION_SUCCESS = 'CREATE_TP_ORGANISATION_SUCCESS';
export const CREATE_TP_ORGANISATION_FAIL = 'CREATE_TP_ORGANISATION_FAIL';
export const UPDATE_TP_ORGANISATION_SUCCESS = 'UPDATE_TP_ORGANISATION_SUCCESS';
export const UPDATE_TP_ORGANISATION_FAIL = 'UPDATE_TP_ORGANISATION_FAIL';
export const DELETE_TP_ORGANISATION_SUCCESS = 'DELETE_TP_ORGANISATION_SUCCESS';
export const DELETE_TP_ORGANISATION_FAIL = 'DELETE_TP_ORGANISATION_FAIL';

// contracts
export const FETCH_CONTRACT_SUCCESS = 'FETCH_CONTRACT_SUCCESS';
export const FETCH_CONTRACT_FAIL = 'FETCH_CONTRACT_FAIL';
