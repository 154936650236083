import { organisationLocations } from '../constants';

const locationTypesNeedExtra = [
  organisationLocations.GP_PRACTICE_PLEASE_SPECIFY_BELOW,
  organisationLocations.VOLUNTARY_THIRD_SECTOR_ORGANISATION_PLEASE_SPECIFY_BELOW,
  organisationLocations.HOUSING_ORGANISATION_PLEASE_SPECIFY_BELOW,
  organisationLocations.EDUCATION_FURTHER_HIGHER_PLEASE_SPECIFY_BELOW,
  organisationLocations.EDUCATION_SECONDARY_PLEASE_SPECIFY_BELOW,
  organisationLocations.EDUCATION_PRIMARY_PLEASE_SPECIFY_BELOW,
  organisationLocations.EDUCATION_EARLY_YEARS_PLEASE_SPECIFY_BELOW
];

export const shouldLocationHaveExtra = location => {
  return locationTypesNeedExtra.includes(location);
};

export const extractLocationExtra = location => {
  if (!location) return '';
  const locationExtraType = locationTypesNeedExtra.find(type =>
    location.includes(type)
  );

  if (locationExtraType) {
    return location
      .replace(locationExtraType, '')
      .replace(/^\s-+\s/, '')
      .trim();
  }
  return '';
};
