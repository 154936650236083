const USER_TYPES = {
  participant: 'participant',
  trainer: 'trainer',
  admin: 'admin',
  programmeManager: 'programmeManager',
  tpOrgAdmin: 'tpOrgAdmin',
  employerOrgAdmin: 'employerOrgAdmin'
};

export const USER_TYPE_LABELS = {
  [USER_TYPES.admin]: 'Admin',
  [USER_TYPES.employerOrgAdmin]: 'Employer Organisation Admin',
  [USER_TYPES.participant]: 'Participant',
  [USER_TYPES.programmeManager]: 'Programme Manager',
  [USER_TYPES.trainer]: 'Trainer',
  [USER_TYPES.tpOrgAdmin]: 'Training Provider Organisation Admin'
};

export default USER_TYPES;
