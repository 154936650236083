import styled from 'styled-components';
import setMargins from '../../../helpers/setMargin';

export const TextWithIcon = styled.button`
  ${setMargins};
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  gap: 6px;
  margin-top: ${({ theme }) => theme.spacings[4]};
`;

export const AuthCodeContainer = styled.div`
  margin-bottom: 24px;
`;

export const AuthCodeWrapper = styled.div`
  width: 280px;
  height: 53px;
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`;

export const AuthCodeDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 151px;
  height: 100%;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const AuthCodeActions = styled.div`
  display: flex;
  height: 100%;
  gap: 4px;
  align-items: flex-end;
  cursor: pointer;
`;

export const DeleteAccountButton = styled.button`
  ${setMargins};
  background: none;
  text-decoration: underline;
  font-weight: 700;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkGray};
`;
