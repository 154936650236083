import styled from 'styled-components';
import setMargins from '../../../helpers/setMargin';

export const Wrapper = styled.div``;

export const ContentWrapper = styled.div``;

export const Form = styled.form`
  background: ${({ theme }) => theme.colors.lightPrimary};
  box-sizing: border-box;
  border-radius: 12px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacings[6]};
`;

export const TextWithIcon = styled.button`
  ${setMargins};
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  gap: 6px;
  margin-top: ${({ theme }) => theme.spacings[4]};
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
