import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import * as R from '../../../constants/navigationRoutes';
import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';

import { useHistory } from 'react-router-dom';
import { signupUserViaInvitationLink } from '../../../actions/authAction';

import Layout from '../../Layouts';
import Button from '../../common/Button';
import Spin from '../../common/Spin';

import { BasicInput, Checkbox, Dropdown } from '../../common/Inputs';

import { fetchUserInvitationDetailsByToken } from '../../../actions/users';
import {
  localAuthoritiesBasic as localAuthoritiesOptions,
  userRoles
} from '../../../constants';
import useFormData from '../../../hooks/useFormData';
import useSearchParams from '../../../hooks/useSearchParams';
import useValidateFormData from '../../../hooks/useValidateFormData';
import validate from '../../../validation/schemas/signup';
import IEMessage from '../../common/IEMessage';
import { ContentWrapper, Form, Wrapper, CheckboxLabel } from './SignUp.style';
import Welcome from './Welcome';

import { fetchEmployerOrganisations } from '../../../actions/employerOrganisationActions';

const OTHER_ORG_LABEL = 'Other - specify below';

const initialFormState = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  localAuthorities: '',
  agreeToTerms: '',
  employerOrganisation: null,
  organisationOther: '',
  submitAttempt: false,
  validationErrors: {},
  showOrgOther: false,
  jobRole: '',
  phone: '',
  newsletterSubscription: false
};

const SignUp = ({
  isAuthenticated,
  loading,
  httpError,
  userInvitation,
  fetchUserInvitationLoading,
  fetchUserInvitationDetailsByToken,
  signupUserViaInvitationLink,
  signupViaInvitationLoading,
  fetchEmployerOrganisations,
  employerOrganisations
}) => {
  const searchParams = useSearchParams();

  const { formState, setFormData } = useFormData({ initialFormState });

  const submitAttempt = useRef(false);

  const includeTrainer = userInvitation?.roles?.some(
    role => role === userRoles.trainer
  );
  const includeTpOrgAdmin = !!userInvitation?.roles?.some(
    role => role === userRoles.tpOrgAdmin
  );
  const includeEmployerOrgAdmin = !!userInvitation?.roles?.some(
    role => role === userRoles.employerOrgAdmin
  );

  const {
    validateForm,
    validationErrors,
    finalSubmissionData
  } = useValidateFormData({
    formState: { ...formState, includeTrainer },
    validateFn: validate,
    submitAttempt
  });

  const token = searchParams.params.token;

  const history = useHistory();

  const {
    name,
    email,
    password,
    employerOrganisation,
    confirmPassword,
    localAuthorities,
    agreeToTerms,
    organisationOther,
    showOrgOther,
    jobRole,
    phone,
    newsletterSubscription
  } = formState;

  const handleSubmit = e => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      handleSignup();
    }
  };

  const handleSignup = async () => {
    const { current: finalFormState } = finalSubmissionData;

    const payload = {
      ...finalFormState,
      invitationToken: token,
      employerOrganisation:
        employerOrganisation === OTHER_ORG_LABEL
          ? undefined
          : employerOrganisation,
      organisation: organisationOther &&
        employerOrganisation === OTHER_ORG_LABEL && {
          value: organisationOther,
          category: 'custom'
        }
    };

    await signupUserViaInvitationLink(payload);

    if (includeTpOrgAdmin) {
      if (userInvitation.tpOrganisation.setupCompleted) {
        // redirect them to "Platform Tour"
        history.push(R.TOUR_URL);
        return;
      }

      history.push(R.COMPLETE_YOUR_ACCOUNT);
      return;
    }

    if (includeEmployerOrgAdmin) {
      if (userInvitation.employerOrganisation.setupCompleted) {
        // redirect them to "Platform Tour"
        history.push(R.TOUR_URL);
        return;
      }

      history.push(R.COMPLETE_YOUR_ACCOUNT);
      return;
    }

    return history.push(R.DASHBOARD_URL);
  };

  useEffect(() => {
    if (userInvitation) {
      setFormData({
        email: userInvitation.email,
        name: userInvitation.fullName
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInvitation]);

  useEffect(() => {
    fetchUserInvitationDetailsByToken(token);
  }, [fetchUserInvitationDetailsByToken, token]);

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    email,
    password,
    employerOrganisation,
    organisationOther,
    localAuthorities,
    jobRole,
    phone,
    newsletterSubscription
  ]);

  useEffect(() => {
    fetchEmployerOrganisations({ access: 'public' });
  }, [fetchEmployerOrganisations]);

  if (!token || fetchUserInvitationLoading || signupViaInvitationLoading) {
    return <Spin />;
  }

  if (isAuthenticated && userInvitation) {
    return (
      <Redirect
        to={{
          pathname: R.COMPLETE_SIGNUP,
          search: `?token=${token}&source=invitation`,
          state: { userInvitation }
        }}
      />
    );
  }

  if (!userInvitation) {
    return (
      <Layout>
        <T.P>
          The invitation link is invalid or expired. Please contact the
          administrator.
        </T.P>
      </Layout>
    );
  }

  const handleAddNewEmployerOrganisation = e => {
    setFormData({
      employerOrganisation: OTHER_ORG_LABEL,
      showOrgOther: true
    });
  };

  const InitialSignupForm = (
    <Form className="signup-form">
      <BasicInput
        value={name}
        handleChange={value => setFormData({ name: value })}
        mb={4}
        label="Name"
        placeholder="Type your name here"
        required
        error={validationErrors.name}
      />
      <BasicInput
        value={email}
        handleChange={value => setFormData({ email: value })}
        label="Email"
        placeholder="Type your email here"
        mb={4}
        required
        error={
          validationErrors.email ||
          (httpError?.response?.status === 409 &&
            httpError?.response?.data?.error)
        }
        disabled
      />
      <BasicInput
        value={phone}
        handleChange={value => setFormData({ phone: value })}
        mb={4}
        label="Phone Number (Optional)"
        placeholder="Type your phone here"
        error={validationErrors.phone}
      />
      <BasicInput
        value={password}
        label="Create password"
        required
        placeholder="Password"
        mb={4}
        type="password"
        handleChange={value => setFormData({ password: value })}
        error={validationErrors.password}
      />
      <BasicInput
        value={confirmPassword}
        label="Confirm Password"
        required
        placeholder="Confirm your password"
        mb={4}
        type="password"
        handleChange={value => setFormData({ confirmPassword: value })}
        error={validationErrors.confirmPassword}
      />
      {includeTrainer && (
        <>
          <BasicInput
            value={jobRole}
            handleChange={value => setFormData({ jobRole: value })}
            mb={4}
            label="Job Title"
            placeholder="Type your job title here"
            required
            error={validationErrors.jobRole}
          />

          <ConfigProvider renderEmpty={() => null}>
            <Dropdown
              m={{ mb: '4' }}
              label="Organisation"
              placeholder="Select"
              required
              options={employerOrganisations?.map(employerOrganisation => ({
                label: employerOrganisation.name,
                value: employerOrganisation._id.toString()
              }))}
              multi={false}
              selected={employerOrganisation}
              handleChange={employerOrganisation =>
                setFormData({ employerOrganisation, showOrgOther: false })
              }
              showSearch={true}
              dropdownRender={menu => (
                <>
                  {menu}
                  <div
                    style={{
                      padding: '8px 12px',
                      cursor: 'pointer'
                    }}
                    onMouseDown={handleAddNewEmployerOrganisation}
                  >
                    Other - specify below
                  </div>
                </>
              )}
              error={validationErrors.employerOrganisation}
            />
          </ConfigProvider>
          {showOrgOther && (
            <BasicInput
              value={organisationOther}
              autoFocus
              handleChange={value => setFormData({ organisationOther: value })}
              placeholder="Type organisation here..."
              mb={3}
              error={
                validationErrors.organisation ||
                validationErrors.organisationOther
              }
            />
          )}
          <Dropdown
            m={{ mb: 4 }}
            label="In which local authorities do you expect to deliver the training?"
            required
            placeholder="Select as many as you like or add new"
            options={localAuthoritiesOptions.map(i => ({
              label: i,
              value: i
            }))}
            addNew
            multi
            selected={localAuthorities}
            handleChange={values => setFormData({ localAuthorities: values })}
            error={validationErrors.localAuthorities}
          />
        </>
      )}
      <Checkbox
        checked={newsletterSubscription}
        handleChange={value => setFormData({ newsletterSubscription: value })}
        mb={3}
        label="I wish to receive Boost monthly bulletin updates via email"
      />
      <Checkbox
        checked={agreeToTerms}
        handleChange={value => setFormData({ agreeToTerms: value })}
        mb={3}
        label={
          <CheckboxLabel>
            I agree to the{' '}
            <T.Link external href={R.TERMS_OF_USE}>
              Terms and Conditions
            </T.Link>{' '}
            and acknowledge the{' '}
            <T.Link external href={R.PRIVACY_POLICY}>
              Privacy Policy
            </T.Link>
          </CheckboxLabel>
        }
      />
      <Row>
        <Col w={[4, 6, 6]} mt={4}>
          {httpError?.response?.data?.error && (
            <T.P color="error"> {httpError?.response?.data?.error}</T.P>
          )}
          <Button
            onClick={handleSubmit}
            type="primary"
            label="Create account"
            loading={loading}
            disabled={!agreeToTerms}
          />
        </Col>
      </Row>

      <T.P mt={4}>
        Already have an account?{' '}
        <T.Link
          to={{
            pathname: '/login'
          }}
        >
          Log in
        </T.Link>
      </T.P>
    </Form>
  );

  const LoginNeeded = (
    <T.P weight={600}>
      Please{' '}
      <T.Link
        d="inline"
        to={{
          pathname: '/login',
          search: `?token=${token}&source=invitation`
        }}
      >
        login
      </T.Link>{' '}
      with your existing account to complete the sign up.
    </T.P>
  );

  return (
    <Layout>
      <T.H1 mb="7">
        {includeTpOrgAdmin || includeEmployerOrgAdmin
          ? 'Create Your Account'
          : 'Sign up'}
      </T.H1>
      <Wrapper className="sign-up">
        <IEMessage />
        <Row>
          <Col w={[4, 12, 8]}>
            <ContentWrapper>
              <Welcome userInvitation={userInvitation} mb="6" />
              {!userInvitation?.isExistingUser
                ? InitialSignupForm
                : LoginNeeded}
            </ContentWrapper>
          </Col>
        </Row>
      </Wrapper>
    </Layout>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  httpError: state.auth.error,
  loading: state.loading.signupLoading,
  userInvitation: state.users.userInvitation,
  fetchUserInvitationLoading: state.loading.fetchUserInvitationLoading,
  signupViaInvitationLoading: state.loading.signupViaInvitationLoading,
  employerOrganisations: state.employerOrganisations.employerOrganisations
});

export default connect(mapStateToProps, {
  fetchUserInvitationDetailsByToken,
  signupUserViaInvitationLink,
  fetchEmployerOrganisations
})(SignUp);
