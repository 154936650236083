import { combineReducers } from 'redux';

import { LOGOUT } from '../constants/actionTypes';

import userResultsReducer from './userResultsReducer';
import usersReducer from './usersReducer';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import trainerReducer from './trainerReducer';
import sessionReducer from './sessionReducer';
import statsReducer from './statsReducer';
import fetchedSessions from './fetchSessionReducer';
import groupsReducer from './groups';
import viewReducer from './viewReducer';
import changePasswordReducer from './changePasswordReducer';
import exportDataReducer from './exportDataReducer';
import demographicsReducer from './demographics';
import surveyReducer from './surveyReducer';
import confirmRegistration from './confirmRegistration';
import storeSessionData from './storeSessionData';
import checkBrowserWidth from './checkBrowserWidthReducer';
import loadingReducer from './loading';
import filters from './filters';
import participantSession from './participantSessionReducer';
import participantResponses from './participantResponsesReducer';
import getInvolvedReducer from './getInvolvedReducer';
import modulesReducer from './modulesReducer';
import courseReducer from './courseReducer';
import searchReducer from './searchReducer';
import programmesReducer from './programmesReducer';
import accessibilityReducer from './accessibilityReducer';
import fileUploadReducer from './fileUploadReducer';
import newsletterReducer from './newsletterReducer';
import employerOrganisationsReducer from './employerOrganisationsReducer';
import tpOrganisationsReducer from './tpOrganisationsReducer';
import contractsReducer from './contractsReducer';

const appReducer = combineReducers({
  auth: authReducer,
  error: errorReducer,
  stats: statsReducer,
  results: userResultsReducer,
  users: usersReducer,
  sessions: fetchedSessions,
  trainers: trainerReducer,
  session: sessionReducer,
  groups: groupsReducer,
  viewLevel: viewReducer,
  changePassword: changePasswordReducer,
  exportData: exportDataReducer,
  demographics: demographicsReducer,
  survey: surveyReducer,
  confirmRegistration,
  storeSessionData,
  checkBrowserWidth,
  loading: loadingReducer,
  filters,
  participantSession,
  participantResponses,
  getInvolved: getInvolvedReducer,
  modules: modulesReducer,
  course: courseReducer,
  search: searchReducer,
  programmes: programmesReducer,
  accessibility: accessibilityReducer,
  fileUpload: fileUploadReducer,
  newsletter: newsletterReducer,
  employerOrganisations: employerOrganisationsReducer,
  tpOrganisations: tpOrganisationsReducer,
  contracts: contractsReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = {};
  }

  return appReducer(state, action);
};

export default rootReducer;
