import styled from 'styled-components';
import Button from '../../common/Button';

export const Wrapper = styled.div`
  width: 100%;
`;
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 48px;
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 389.33px;
  background: ${({ theme }) => theme.colors.lightPrimary};
  border-radius: 4px;
  margin-bottom: ${({ theme }) => theme.spacings[4]};
`;

export const WithdrawWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.mainSecondary};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 32px;
  margin-top: 32px;
`;

export const WithdrawButton = styled(Button)`
  max-width: 320px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
