import styled from 'styled-components';
import { Row } from '../../common/Grid';
import setMargins from '../../../helpers/setMargin';

export const TopRow = styled(Row)`
  margin-top: ${({ theme }) => theme.spacings[7]} !important;
  margin-bottom: ${({ theme }) => theme.spacings[8]} !important;

  @media ${({ theme }) => theme.breakpointsMax.laptop} {
    margin-top: 0 !important;
  }
  @media ${({ theme }) => theme.breakpointsMax.tablet} {
    margin-bottom: 0 !important;
  }
`;

export const Divider = styled.hr`
  width: ${({ full }) => (full ? '100%' : '60%')};
  border: 0;
  background-color: ${({ theme }) => theme.colors.mainPrimary};
  height: 2px;
  margin-bottom: ${({ theme }) => theme.spacings[6]};
  margin-right: auto;
`;

export const ShowMyEventsOnly = styled.div`
  ${setMargins}
  padding: ${({ theme }) => theme.spacings[4]};
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.mainSecondary};
`;
