import {
  FETCH_EMPLOYER_ORGANISATIONS_SUCCESS,
  FETCH_EMPLOYER_ORGANISATION_SUCCESS,
  FETCH_EMPLOYER_ORGANISATIONS_FAIL,
  FETCH_EMPLOYER_ORGANISATION_FAIL,
  UPDATE_EMPLOYER_ORGANISATION_SUCCESS,
  UPDATE_EMPLOYER_ORGANISATION_FAIL,
  CREATE_EMPLOYER_ORGANISATION_SUCCESS,
  CREATE_EMPLOYER_ORGANISATION_FAIL,
  DELETE_EMPLOYER_ORGANISATION_SUCCESS,
  DELETE_EMPLOYER_ORGANISATION_FAIL
} from '../constants/actionTypes';

const initialState = {
  employerOrganisations: [],
  employerOrganisation: null,
  loading: false,
  error: null
};

const employerOrganisationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYER_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        employerOrganisations: action.payload,
        loading: false,
        error: null
      };

    case FETCH_EMPLOYER_ORGANISATION_SUCCESS:
      return {
        ...state,
        employerOrganisation: action.payload,
        loading: false,
        error: null
      };

    case FETCH_EMPLOYER_ORGANISATIONS_FAIL:
    case FETCH_EMPLOYER_ORGANISATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case UPDATE_EMPLOYER_ORGANISATION_SUCCESS:
      return {
        ...state,
        employerOrganisations: state.employerOrganisations.map(org =>
          org._id === action.payload._id ? { ...org, ...action.payload } : org
        ),
        loading: false,
        error: null
      };

    case UPDATE_EMPLOYER_ORGANISATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CREATE_EMPLOYER_ORGANISATION_SUCCESS:
      return {
        ...state,
        employerOrganisations: [...state.employerOrganisations, action.payload],
        loading: false,
        error: null
      };

    case CREATE_EMPLOYER_ORGANISATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case DELETE_EMPLOYER_ORGANISATION_SUCCESS:
      return {
        ...state,
        employerOrganisations: state.employerOrganisations.filter(
          org => org._id !== action.payload
        ),
        loading: false,
        error: null
      };

    case DELETE_EMPLOYER_ORGANISATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default employerOrganisationsReducer;
