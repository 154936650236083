import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

import eventsHeaderImage from '../../../assets/boost/people-focus.jpeg';
import { sessionsFilters } from '../../../constants/filtersData';
import Layout from '../../Layouts';
import Table from '../../common/BoostTable';
import ProgrammeHeader from '../../common/ProgrammeHeader';
import SearchFilter from '../../common/SearchFilter';
import * as T from '../../common/Typography';
import { Checkbox } from '../../common/Inputs';

import { useEffect, useState } from 'react';
import { fetchPublicSessions } from '../../../actions/groupSessionsAction';
import { fetchEmployerOrganisation } from '../../../actions/employerOrganisationActions';
import { Row } from '../../common/Grid';
import MaxWidthContainer from '../../common/MaxWidthContainer';
import Spin from '../../common/Spin';
import { TableColumns } from './TableColumns';
import * as S from './style';
import OrgEventsHeader from './OrgEventsHeader';
import { userRoles } from '../../../constants';

const Events = ({
  fetchPublicSessions,
  sessions: allSessions,
  filteredSessions,
  loading,
  fetchSessionsHttpError,
  fetchEmployerOrganisation,
  fetchEmployerOrganisationLoading,
  fetchedEmployerOrganisation,
  fetchEmployerOrganisationHttpError,
  loggedInUser
}) => {
  const { breakpoints } = useTheme();
  const { slug } = useParams();
  const [showMyEventsOnly, setShowMyEventsOnly] = useState(false);
  const isEmployerOrg = !!slug;
  const isEmployerAdmin = loggedInUser?.role === userRoles.employerOrgAdmin;

  const isTablet = useMediaQuery({
    query: breakpoints.tablet
  });

  useEffect(() => {
    if (isEmployerOrg || showMyEventsOnly) {
      const employerOrgId =
        fetchedEmployerOrganisation?._id || loggedInUser.employerOrganisation;
      if (employerOrgId) {
        fetchPublicSessions({
          upcoming: true,
          onlyEmployerOrgEvents: true,
          employerOrgId
        });
      }
      return;
    }
    fetchPublicSessions({ upcoming: true });
  }, [
    fetchPublicSessions,
    fetchedEmployerOrganisation?._id,
    isEmployerOrg,
    loggedInUser.employerOrganisation,
    showMyEventsOnly
  ]);

  useEffect(() => {
    if (slug) {
      fetchEmployerOrganisation(slug, { isPublic: true });
    }
    if (showMyEventsOnly) {
      fetchEmployerOrganisation(loggedInUser.employerOrganisation);
    }
  }, [
    fetchEmployerOrganisation,
    loggedInUser.employerOrganisation,
    showMyEventsOnly,
    slug
  ]);

  const sessions = filteredSessions || allSessions;

  if (loading || !allSessions || fetchEmployerOrganisationLoading) {
    return <Spin />;
  }

  if (
    isEmployerOrg &&
    (fetchEmployerOrganisationHttpError || fetchSessionsHttpError)
  ) {
    return (
      <Layout layout="website">
        <Row mb="4" jc="center">
          <T.P color="error">
            {fetchSessionsHttpError
              ? fetchSessionsHttpError?.error
                ? fetchSessionsHttpError?.error
                : 'You are not allowed to view this page'
              : 'There was an error fetching your organisation'}
          </T.P>
        </Row>
      </Layout>
    );
  }

  return (
    <Layout layout="website">
      <S.TopRow>
        {isEmployerOrg || showMyEventsOnly ? (
          <OrgEventsHeader
            fetchedEmployerOrganisation={fetchedEmployerOrganisation}
          />
        ) : (
          <ProgrammeHeader
            title="Training & Events"
            description="Here you can see the full list of events coming up. Click on any event title to find out more information, including what learning content will be covered, and to book your place."
            image={eventsHeaderImage}
            withOverlay={true}
          />
        )}
      </S.TopRow>
      <Row mb="4" jc="center">
        <SearchFilter
          category="sessions"
          showSearch
          showFilters
          filters={sessionsFilters}
          title="Find an event"
          additionalFilters={{
            upcoming: true
          }}
          onlyEmployerOrgEvents={isEmployerOrg || showMyEventsOnly}
          employerOrgId={
            fetchedEmployerOrganisation?._id ||
            loggedInUser.employerOrganisation
          }
        />
      </Row>
      <MaxWidthContainer>
        {!isEmployerOrg && isEmployerAdmin && (
          <S.ShowMyEventsOnly mb="6">
            <Checkbox
              checked={showMyEventsOnly}
              handleChange={value => setShowMyEventsOnly(value)}
              label="Show events for my organisation only"
            />
          </S.ShowMyEventsOnly>
        )}
        <Row mb="4" jc="center">
          <Table data={sessions} columns={TableColumns(isTablet)} stripedRows />
        </Row>
      </MaxWidthContainer>
    </Layout>
  );
};
const mapStateToProps = state => ({
  filteredSessions: state.search.sessions?.data,
  sessions: state.sessions.sessions,
  loading: state.loading.fetchSessions,
  fetchSessionsHttpError: state.sessions.error,
  fetchEmployerOrganisationLoading:
    state.loading.fetchEmployerOrganisationLoading,
  fetchedEmployerOrganisation: state.employerOrganisations.employerOrganisation,
  fetchEmployerOrganisationHttpError: state.employerOrganisations.error,
  loggedInUser: state.auth
});

export default connect(mapStateToProps, {
  fetchPublicSessions,
  fetchEmployerOrganisation
})(Events);
