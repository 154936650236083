import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { logout, selectRole } from '../../../actions/authAction';
import logo from '../../../assets/boost/Boost Logo - Black & White.svg';
import useSearchParams from './../../../hooks/useSearchParams';

import {
  ALL_SESSIONS_URL,
  ANALYTICS_URL,
  CHOOSE_PROGRAMME_FOR_SESSIONS,
  DASHBOARD_URL,
  DECIDE_ROLE,
  EVENTS_URL,
  LOGIN_URL,
  MANAGE_PROGRAMMES_PROGRAMME_SELECTION,
  MANAGE_USERS,
  MODULES_URL,
  MY_ACCOUNT_URL,
  MY_SESSIONS_URL,
  PARTICIPANT_DASHBOARD,
  PROGRAMMES,
  CONTARCTS_URL,
  MANAGE_TP_ORGANISATIONS
} from '../../../constants/navigationRoutes';

import USER_TYPES from '../../../constants/userTypes';

import {
  BottomElementsWrapper,
  CloseIconWrapper,
  DividerLine,
  Logo,
  MainDiv,
  MenuDiv,
  MenuItem,
  MenuWrapper
} from './Menu.style';

import Icon from './../Icon';

const Menu = ({
  role,
  logout: logoutAction,
  dark,
  toggleShow,
  setToggleShow,
  isDesktop,
  multipleUsers,
  selectRole
}) => {
  const history = useHistory();
  const searchParams = useSearchParams();
  const location = useLocation();

  const isOnboardingProcess =
    location.pathname.includes('/welcome/onboarding') ||
    searchParams.params.registerAfterAuth === 'true';

  const isUpdateIncompleteProfileProcess = location.pathname.includes(
    '/update-incomplete-profile'
  );

  const disableNavigation =
    isOnboardingProcess || isUpdateIncompleteProfileProcess;

  useEffect(() => {
    return () => {
      const wrapper = document.getElementById('wrapper');
      if (wrapper?.style?.marginLeft) {
        wrapper.style.marginLeft = '0';
      }
    };
  }, []);

  const resetRole = async () => {
    await selectRole(null);
    history.push(DECIDE_ROLE);
  };

  return (
    <MenuDiv>
      {toggleShow && (
        <MenuWrapper dark={dark}>
          {!isDesktop && (
            <CloseIconWrapper
              onClick={() => setToggleShow(false)}
              aria-label="Close"
            >
              <Icon icon="cross" width="24" height="24" color="white" />
            </CloseIconWrapper>
          )}
          <Logo src={logo} alt="logo" />
          <MainDiv>
            {!disableNavigation && (
              <>
                {/* trainer */}
                {role === USER_TYPES.trainer && (
                  <>
                    <MenuItem
                      to={DASHBOARD_URL}
                      onClick={() => setToggleShow(false)}
                    >
                      Home
                    </MenuItem>
                    <MenuItem
                      to={{
                        pathname: ANALYTICS_URL
                      }}
                      onClick={() => setToggleShow(false)}
                    >
                      Analytics
                    </MenuItem>
                    <MenuItem
                      to={MY_SESSIONS_URL}
                      onClick={() => setToggleShow(false)}
                    >
                      Events
                    </MenuItem>
                    <MenuItem
                      to={PROGRAMMES}
                      onClick={() => setToggleShow(false)}
                    >
                      Learning Academy
                    </MenuItem>
                  </>
                )}

                {/* Admin */}
                {(role === USER_TYPES.admin ||
                  role === USER_TYPES.programmeManager) && (
                  <>
                    <MenuItem
                      to={DASHBOARD_URL}
                      onClick={() => setToggleShow(false)}
                    >
                      Home
                    </MenuItem>
                    <MenuItem
                      to={{
                        pathname: ANALYTICS_URL
                      }}
                      onClick={() => setToggleShow(false)}
                    >
                      Analytics
                    </MenuItem>
                    <MenuItem
                      to={ALL_SESSIONS_URL}
                      onClick={() => setToggleShow(false)}
                    >
                      Events
                    </MenuItem>
                    <MenuItem
                      to={MANAGE_PROGRAMMES_PROGRAMME_SELECTION}
                      onClick={() => setToggleShow(false)}
                    >
                      Programmes
                    </MenuItem>
                    <MenuItem
                      to={MANAGE_USERS}
                      onClick={() => setToggleShow(false)}
                    >
                      Users
                    </MenuItem>
                    <MenuItem
                      to={MANAGE_TP_ORGANISATIONS}
                      onClick={() => setToggleShow(false)}
                    >
                      Training Providers
                    </MenuItem>
                    <MenuItem
                      to={PROGRAMMES}
                      onClick={() => setToggleShow(false)}
                    >
                      Learning Academy
                    </MenuItem>
                  </>
                )}

                {/* Employer Org Admin */}
                {role === USER_TYPES.employerOrgAdmin && (
                  <>
                    <MenuItem
                      to={DASHBOARD_URL}
                      onClick={() => setToggleShow(false)}
                    >
                      Home
                    </MenuItem>
                    <MenuItem
                      to={{
                        pathname: ANALYTICS_URL
                      }}
                      onClick={() => setToggleShow(false)}
                    >
                      Analytics
                    </MenuItem>
                    <MenuItem
                      to={ALL_SESSIONS_URL}
                      onClick={() => setToggleShow(false)}
                    >
                      Events
                    </MenuItem>
                    <MenuItem
                      to={MANAGE_PROGRAMMES_PROGRAMME_SELECTION}
                      onClick={() => setToggleShow(false)}
                    >
                      Programmes
                    </MenuItem>
                    {/* <MenuItem
                      to={MANAGE_USERS}
                      onClick={() => setToggleShow(false)}
                    >
                      Users
                    </MenuItem> */}
                    <MenuItem
                      to={PROGRAMMES}
                      onClick={() => setToggleShow(false)}
                    >
                      Learning Academy
                    </MenuItem>
                  </>
                )}

                {/* TP Org Admin */}
                {role === USER_TYPES.tpOrgAdmin && (
                  <>
                    <MenuItem
                      to={DASHBOARD_URL}
                      onClick={() => setToggleShow(false)}
                    >
                      Home
                    </MenuItem>
                    <MenuItem
                      to={{
                        pathname: ANALYTICS_URL
                      }}
                      onClick={() => setToggleShow(false)}
                    >
                      Analytics
                    </MenuItem>
                    <MenuItem
                      to={ALL_SESSIONS_URL}
                      onClick={() => setToggleShow(false)}
                    >
                      Events
                    </MenuItem>
                    {/* <MenuItem
                      to={MANAGE_USERS}
                      onClick={() => setToggleShow(false)}
                    >
                      Users
                    </MenuItem> */}
                    <MenuItem
                      to={PROGRAMMES}
                      onClick={() => setToggleShow(false)}
                    >
                      Learning Academy
                    </MenuItem>
                    <MenuItem
                      to={CONTARCTS_URL}
                      onClick={() => setToggleShow(false)}
                    >
                      Contracts
                    </MenuItem>
                  </>
                )}

                {/* USER */}
                {role === USER_TYPES.participant && (
                  <>
                    <MenuItem
                      to={PARTICIPANT_DASHBOARD}
                      onClick={() => setToggleShow(false)}
                    >
                      Home
                    </MenuItem>
                    <MenuItem
                      to={CHOOSE_PROGRAMME_FOR_SESSIONS}
                      onClick={() => setToggleShow(false)}
                    >
                      My Programmes
                    </MenuItem>
                    <MenuItem
                      to={PROGRAMMES}
                      onClick={() => setToggleShow(false)}
                    >
                      Learning Academy
                    </MenuItem>
                  </>
                )}

                {!role && (
                  <>
                    <MenuItem
                      to="/"
                      dark={dark}
                      onClick={() => setToggleShow(false)}
                    >
                      Home
                    </MenuItem>
                    <MenuItem
                      to={PROGRAMMES}
                      onClick={() => setToggleShow(false)}
                    >
                      Programmes
                    </MenuItem>
                    <MenuItem
                      to={MODULES_URL}
                      onClick={() => setToggleShow(false)}
                    >
                      Modules
                    </MenuItem>
                    <MenuItem
                      to={EVENTS_URL}
                      onClick={() => setToggleShow(false)}
                    >
                      Events
                    </MenuItem>
                    <MenuItem
                      to={LOGIN_URL}
                      onClick={() => setToggleShow(false)}
                    >
                      Sign Up / Sign In
                    </MenuItem>
                  </>
                )}
              </>
            )}
          </MainDiv>
          {/* BOTTOM SECTION */}
          {role && (
            <>
              <DividerLine />
              <BottomElementsWrapper>
                {!disableNavigation && (
                  <>
                    <MenuItem
                      to={MY_ACCOUNT_URL}
                      onClick={() => setToggleShow(false)}
                    >
                      Account Details
                    </MenuItem>
                    {multipleUsers && (
                      <MenuItem onClick={() => resetRole()} to="#">
                        Switch Account
                      </MenuItem>
                    )}
                  </>
                )}
                <MenuItem
                  as="div"
                  onClick={() => logoutAction(history)}
                  lastItem
                >
                  Log out
                </MenuItem>
              </BottomElementsWrapper>
            </>
          )}
        </MenuWrapper>
      )}
    </MenuDiv>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  PIN: state.auth.PIN,
  loaded: state.auth.loaded,
  isDeskTop: state.checkBrowserWidth.isDeskTop,
  userRoles: state.auth.userRoles,
  multipleUsers: state.auth.multipleUsers
});

export default connect(mapStateToProps, {
  logout,
  selectRole
})(withRouter(Menu));
