import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

import Layout from '../../Layouts';
import Table from '../../common/BoostTable';
import SearchFilter from '../../common/SearchFilter';
import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';

import { MANAGE_TP_ORGANISATIONS_ADD } from '../../../constants/navigationRoutes';
import { tpOrganisationsFilters } from '../../../constants/filtersData';

import * as S from './style';

import { TableColumns } from './TableColumns';

import { fetchTPOrganisations } from '../../../actions/tpOrganisationActions';
import { fetchProgrammes } from '../../../actions/programmesActions';

import Spin from '../../common/Spin';
import { userRoles } from '../../../constants';

const ManageTPOrganisations = ({
  fetchTPOrganisationsAction,
  tpOrganisations: allTPOrganisations,
  tpOrganisationsTotalCount,
  filteredTPOrganisations,
  fetchTPOrganisationsLoading,
  searchLoading,
  fetchProgrammesAction,
  fetchProgrammesLoading,
  programmes: allProgrammes,
  authUser
}) => {
  const history = useHistory();

  const { breakpoints } = useTheme();

  const isTablet = useMediaQuery({
    query: breakpoints.tablet
  });

  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchTPOrganisationsAction({ page });
  }, [fetchTPOrganisationsAction, page]);

  useEffect(() => {
    fetchProgrammesAction();
  }, [fetchProgrammesAction]);

  if (
    !Array.isArray(allTPOrganisations) ||
    !Array.isArray(allProgrammes) ||
    fetchProgrammesLoading
  ) {
    return <Spin />;
  }
  const tpOrganisations = filteredTPOrganisations || allTPOrganisations;

  const programmes =
    authUser.role === userRoles.programmeManager
      ? allProgrammes.filter(p => p.managers.includes(authUser.userId))
      : allProgrammes;

  return (
    <Layout>
      <Row>
        <Col w={[4, 6, 6]}>
          <T.H2W>Training Providers</T.H2W>
        </Col>
        <Col w={[4, 6, 6]} jc="flex-end">
          <Button
            label="Add Training Provider"
            width="200px"
            height="auto"
            type="tertiary"
            onClick={() => history.push(MANAGE_TP_ORGANISATIONS_ADD)}
          />
        </Col>
        <S.Divider full />
      </Row>
      <Row jc="center">
        <SearchFilter
          category="tp-organisations"
          showSearch
          showFilters
          filters={tpOrganisationsFilters(programmes)}
          loading={searchLoading}
          searchBarTransparentBg
          fullWidth
        />
      </Row>

      <Row mb="4" jc="center">
        <Table
          data={tpOrganisations}
          columns={TableColumns({
            isTablet
          })}
          loading={fetchTPOrganisationsLoading}
          stripedRows
        />
      </Row>

      {tpOrganisations.length &&
        tpOrganisations.length < tpOrganisationsTotalCount &&
        !filteredTPOrganisations && (
          <Row>
            <Col w={[4, 6, 4]} ml="auto" mr="auto">
              <Button
                label="Load more"
                type="primary"
                onClick={() => setPage(prevPage => prevPage + 1)}
                loading={fetchTPOrganisationsLoading}
              />
            </Col>
          </Row>
        )}
    </Layout>
  );
};
const mapStateToProps = state => ({
  searchLoading: state.loading.searchLoading,
  tpOrganisations: state.tpOrganisations.tpOrganisations,
  tpOrganisationsTotalCount: state.tpOrganisations.totalCount,
  filteredTPOrganisations: state.search?.['tp-organisations']?.data,
  fetchTPOrganisationsLoading: state.loading.fetchTPOrganisationsLoading,
  fetchProgrammesLoading: state.loading.fetchProgrammesLoading,
  programmes: state.programmes.programmes,
  authUser: state.auth
});

const mapActionsToProps = {
  fetchTPOrganisationsAction: fetchTPOrganisations,
  fetchProgrammesAction: fetchProgrammes
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ManageTPOrganisations);
