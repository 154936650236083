import styled from 'styled-components';

export const Divider = styled.hr`
  width: ${({ full }) => (full ? '100%' : '70%')};
  border: 0;
  background-color: ${({ theme }) => theme.colors.mainSecondary};
  height: 1px;
  margin-top: ${({ theme }) => theme.spacings[3]};
  margin-bottom: ${({ theme }) => theme.spacings[6]};
  margin-right: auto;
`;

export const UserCard = styled.div`
  background-color: ${({ theme }) => theme.colors.lightSecondary};
  padding: ${({ theme }) => `${theme.spacings[5]} ${theme.spacings[6]}`};
  margin: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[0]}`};
  border-radius: 12px;
  width: 100%;
`;

export const Form = styled.form`
  width: 100%;
  border-radius: 12px;
`;

export const AwaitingSignupCard = styled.div`
  background-color: ${({ theme }) => theme.colors.mainPrimary};
  padding: ${({ theme }) => `${theme.spacings[5]} ${theme.spacings[6]}`};
  margin: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[0]}`};
  border-radius: 12px;
  width: 100%;
`;

export const SubFormContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.lightPrimary};
  padding: 32px;
  margin-bottom: 40px;
  border-radius: 12px;
`;
