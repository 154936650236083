import { useSelector } from 'react-redux';
import Layout from '../../Layouts';
import AccountPageHeader from '../../common/AccountPageHeader';
import Carousel from '../../common/Carousel/EmblaCarousel';
import { useHistory } from 'react-router-dom';
import { DASHBOARD_URL } from '../../../constants/navigationRoutes';
import { EMPOrgAdmin, TPAdmin } from './constants';

export default function SignupTour() {
  const history = useHistory();
  const { role, name } = useSelector(state => state.auth);

  const getAccountTypeText = () => {
    if (role === 'employerOrgAdmin') {
      return 'Organisation Admin';
    } else return 'Training Provider';
  };

  const handleSkipTour = () => {
    history.push(DASHBOARD_URL);
  };

  return (
    <Layout>
      <AccountPageHeader
        title={`Welcome!`}
        subtitle={`Welcome to the Learning Academy, ${
          name.split(' ')[0]
        }! Your ${getAccountTypeText()} account has been successfully created. Let’s get started.`}
      />
      {role && (
        <Carousel
          slides={role === 'employerOrgAdmin' ? EMPOrgAdmin : TPAdmin}
          onSkip={handleSkipTour}
        />
      )}
    </Layout>
  );
}
