import React from 'react';
import * as T from '../../common/Typography';
import { userRoles } from '../../../constants';

const Welcome = ({ userInvitation = {}, mb }) => {
  const includeTpOrgAdmin = userInvitation.roles?.some(
    role => role === userRoles.tpOrgAdmin
  );
  const includeEmployerOrgAdmin = userInvitation.roles?.some(
    role => role === userRoles.employerOrgAdmin
  );

  if (includeTpOrgAdmin) {
    const isSetupCompleted = userInvitation.tpOrganisation?.setupCompleted;
    return (
      <T.P weight={400} mb={mb}>
        You have been invited {isSetupCompleted ? 'to join ' : 'to set up '}
        <b>{userInvitation.tpOrganisation?.name}</b> as{' '}
        {isSetupCompleted
          ? 'an administrator'
          : 'a Training Provider on the Learning Academy'}
        . To accept the invite, click below.
      </T.P>
    );
  }

  if (includeEmployerOrgAdmin) {
    const isSetupCompleted =
      userInvitation.employerOrganisation?.setupCompleted;
    return (
      <T.P weight={400} mb={mb}>
        You have been invited{' '}
        {isSetupCompleted ? 'to join ' : 'to set up an account for '}
        <b>{userInvitation.employerOrganisation?.name}</b>{' '}
        {isSetupCompleted ? 'as an administrator' : ''}. To accept the invite,
        click below.
      </T.P>
    );
  }

  return (
    <T.P weight={400}>
      <T.P weight={400}>
        Hi,{' '}
        <T.P d="inline" weight={600}>
          {userInvitation.fullName}.
        </T.P>{' '}
        To complete creating your new account as{' '}
        {userInvitation.roles.length > 1
          ? `(${userInvitation.roles
              .slice(0, -1)
              .join(', ')} and ${userInvitation.roles.at(-1)})`
          : userInvitation.roles[0]}{' '}
        on the platform, please login here.
      </T.P>
    </T.P>
  );
};

export default Welcome;
