export const HOME_URL = '/';
export const LOGIN_URL = '/login';
export const INVITATION_SIGN_UP_URL = '/signup/invitation';
export const COMPLETE_SIGNUP = '/complete-signup';
export const DECIDE_ROLE = '/decide-role';
export const PARTICIPANT_SIGN_UP_URL = '/participant/signup';
export const PARTICIPANT_LOGIN = '/participant-login';
export const UNAUTHORIZED = '/unauthorized';
export const CERTIFIED_TRAINER = '/certified-trainer';
export const TRAINER_RESOURCES_AND_ADVICE = '/trainer-resources-and-advice';
export const HOW_IT_WORKS = '/how-it-works';
export const UPDATE_INCOMPLETE_PROFILE = '/update-incomplete-profile';
export const UNSUBSCRIBE_NEWSLETTER_URL = '/newsletter/unsubscribe';

// ALL
export const DASHBOARD_URL = '/dashboard';
export const ADD_SESSION_URL = '/manage/events/add';
export const EDIT_SESSION_URL = '/manage/events/:sessionId/edit';
export const MODULES_URL = '/modules';
export const SESSION_CREATED_URL = '/session-created/:id';
export const SURVEY_URL = '/survey/:id/:type';
export const ONBOARDING_SURVEY_QUESTIONS_URL = '/welcome/:type/:step';
export const SURVEY_QUESTIONS_URL = '/survey/:id/:type/:step/:success';
export const SESSION_DETAILS_URL = '/session-details/:id';
export const MY_PROFILE_URL = '/my-profile';
export const MY_ACCOUNT_URL = '/my-account';
export const PROGRAMMES = '/programmes';
export const INDIVIDUAL_PROGRAMME = '/programmes/:slug';
export const PROGRAMME_CATALOGUE = '/programmes/:slug/catalogue';
export const POVERTY_PROOFING_PROGRAMME_URL = '/programmes/poverty-proofing';

export const MODULE_URL = '/module/:id';
export const MODULE_STEPS_URL = '/module/:id/steps';
export const ALCOHOL_STUDIES = '/alcohol-studies';
export const POVERTY_PROOFING_URL = '/poverty-proofing';
export const TOBACCO_URL = '/tobacco-dependency';
export const CONNECT5_URL = '/connect5';
export const MECC_URL = '/mecc';
export const ALCOHOL_STUDIES_ABOUT_US_URL = '/alcohol-studies/about-us';

export const ALCOHOL_CATALOGUE_URL = '/alcohol-studies/catalogue';
export const POVERTY_CATALOGUE_URL = '/poverty-proofing/catalogue';
export const TOBACCO_CATALOGUE_URL = '/tobacco-dependency/catalogue';

export const POVERTY_MODULE_URL = '/poverty-proofing/module/:id';
export const TOBACCO_MODULE_URL = '/tobacco-dependency/module/:id';

export const MY_SESSIONS_URL = '/my-sessions';
export const MY_COURSES_URL = '/my-courses';
export const ALL_SESSIONS_URL = '/all-sessions';
export const ABOUT_URL = '/about';
export const TRAINER_PUBLIC_SESSIONS = '/available-sessions/:id';
export const PARTICIPANT_ASSESSMENT = '/module/:id/assessment';

export const EVENTS_URL = '/events';
export const ORG_EVENTS_EVENTS_URL = '/org-events/:slug';
export const INDIVIDUAL_EVENT = '/events/:id';
export const INDIVIDUAL_EVENT_GROUP_BOOKING = '/events/:id/group-booking';

export const ANALYTICS_URL = '/analytics';

export const EVENT_REGISTER_URL = '/register/:shortId';

// TP and EMP Org
export const TOUR_URL = '/dashboard/tour';
export const COMPLETE_YOUR_ACCOUNT = '/complete-your-account';

// TP Admin
export const CONTARCTS_URL = '/contracts';

// EMP_ORG_ADMIN
export const AUTH_CODE_URL = '/dashboard/auth-code';

// trainer
export const TRAINER_SESSIONS_URL = '/trainer-sessions/trainerId?';
export const TRAINER_FEEDBACK_URL = '/feedback';
export const USERS_URL = '/users';

export const EVAL_RESULTS = '/survey/:id/:surveyType/results';

// admin
// for menu & navbar
export const MY_GROUP_SESSIONS_URL = '/group-sessions';
export const ARCHIVED_MODULES = '/archived-modules';
// for App react router
export const ADD_TRAINER_URL = '/add-trainer';
// export const DECIDE_VIEW_URL = '/welcome-back';

// admin
export const DEMOGRAPHICS_URL = '/demographics';

// participant
export const USER_INSIGHTS = '/my-insights';
export const USER_PROGRESS = '/my-progress';
export const USER_LOGIN = '/user-login';
export const USER_DASHBOARD = '/my-dashboard';
export const FORGET_PASSWORD = '/forget-password';
export const PARTICIPANT_DASHBOARD = '/participant-dashboard';
export const PARTICIPANT_SESSIONS_LIST =
  '/participant-sessions-list/:programmeSlug';
export const PARTICIPANT_SESSION_DETAILS = '/participant-session-details/:id';
export const CERTIFICATE = '/certificate/:id';
export const CHOOSE_PROGRAMME_FOR_SESSIONS = '/choose-programme-sessions';

export const ACCESSIBILITY = '/accessibility';

export const ELEARNING_TEST = '/test-elearning';

// Dashboard
export const MANAGE_PROGRAMMES_PROGRAMME_SELECTION =
  '/manage/programmes/select';
export const MANAGE_PROGRAMMES_PROGRAMME_OVERVIEW =
  '/manage/programmes/:slug/overview';
export const MANAGE_PROGRAMMES_PROGRAMME_EDIT = '/manage/programmes/:slug/edit';
export const MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES =
  '/manage/programmes/:slug/categories';
export const MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES_ARCHIVED =
  '/manage/programmes/:slug/categories/archived';
export const MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES_ADD =
  '/manage/programmes/:slug/categories/add';
export const MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES_EDIT =
  '/manage/programmes/:slug/categories/:categoryId/edit';
export const MANAGE_PROGRAMMES_PROGRAMME_MODULES =
  '/manage/programmes/:slug/modules';
export const MANAGE_PROGRAMMES_PROGRAMME_MODULE_ADD =
  '/manage/programmes/:programmeSlug/modules/add';
export const MANAGE_PROGRAMMES_PROGRAMME_MODULE_EDIT =
  '/manage/programmes/:programmeSlug/modules/:id/edit';

export const MANAGE_USERS = '/manage/users';
export const MANAGE_USERS_EDIT = '/manage/users/:credId/edit';
export const MANAGE_USERS_ADD = '/manage/users/add';

export const PRIVACY_POLICY =
  'https://boost.org.uk/website-terms-and-conditions/privacy-policy/';

export const TERMS_OF_USE =
  'https://boost.org.uk/website-terms-and-conditions/';

export const NHS = 'https://northeastnorthcumbria.nhs.uk/';
export const CNE = 'https://children-ne.org.uk';
export const CNE_POVERTY_PROOFING =
  'https://children-ne.org.uk/how-we-can-help/poverty-proofing-services';

export const TOBACCO_LEARN_MORE =
  'https://northeastnorthcumbria.nhs.uk/our-work/workstreams/health-and-prevention/tobacco-programme/';
export const TOBACCO_FOLLOW_X = 'https://twitter.com/SmokefreeNHS_NE';

export const ACCESSABILITY_GUIDELINES =
  'https://webaim.org/resources/contrastchecker/';

export const CHROME_GOOGLE_SPEAK =
  'https://chrome.google.com/webstore/detail/voice-in-voice-typing/pjnefijmagpdjfhhkpljicbbpicelgko';
export const VOICE_ALOUD_READER_ANDROID =
  'https://play.google.com/store/apps/details?id=com.hyperionics.avar&utm_source=allhunan.com';
export const VOICE_ALOUD_READER_APPLE =
  'https://apps.apple.com/us/app/voice-aloud-reader/id1446876360';
export const APPLE_ACCESSIBILITY =
  'https://www.apple.com/uk/accessibility/#:~:text=AssistiveTouch%20%2B%20Apple%20Watch,-AssistiveTouch%20for%20Apple&text=Answer%20incoming%20calls%2C%20control%20an,Centre%2C%20Control%20Centre%20and%20more.&text=You%20can%20also%20use%20AssistiveTouch,Control%20settings%20on%20your%20iPhone.';
export const ANDROID_ACCESSIBILITY =
  'https://support.google.com/accessibility/android/answer/6006564?hl=en';

export const EXTERNAL_LEARNING_ACADEMY =
  'https://boost.org.uk/learning-academy/';

export const MANAGE_TP_ORGANISATIONS = '/manage/tp-organisations';
export const MANAGE_TP_ORGANISATIONS_EDIT = '/manage/tp-organisations/:id/edit';
export const MANAGE_TP_ORGANISATIONS_ADD = '/manage/tp-organisations/add';
