import styled from 'styled-components';
import { Link } from 'react-router-dom';
import setMargin from '../../../helpers/setMargin';

export const ViewSessionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const TotalSessions = styled.div`
  display: flex;
`;

export const Span = styled.p`
  text-align: center;
`;

export const SessionsCount = styled.p`
  text-align: center;
  font-weight: 600;
  font-size: calc(var(--font-size-adjustment) * 1.5rem);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const LinkBtn = styled(Link)`
  background-color: ${({ theme }) => theme.colors.lightPrimary};
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.colors.lightPrimary};
  width: 200px;
  display: block;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 2rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  font-size: calc(var(--font-size-adjustment) * 1rem);
  padding: 14px 25px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #2f6627;
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.darkGray};
    border: 1px solid ${({ theme }) => theme.colors.lightPrimary};
  }
  &:active {
    position: relative;
    top: 1px;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 2rem;
`;

export const Divider = styled.hr`
  width: ${({ full }) => (full ? '100%' : '70%')};
  border: 0;
  background-color: ${({ theme }) => theme.colors.mainPrimary};
  height: 2px;
  margin-bottom: ${({ theme }) => theme.spacings[3]};
  margin-right: auto;
`;

export const ShareSessionContainer = styled.div`
  ${setMargin};
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  gap: ${({ theme }) => theme.spacings[5]};
  padding: ${({ theme }) => theme.spacings[6]};
  border: 1px solid ${({ theme }) => theme.colors.midPrimary};

  @media ${({ theme }) => theme.breakpointsMax.mobileXL} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacings[4]};
    padding: ${({ theme }) => theme.spacings[3]};
  }
`;

export const ShareSessionText = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
`;
