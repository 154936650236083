import React, { useEffect, useState } from 'react';
import Icon from '../Icon';
import * as S from './style';
import * as T from '../Typography';

const CopyLink = ({ link, big, small, title, ...props }) => {
  const [copied, setCopied] = useState(false);
  const inviteLink = link ? link : 'https://www.test.com/test';

  const copyCodeToClipboard = () => {
    setCopied(false);
    const el = document.createElement('textArea');
    el.value = inviteLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 750);
    }
  }, [copied]);

  if (big)
    return (
      <S.Container {...props}>
        <S.BigWrapper>
          {title && (
            <T.P weight={400} mb="4" color="neutralGray">
              {title}
            </T.P>
          )}
          <S.CopyLinkWrapper>
            <T.P
              weight={700}
              color="gray"
              bold
              style={{
                textDecoration: 'underline',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
            >
              {inviteLink}
            </T.P>
            <S.CopyBtn
              onClick={copyCodeToClipboard}
              big={big}
              aria-label="Copy"
            >
              <T.H5
                weight={700}
                color="darkGray"
                style={{ whiteSpace: 'nowrap' }}
              >
                Copy
              </T.H5>
              <Icon icon="copy" color="#FDC800" width="16" height="16" ml="1" />
            </S.CopyBtn>
          </S.CopyLinkWrapper>
          {copied && (
            <Icon
              icon="circleTick"
              text="Copied!"
              color="primary"
              width="16"
              height="16"
              mt="2"
            />
          )}
        </S.BigWrapper>
      </S.Container>
    );
  return (
    <S.Container {...props}>
      <S.Wrapper small={small}>
        <T.P color="gray" bold style={{ textDecoration: 'underline' }}>
          {inviteLink}
        </T.P>
        <S.CopyBtn ml="3" onClick={copyCodeToClipboard} aria-label="Copy">
          <T.H5 color="darkGray">Copy</T.H5>
          <Icon icon="copy" color="darkGray" width="16" height="16" ml="1" />
        </S.CopyBtn>
      </S.Wrapper>
      {copied && (
        <S.IconWrapper>
          <Icon
            icon="circleTick"
            text="Copied!"
            color="primary"
            width="16"
            height="16"
            mr="4"
            mt="2"
            style={{ justifyContent: 'flex-end' }}
          />
        </S.IconWrapper>
      )}
    </S.Container>
  );
};

export default CopyLink;
