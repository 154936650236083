import styled from 'styled-components';
import * as T from '../Typography';

export const CarouselContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.lightPrimary};
  border-radius: 0.75rem;
  padding: 2rem;
  max-width: 600px;
  @media (max-width: 500px) {
    padding: 0.75rem;
  }
`;

export const Carousel = styled.section`
  max-width: 48rem;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 2rem;
  --slide-size: 100%;
`;

export const CarouselViewPort = styled.div`
  overflow: hidden;
`;

export const SlidesContainer = styled.div`
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
`;

export const Slide = styled.div`
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
`;

export const SlideInfoContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  min-height: 200px;
`;

export const SlideContentContainer = styled.div`
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
  user-select: none;
`;

export const DotsContainer = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
  gap: 0.5rem;
`;

export const CarouselControls = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
    & > button {
      width: 100%;
      max-width: 100% !important;
    }
  }
`;

export const StyledDotButton = styled.button`
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 0.5rem;
  height: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary : theme.colors.lightGray};
`;

export const StyledSlideHeading = styled(T.H5)`
  font-size: 18px !important;
`;
