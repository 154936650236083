import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, useLocation, matchPath } from 'react-router-dom';

import { surveyTypesNew, userRoles } from '../../../constants';
import SpinWrapper from '../Spin';

import * as R from '../../../constants/navigationRoutes';
import { LOGIN_URL, UNAUTHORIZED } from '../../../constants/navigationRoutes';
import authorization from '../../../helpers/authorization';
import withTheme from '../../../hoc/withTheme';
import useSearchParams from '../../../hooks/useSearchParams';

const PrivateRoute = ({
  loaded,
  Component,
  path,
  exact,
  isAuthenticated,
  allowedRoles,
  role,
  onboardingCompleted,
  incompleteProfile,
  navbar,
  isMobile,
  userId,
  isPrivate,
  publicOnly,
  multipleUsers,
  decideRole,
  theme,
  GlobalStyles,
  ...rest
}) => {
  // const history = useHistory();
  const location = useLocation();
  const searchParams = useSearchParams();
  const matchPreCourseProcess = matchPath(location.pathname, {
    path: R.SURVEY_QUESTIONS_URL,
    exact: true,
    strict: false
  });

  const isUpdateIncompleteProfileProcess = location.pathname.includes(
    '/update-incomplete-profile'
  );

  const isOnboardingProcess = location.pathname.includes('/welcome/onboarding');
  const isOnboardingCompleted =
    searchParams.params.onboardingCompleted === 'true';

  const registerAfterAuth = searchParams.params.registerAfterAuth === 'true';
  const initialSignup = searchParams.params.initialSignup === 'true';
  const isUpgradeInvitation = (searchParams.params.source = 'invitation');

  if (!loaded) {
    return <SpinWrapper />;
  }

  if (incompleteProfile && !isUpdateIncompleteProfileProcess && userId) {
    const redirectAfterCompleteProfile = registerAfterAuth
      ? encodeURIComponent(location.pathname + location.search)
      : null;

    return (
      <Redirect
        to={{
          pathname: R.UPDATE_INCOMPLETE_PROFILE,
          search: redirectAfterCompleteProfile
            ? `?redirect=${redirectAfterCompleteProfile}`
            : ''
        }}
      />
    );
  }

  if (
    role === userRoles.participant &&
    !matchPreCourseProcess &&
    !onboardingCompleted &&
    !isOnboardingProcess &&
    !isOnboardingCompleted &&
    !registerAfterAuth &&
    !initialSignup &&
    !isUpdateIncompleteProfileProcess &&
    !isUpgradeInvitation
  ) {
    return (
      <Redirect
        to={R.ONBOARDING_SURVEY_QUESTIONS_URL.replace(
          ':type',
          surveyTypesNew.ONBOARDING
        ).replace(':step', 0)}
      />
    );
  }

  if (multipleUsers && !userId) {
    if (decideRole) {
      return (
        <Route
          path={path}
          {...rest}
          render={LinkProps => (
            <Component {...LinkProps} {...rest} role={role} />
          )}
        />
      );
    }

    return <Redirect to={R.DECIDE_ROLE} />;
  }

  if ((publicOnly || decideRole) && userId && !registerAfterAuth) {
    if (role === userRoles.participant) {
      return (
        <Redirect to={location?.state?.redirectTo || R.PARTICIPANT_DASHBOARD} />
      );
    }

    return (
      <Redirect
        to={{
          pathname: location?.state?.redirectTo || R.DASHBOARD_URL,
          search: searchParams.toString()
        }}
      />
    );
  }

  if (isPrivate) {
    const authorized = authorization(role, allowedRoles);
    if (userId) {
      return (
        <Route
          path={path}
          {...rest}
          render={LinkProps =>
            // eslint-disable-next-line no-nested-ternary

            authorized ? (
              theme ? (
                withTheme(Component, theme, GlobalStyles)(LinkProps)
              ) : (
                <Component {...LinkProps} {...rest} role={role} />
              )
            ) : (
              <Redirect to={UNAUTHORIZED} />
            )
          }
        />
      );
    }

    return <Redirect to={LOGIN_URL} />;
  }

  return (
    <Route
      path={path}
      {...rest}
      render={LinkProps =>
        theme ? (
          withTheme(Component, theme, GlobalStyles)(LinkProps)
        ) : (
          <Component {...LinkProps} {...rest} role={role} />
        )
      }
    />
  );
};

const mapStateToProps = state => {
  return {
    isMobile: state.checkBrowserWidth.isMobile,
    userId: state.auth.userId,
    role: state.auth.role,
    loaded: state.auth.loaded,
    credentialId: state.auth.credentialId,
    multipleUsers: state.auth.multipleUsers,
    onboardingCompleted: state.auth.onboardingCompleted,
    incompleteProfile: state.auth.incompleteProfile
  };
};

export default connect(mapStateToProps)(PrivateRoute);
