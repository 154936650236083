import { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';

import * as R from '../../../constants/navigationRoutes';
import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';
import { ConfigProvider } from 'antd';

import { useHistory } from 'react-router-dom';
import { signupUserViaInvitationLink } from '../../../actions/authAction';

import Layout from '../../Layouts';
import Button from '../../common/Button';
import Spin from '../../common/Spin';

import { BasicInput, Dropdown } from '../../common/Inputs';

import { fetchUserInvitationDetailsByToken } from '../../../actions/users';
import {
  localAuthoritiesBasic as localAuthoritiesOptions,
  userRoles
} from '../../../constants';
import useFormData from '../../../hooks/useFormData';
import useSearchParams from '../../../hooks/useSearchParams';
import useValidateFormData from '../../../hooks/useValidateFormData';
import validate from '../../../validation/schemas/signup';
import IEMessage from '../../common/IEMessage';
import { ContentWrapper, Form, Wrapper } from './CompleteSignUp.style';
import { fetchEmployerOrganisations } from '../../../actions/employerOrganisationActions';
import Welcome from './Welcome';

const OTHER_ORG_LABEL = 'Other - specify below';

const initialFormState = {
  name: '',
  localAuthorities: '',
  agreeToTerms: '',
  employerOrganisation: null,
  organisationOther: '',
  submitAttempt: false,
  validationErrors: {},
  showOrgOther: false,
  showOrgIcb: false,
  jobRole: '',
  phone: '',
  newsletterSubscription: false,
  userUpgrade: true
};

const CompleteSignUp = ({
  isAuthenticated,
  authUser,
  loading,
  httpError,
  fetchedUserInvitation,
  fetchUserInvitationLoading,
  fetchUserInvitationDetailsByToken,
  signupUserViaInvitationLink,
  signupViaInvitationLoading,
  fetchEmployerOrganisations,
  employerOrganisations
}) => {
  const searchParams = useSearchParams();
  const { formState, setFormData } = useFormData({ initialFormState });

  const submitAttempt = useRef(false);

  const history = useHistory();
  const userInvitation = useMemo(() => {
    if (fetchedUserInvitation) {
      return fetchedUserInvitation;
    }
    return history.location.state?.userInvitation;
  }, [fetchedUserInvitation, history.location.state?.userInvitation]);

  const includeTrainer = userInvitation?.roles?.some(
    role => role === userRoles.trainer
  );
  const includeTpOrgAdmin = !!userInvitation?.roles?.some(
    role => role === userRoles.tpOrgAdmin
  );
  const includeEmployerOrgAdmin = !!userInvitation?.roles?.some(
    role => role === userRoles.employerOrgAdmin
  );

  const {
    validateForm,
    validationErrors,
    finalSubmissionData
  } = useValidateFormData({
    formState: { ...formState, includeTrainer },
    validateFn: validate,
    submitAttempt
  });

  const token = searchParams.params.token;

  const {
    organisation = {},
    localAuthorities,
    organisationOther,
    showOrgOther,
    employerOrganisation,
    jobRole
  } = formState;

  const handleSubmit = e => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      handleSignup();
    }
  };

  const handleSignup = async () => {
    const { current: finalFormState } = finalSubmissionData;

    const payload = {
      ...finalFormState,
      invitationToken: token,
      employerOrganisation:
        employerOrganisation === OTHER_ORG_LABEL
          ? undefined
          : employerOrganisation,
      organisation: organisationOther &&
        employerOrganisation === OTHER_ORG_LABEL && {
          value: organisationOther,
          category: 'custom'
        }
    };

    await signupUserViaInvitationLink(payload);

    if (includeTpOrgAdmin) {
      if (userInvitation.tpOrganisation.setupCompleted) {
        // redirect them to "Platform Tour"
        history.push(R.TOUR_URL);
        return;
      }

      history.push(R.COMPLETE_YOUR_ACCOUNT);
      return;
    }

    if (includeEmployerOrgAdmin) {
      if (userInvitation.employerOrganisation.setupCompleted) {
        // redirect them to "Platform Tour"
        history.push(R.TOUR_URL);
        return;
      }

      history.push(R.COMPLETE_YOUR_ACCOUNT);
      return;
    }

    return history.push(R.DASHBOARD_URL);
  };

  useEffect(() => {
    if (userInvitation) {
      setFormData({
        email: userInvitation.email,
        jobRole: authUser.jobRole,
        organisation: authUser.organisation,
        employerOrganisation: authUser.employerOrganisation,
        localAuthorities: authUser.localAuthorities?.filter(la => !!la)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInvitation]);

  useEffect(() => {
    if (!history.location.state?.userInvitation) {
      fetchUserInvitationDetailsByToken(token);
    }
  }, [
    fetchUserInvitationDetailsByToken,
    token,
    history.location.state?.userInvitation
  ]);

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation, localAuthorities, jobRole, organisationOther]);

  useEffect(() => {
    fetchEmployerOrganisations({ access: 'public' });
  }, [fetchEmployerOrganisations]);

  if (!token || fetchUserInvitationLoading || signupViaInvitationLoading) {
    return <Spin />;
  }

  if (
    isAuthenticated &&
    userInvitation &&
    authUser?.email !== userInvitation?.email
  ) {
    return (
      <Layout>
        <T.P>
          {' '}
          You are already logged in with a different account. Please log out and
          try again with the correct account.
        </T.P>
      </Layout>
    );
  }

  if (!userInvitation) {
    return (
      <Layout>
        <T.P>
          The invitation link is invalid or expired. Please contact the
          administrator.
        </T.P>
      </Layout>
    );
  }

  const handleAddNewEmployerOrganisation = e => {
    setFormData({
      employerOrganisation: OTHER_ORG_LABEL,
      showOrgOther: true
    });
  };

  return (
    <Layout>
      <T.H1 mb="7">Complete {includeTrainer ? 'Trainer' : ''} Sign Up</T.H1>
      <Wrapper className="sign-up">
        <IEMessage />
        <Row>
          <Col w={[4, 12, 8]}>
            <ContentWrapper>
              <Welcome userInvitation={userInvitation} mb="6" />
              <Form className="complete-signup-form">
                {includeTrainer && (
                  <>
                    <BasicInput
                      value={jobRole}
                      handleChange={value => setFormData({ jobRole: value })}
                      mb={4}
                      label="Job Title"
                      placeholder="Type your job title here"
                      required
                      error={validationErrors.jobRole}
                    />
                    <ConfigProvider renderEmpty={() => null}>
                      <Dropdown
                        m={{ mb: '4' }}
                        label="Organisation"
                        placeholder="Select"
                        required
                        options={employerOrganisations?.map(
                          employerOrganisation => ({
                            label: employerOrganisation.name,
                            value: employerOrganisation._id.toString()
                          })
                        )}
                        multi={false}
                        selected={employerOrganisation}
                        handleChange={employerOrganisation =>
                          setFormData({
                            employerOrganisation,
                            showOrgOther: false
                          })
                        }
                        showSearch={true}
                        dropdownRender={menu => (
                          <>
                            {menu}
                            <div
                              style={{
                                padding: '8px 12px',
                                cursor: 'pointer'
                              }}
                              onMouseDown={handleAddNewEmployerOrganisation}
                            >
                              Other - specify below
                            </div>
                          </>
                        )}
                        error={validationErrors.employerOrganisation}
                      />
                    </ConfigProvider>
                    {showOrgOther && (
                      <BasicInput
                        value={organisationOther}
                        autoFocus
                        handleChange={value =>
                          setFormData({ organisationOther: value })
                        }
                        placeholder="Type organisation here..."
                        mb={3}
                        error={
                          validationErrors.organisation ||
                          validationErrors.organisationOther
                        }
                      />
                    )}
                    <Dropdown
                      m={{ mb: 4 }}
                      label="In which local authorities do you expect to deliver the training?"
                      required
                      placeholder="Select as many as you like or add new"
                      options={localAuthoritiesOptions.map(i => ({
                        label: i,
                        value: i
                      }))}
                      addNew
                      multi
                      selected={localAuthorities}
                      handleChange={values =>
                        setFormData({ localAuthorities: values })
                      }
                      error={validationErrors.localAuthorities}
                    />
                  </>
                )}
                <Row>
                  <Col w={includeTrainer ? [4, 6, 6] : [4, 12, 12]} mt={4}>
                    {httpError?.response?.data?.error && (
                      <T.P color="error">
                        {' '}
                        {httpError?.response?.data?.error}
                      </T.P>
                    )}
                    <Button
                      onClick={handleSubmit}
                      type="primary"
                      label={includeTrainer ? 'Submit' : 'Complete Sign Up'}
                      loading={loading}
                      disabled={validationErrors.hasError}
                    />
                  </Col>
                </Row>
              </Form>
            </ContentWrapper>
          </Col>
        </Row>
      </Wrapper>
    </Layout>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  authUser: state.auth,
  httpError: state.auth.error,
  loading: state.loading.signupLoading,
  fetchedUserInvitation: state.users.userInvitation,
  fetchUserInvitationLoading: state.loading.fetchUserInvitationLoading,
  signupViaInvitationLoading: state.loading.signupViaInvitationLoading,
  employerOrganisations: state.employerOrganisations.employerOrganisations
});

export default connect(mapStateToProps, {
  fetchUserInvitationDetailsByToken,
  signupUserViaInvitationLink,
  fetchEmployerOrganisations
})(CompleteSignUp);
