import { Row, Col } from '../../common/Grid';
import * as T from '../../common/Typography';
import CopyLinkComponent from '../../common/CopyLink';
import MaxWidthContainer from '../../common/MaxWidthContainer';
import { ORG_EVENTS_EVENTS_URL } from '../../../constants/navigationRoutes';

import * as S from './style';

const OrgEventsHeader = ({ fetchedEmployerOrganisation }) => {
  return (
    <MaxWidthContainer>
      <Row fullWidth>
        <Col w={[4, 12, 12]} dir="column" ai="flex-start">
          <T.H1 color="darkGray" weight="700" mb="5">
            Training & Events for {fetchedEmployerOrganisation?.name}
          </T.H1>
          <S.Divider />

          <T.P smallMedium>
            Here you can see the full list of{' '}
            <b style={{ fontSize: 'inherit' }}>exclusive training events</b>{' '}
            provided by your organisation. Click on any event title to find out
            more information, including what learning content will be covered,
            and to book your place.
          </T.P>
        </Col>
      </Row>
      <Row mt="5">
        <Col w={[4, 12, 8]}>
          <T.P weight="600" mb="2">
            Use this link to share your private organisation page
          </T.P>
          <CopyLinkComponent
            small
            link={`${
              process.env.NODE_ENV === 'production' ? 'https://' : 'http://'
            }${window.location.host}${ORG_EVENTS_EVENTS_URL.replace(
              ':slug',
              fetchedEmployerOrganisation?.slug
            )}`}
          />
        </Col>
      </Row>
    </MaxWidthContainer>
  );
};

export default OrgEventsHeader;
