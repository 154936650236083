import { validate as _validate, createSchema } from '..';
import { arrayOfRequiredString, requiredText } from '../fields';

const tpOrganisationSchema = createSchema({
  name: requiredText,
  programmes: arrayOfRequiredString
});

const validate = data => {
  return _validate(tpOrganisationSchema, data);
};

export default validate;
