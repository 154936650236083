import { message, Modal } from 'antd';
import axios from 'axios';
import Swal from 'sweetalert2';
import { customSwal } from '../theme';

import {
  DELETE_SESSION_SUCCESS,
  EDIT_SESSION_SUCCESS,
  FETCH_ALL_SESSIONS,
  FETCH_PARTICIPANT_SESSIONS_BY_EMAIL_SUCCESS,
  FETCH_SESSION_DETAILS,
  FETCH_SESSION_FAIL,
  FETCH_TRAINERS_SESSIONS,
  LOADING_FALSE,
  LOADING_TRUE
} from '../constants/actionTypes';

import { MY_SESSIONS_URL } from '../constants/navigationRoutes';

export const fetchTrainerSessions = ({
  id,
  history,
  page,
  type,
  programme
}) => async dispatch => {
  axios
    .get(`/api/users/trainer-sessions/${id}`, {
      params: { page, type, programme }
    })
    .then(res =>
      dispatch({
        type: FETCH_TRAINERS_SESSIONS,
        payload: { data: res.data, type }
      })
    )
    .catch(error => {
      history.push('/404err');
    })
    .finally(() => {
      dispatch({ type: LOADING_FALSE, payload: 'fetchSessions' });
    });
};

export const fetchTpOrgAdminSessions = ({ page, type }) => async dispatch => {
  axios
    .get(`/api/users/sessions/tp-admin-sessions`, {
      params: { page, type }
    })
    .then(res =>
      dispatch({
        type: FETCH_ALL_SESSIONS,
        payload: { data: res.data, type }
      })
    )
    .catch(error => {
      dispatch({
        type: FETCH_SESSION_FAIL,
        payload: error.response.data
      });
    })
    .finally(() => {
      dispatch({ type: LOADING_FALSE, payload: 'fetchSessions' });
    });
};

export const fetchEmployerAdminSessions = ({
  page,
  type
}) => async dispatch => {
  axios
    .get(`/api/users/sessions/employer-admin-sessions`, {
      params: { page, type }
    })
    .then(res =>
      dispatch({
        type: FETCH_ALL_SESSIONS,
        payload: { data: res.data, type }
      })
    )
    .catch(error => {
      dispatch({
        type: FETCH_SESSION_FAIL,
        payload: error.response.data
      });
    })
    .finally(() => {
      dispatch({ type: LOADING_FALSE, payload: 'fetchSessions' });
    });
};

export const fetchALLSessions = ({
  history,
  page,
  type,
  programmeManager,
  programme
}) => async dispatch => {
  dispatch({ type: LOADING_TRUE, payload: 'fetchSessions' });
  axios
    .get(`/api/users/sessions`, {
      params: { page, type, programmeManager, programme }
    })
    .then(res =>
      dispatch({
        type: FETCH_ALL_SESSIONS,
        payload: { data: res.data, type }
      })
    )
    .catch(() => history.push('/404err'))
    .finally(() => {
      dispatch({ type: LOADING_FALSE, payload: 'fetchSessions' });
    });
};

export const fetchSessionDetails = (
  id,
  history,
  populated = false
) => async dispatch => {
  dispatch({ type: LOADING_TRUE, payload: 'sessionDetails' });

  axios
    .get(`/api/session-details/${id}`, {
      params: {
        populated
      }
    })
    .then(res =>
      dispatch({
        type: FETCH_SESSION_DETAILS,
        payload: res.data
      })
    )
    .catch(() => history.push('/404err'))
    .then(() => {
      dispatch({ type: LOADING_FALSE, payload: 'sessionDetails' });
    });
};

export const deleteSessionAction = (id, history) => async dispatch => {
  axios
    .delete(`/api/session-delete/${id}`)
    .then(res =>
      dispatch({
        type: DELETE_SESSION_SUCCESS,
        payload: res.data
      })
    )
    .then(() => {
      Modal.success({
        title: 'Success',
        content: 'Session has been successfully deleted',
        onOk: () => history.push(MY_SESSIONS_URL),
        centered: true,
        icon: null
      });
    })
    .catch(() => history.push('/404err'));
};

export const sessionUpdateAction = (
  sessionData,
  id,
  history
) => async dispatch => {
  // const body = JSON.stringify(sessionData);

  dispatch({
    type: LOADING_TRUE,
    payload: 'sessionEditLoading'
  });

  axios
    .patch(`/api/session-edit/${id}`, sessionData)
    .then(res => {
      Swal.fire({
        title: 'Success',
        text: 'Session has been successfully edited',
        type: 'success',
        confirmButtonText: 'Ok',
        ...customSwal
      });
      history.push(`/session-details/${id}`);

      dispatch({
        type: EDIT_SESSION_SUCCESS,
        payload: res.data
      });

      dispatch({
        type: LOADING_FALSE,
        payload: 'sessionEditLoading'
      });
    })
    .catch(() => {
      dispatch({
        type: LOADING_FALSE,
        payload: 'sessionEditLoading'
      });
      history.push('/404err');
    });
};

export const fetchParticipantSessions = ({
  populated = false,
  includeDidNotShowUp = false
} = {}) => async dispatch => {
  try {
    dispatch({ type: LOADING_TRUE, payload: 'fetchParticipantSessions' });

    let res;
    res = await axios.get(`/api/participant/progress`, {
      params: {
        populated,
        includeDidNotShowUp
      }
    });

    dispatch({ type: LOADING_FALSE, payload: 'fetchParticipantSessions' });

    return dispatch({
      type: FETCH_PARTICIPANT_SESSIONS_BY_EMAIL_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({ type: LOADING_FALSE, payload: 'fetchParticipantSessions' });
    return message.error('Error! Something went wrong');
  }
};

export const fetchPublicSessions = ({
  upcoming,
  onlyEmployerOrgEvents,
  employerOrgId
}) => async dispatch => {
  dispatch({ type: LOADING_TRUE, payload: 'fetchSessions' });
  axios
    .get(`/api/sessions`, {
      params: { upcoming, onlyEmployerOrgEvents, employerOrgId }
    })
    .then(res => {
      return dispatch({
        type: FETCH_ALL_SESSIONS,
        payload: {
          data: {
            sessions: res.data
          }
        }
      });
    })
    .catch(error => {
      dispatch({
        type: FETCH_SESSION_FAIL,
        payload: error.response.data
      });
    })
    .finally(() => {
      dispatch({ type: LOADING_FALSE, payload: 'fetchSessions' });
    });
};
