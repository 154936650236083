import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { fetchProgrammes } from '../../../actions/programmesActions';
import {
  deleteUser,
  deleteUserInvitationDetailsByToken,
  fetchUser,
  requestUserPasswordReset,
  sendUserInvitation,
  updateUser,
  updateUserInvitation
} from '../../../actions/users';
import { fetchEmployerOrganisations } from '../../../actions/employerOrganisationActions';
import { fetchTPOrganisations } from '../../../actions/tpOrganisationActions';
import { MANAGE_USERS } from '../../../constants/navigationRoutes';
import USER_TYPES, { USER_TYPE_LABELS } from '../../../constants/userTypes';
import { checkArraysEqual } from '../../../helpers';
import useFormData from '../../../hooks/useFormData';
import useValidateFormData from '../../../hooks/useValidateFormData';
import { customSwal } from '../../../theme';
import { editUserValidation as validate } from '../../../validation/schemas';
import Layout from '../../Layouts';
import Button from '../../common/Button';
import CopyLink from '../../common/CopyLink';
import { Col, Row } from '../../common/Grid';
import * as I from '../../common/Inputs';
import Spin from '../../common/Spin';
import * as T from '../../common/Typography';
import Modal from '../../common/modal';
import * as S from './style';
import { userRoles } from '../../../constants';

const initialFormState = {
  name: '',
  email: '',
  roles: [],
  managedProgrammes: [],
  employerOrganisationId: '',
  employerOrganisationName: '',
  tpOrganisationId: '',
  tpOrganisationName: '',
  tpOrganisationProgrammes: []
};

const validateOrgNameIsDuplicate = (orgName, employerOrganisations) => {
  return employerOrganisations.some(
    empOrg => empOrg.name.toLowerCase() === orgName.toLowerCase()
  );
};

const UserAddEdit = ({
  fetchProgrammesLoading,
  programmes,
  fetchUserLoading,
  user,
  error,
  authUser,
  fetchProgrammesAction,
  fetchUserAction,
  updateUserAction,
  updateUserLoading,
  deleteUserAction,
  deleteUserLoading,
  requestUserPasswordResetAction,
  sendUserInvitationAction,
  sendUserInvitationLoading,
  userInvitation,
  deleteUserInvitationLoading,
  deleteUserInvitationDetailsByTokenAction,
  updateUserInvitationAction,
  updateUserInvitationLoading,
  employerOrganisations,
  fetchEmployerOrganisationsAction,
  tpOrganisations,
  fetchTPOrganisationsAction
}) => {
  const location = useLocation();
  const history = useHistory();

  const isEdit = useMemo(() => location.pathname.includes('/edit'), [location]);
  const isInvitedUser = useMemo(() => user?.invitationDetails, [
    user?.invitationDetails
  ]);

  const isUserUpgrade = user?.userUpgrade;

  const { credId } = useParams();

  const { formState, setFormData } = useFormData({ initialFormState });

  const submitAttempt = useRef(false);
  const upgradeModalDismissed = useRef(true);

  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen
  ] = useState(false);

  const [isDeleteSuccessModalOpen, setIsDeleteSuccessModalOpen] = useState(
    false
  );

  const [isUpdateSuccessModalOpen, setIsUpdateSuccessModalOpen] = useState(
    false
  );

  const [
    isUpdateAccessConfirmationModalOpen,
    setIsUpdateAccessConfirmationModalOpen
  ] = useState(false);

  const [
    isPasswordResetLinkModalOpen,
    setIsPasswordResetLinkModalOpen
  ] = useState(false);

  const [
    isInvitationSuccessModalOpen,
    setIsInvitationSuccessModalOpen
  ] = useState(false);

  const [
    isUserUpgradeInvitationSentModalOpen,
    setIsUserUpgradeInvitationSentModalOpen
  ] = useState(false);

  const {
    validateForm,
    validationErrors,
    finalSubmissionData
  } = useValidateFormData({
    formState,
    validateFn: validate,
    submitAttempt
  });

  const handleUpdateUser = useCallback(async () => {
    submitAttempt.current = true;
    const isValid = validateForm();
    if (isValid) {
      upgradeModalDismissed.current = false;
      const { current: finalFormState } = finalSubmissionData;

      await updateUserAction(credId, finalFormState);

      if (!error) {
        setIsUpdateSuccessModalOpen(true);
      }
    }
  }, [validateForm, finalSubmissionData, updateUserAction, credId, error]);

  const handleAddUser = useCallback(async () => {
    submitAttempt.current = true;
    const isValid = validateForm();
    if (isValid) {
      const { current: finalFormState } = finalSubmissionData;

      await sendUserInvitationAction({
        fullName: finalFormState.name,
        email: finalFormState.email,
        roles: finalFormState.roles,
        programmes: finalFormState.managedProgrammes,
        employerOrganisationId: finalFormState.employerOrganisationId,
        employerOrganisationName: finalFormState.employerOrganisationName,
        tpOrganisationId: finalFormState.tpOrganisationId,
        tpOrganisationName: finalFormState.tpOrganisationName,
        tpOrganisationProgrammes: finalFormState.tpOrganisationProgrammes
      });

      if (!error) {
        setIsInvitationSuccessModalOpen(true);
      }
    }
  }, [validateForm, finalSubmissionData, sendUserInvitationAction, error]);

  const handleUpdateUserInvitation = useCallback(async () => {
    submitAttempt.current = true;
    const isValid = validateForm();
    if (isValid) {
      const { current: finalFormState } = finalSubmissionData;

      await updateUserInvitationAction(user?.invitationDetails?.token, {
        fullName: finalFormState.name,
        email: finalFormState.email,
        roles: finalFormState.roles,
        programmes: finalFormState.managedProgrammes,
        employerOrganisationId: finalFormState.employerOrganisationId,
        employerOrganisationName: finalFormState.employerOrganisationName,
        tpOrganisationId: finalFormState.tpOrganisationId,
        tpOrganisationName: finalFormState.tpOrganisationName,
        tpOrganisationProgrammes: finalFormState.tpOrganisationProgrammes
      });

      if (!error) {
        setIsUpdateSuccessModalOpen(true);
        fetchUserAction(credId);
      }
    }
  }, [
    fetchUserAction,
    finalSubmissionData,
    updateUserInvitationAction,
    user?.invitationDetails?.token,
    credId,
    validateForm,
    error
  ]);

  const isOrgNameDuplicated =
    error?.response?.data?.error?.includes('E11000 duplicate key error') ||
    validateOrgNameIsDuplicate(
      formState.employerOrganisationName,
      employerOrganisations
    );

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (isOrgNameDuplicated) {
        return;
      }

      if (!validateForm()) {
        return;
      }
      if (isEdit) {
        if (!checkArraysEqual(user?.roles, formState?.roles)) {
          setIsUpdateAccessConfirmationModalOpen(true);
        } else if (isInvitedUser) {
          handleUpdateUserInvitation();
        } else {
          handleUpdateUser();
        }
      } else {
        handleAddUser();
      }
    },
    [
      isEdit,
      user?.roles,
      formState?.roles,
      isInvitedUser,
      handleUpdateUserInvitation,
      handleUpdateUser,
      handleAddUser,
      validateForm,
      isOrgNameDuplicated
    ]
  );

  const handleDeleteUser = useCallback(async () => {
    if (!isInvitedUser) {
      await deleteUserAction(credId);
      setIsDeleteSuccessModalOpen(true);
    } else {
      await deleteUserInvitationDetailsByTokenAction(
        user?.invitationDetails?.token
      );
      setIsDeleteSuccessModalOpen(true);
    }
  }, [
    deleteUserAction,
    deleteUserInvitationDetailsByTokenAction,
    isInvitedUser,
    user?.invitationDetails?.token,
    credId
  ]);

  const handleResetPasswordRequest = useCallback(async () => {
    await requestUserPasswordResetAction(user?.email, null, true);
    setIsPasswordResetLinkModalOpen(true);
  }, [requestUserPasswordResetAction, user?.email]);

  const isDeleted = useMemo(() => user?.isDeleted, [user]);

  useEffect(() => {
    if (isEdit) {
      fetchUserAction(credId);
    }
  }, [isEdit, credId, fetchUserAction]);

  useEffect(() => {
    fetchProgrammesAction({ forAdmin: true });
  }, [fetchProgrammesAction]);

  useEffect(() => {
    if (error && isEdit) {
      setFormData({
        ...formState,
        ...user
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isEdit, error]);

  useEffect(() => {
    if (isEdit && !!user) {
      setFormData({
        ...user,
        managedProgrammes: [
          ...new Set(
            user?.managedProgrammes?.map(
              programme => programme._id?.toString() || programme
            )
          )
        ],
        employerOrganisationId: user?.employerOrganisation,
        tpOrganisationId: user?.tpOrganisation
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (
      !(
        formState.roles.includes(USER_TYPES.programmeManager) ||
        formState.roles.includes(USER_TYPES.trainer)
      ) &&
      !!formState.managedProgrammes?.length
    ) {
      setFormData({
        ...formState,
        managedProgrammes: []
      });
    }
    if (
      tpOrganisations.length > 0 &&
      formState.roles.includes(USER_TYPES.tpOrgAdmin) &&
      formState.tpOrganisationId
    ) {
      const selectedTrainingProvider = tpOrganisations?.find(
        tpOrganisation => formState.tpOrganisationId === tpOrganisation._id
      );
      if (selectedTrainingProvider) {
        setFormData({
          ...formState,
          managedProgrammes: selectedTrainingProvider.programmes
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.roles, formState.tpOrganisationId, tpOrganisations]);

  useEffect(() => {
    fetchEmployerOrganisationsAction();
  }, [fetchEmployerOrganisationsAction]);

  useEffect(() => {
    fetchTPOrganisationsAction();
  }, [fetchTPOrganisationsAction]);

  useEffect(() => {
    if (
      isUserUpgrade &&
      !isUserUpgradeInvitationSentModalOpen &&
      !upgradeModalDismissed.current
    ) {
      setIsUserUpgradeInvitationSentModalOpen(true);
      upgradeModalDismissed.current = true;
    }
  }, [isUserUpgrade, isUserUpgradeInvitationSentModalOpen]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tpOrganisationId = queryParams.get('tpOrg');

    if (tpOrganisationId) {
      setFormData({
        ...formState,
        tpOrganisationId: tpOrganisationId,
        roles: [...formState.roles, userRoles.tpOrgAdmin]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  if ((isEdit && !user) || fetchUserLoading || fetchProgrammesLoading) {
    return <Spin />;
  }

  const sortedProgrammes = programmes?.sort((a, b) =>
    a.titleShort.localeCompare(b.titleShort)
  );

  const handleAddNewEmployerOrganisation = e => {
    setFormData({ employerOrganisationId: 'Add New Organisation' });
  };

  const handleAddNewTPOrganisation = e => {
    setFormData({ tpOrganisationId: 'Add New Training Provider' });
  };

  return (
    <Layout>
      <Row>
        <Col w={[4, 6, 6]}>
          <T.H2W>
            {isEdit ? user?.name : 'Add User'} {isDeleted && ' (Deleted User)'}
          </T.H2W>
        </Col>
        <Col w={[4, 6, 6]} jc="flex-end">
          {!isDeleted && isEdit && user?.userId !== authUser?.credentialId && (
            <Button
              label="Delete user"
              width="200px"
              height="auto"
              type="tertiary"
              loading={deleteUserLoading || deleteUserInvitationLoading}
              disabled={deleteUserLoading}
              onClick={() => {
                setIsDeleteConfirmationModalOpen(true);
              }}
            />
          )}
        </Col>
        <S.Divider full />
        {!isEdit && (
          <T.P mb={6} small>
            Here you can set up trainers, programme managers and other admin
            users.
          </T.P>
        )}
      </Row>
      {isEdit && (
        <>
          <Row>
            <Col w={[4, 8, 8]}>
              <S.UserCard>
                <Row mb={6}>
                  <Col w={[4, 4, 4]}>
                    <T.P weight={600}>Email</T.P>
                  </Col>
                  <Col w={[4, 8, 8]}>
                    <T.P>{!isDeleted ? user?.email : 'N/A'}</T.P>
                  </Col>
                </Row>
                <Row mb={6}>
                  <Col w={[4, 4, 4]}>
                    <T.P weight={600}>Password</T.P>
                  </Col>
                  <Col w={[4, 8, 8]}>
                    {!isDeleted && !isInvitedUser ? (
                      <>
                        {' '}
                        <T.P weight={600}>*** *** ***</T.P>
                        <T.P
                          weight={600}
                          underline
                          ml={5}
                          onClick={handleResetPasswordRequest}
                          style={{
                            cursor: 'pointer'
                          }}
                        >
                          Reset Password
                        </T.P>
                      </>
                    ) : (
                      'N/A'
                    )}
                  </Col>
                </Row>
                <Row mb={6}>
                  <Col w={[4, 4, 4]}>
                    <T.P weight={600}>Organisation</T.P>
                  </Col>
                  <Col w={[4, 8, 8]}>
                    <T.P>
                      {employerOrganisations?.find(
                        empOrg => empOrg._id === user?.employerOrganisation
                      )?.name || 'N/A'}
                    </T.P>
                  </Col>
                </Row>
                <Row mb={6}>
                  <Col w={[4, 4, 4]}>
                    <T.P weight={600}>Local authorities</T.P>
                  </Col>
                  <Col w={[4, 8, 8]}>
                    <T.P>
                      {!!user?.localAuthorities?.length
                        ? user?.localAuthorities.join(' & ')
                        : 'N/A'}
                    </T.P>
                  </Col>
                </Row>
                <Row mb={6}>
                  <Col w={[4, 4, 4]}>
                    <T.P weight={600}>Job role</T.P>
                  </Col>
                  <Col w={[4, 8, 8]}>
                    <T.P>{user?.jobRole || 'N/A'}</T.P>
                  </Col>
                </Row>
                <Row mb={6}>
                  <Col w={[4, 4, 4]}>
                    <T.P weight={600}>Roles</T.P>
                  </Col>
                  <Col w={[4, 8, 8]}>
                    <T.P>
                      {user?.roles
                        ?.map(role => USER_TYPE_LABELS[role])
                        ?.join(' & ')}
                    </T.P>
                  </Col>
                </Row>

                {user?.tpOrganisationId && (
                  <Row mb={6}>
                    <Col w={[4, 4, 4]}>
                      <T.P weight={600}>{'Training Provider'}</T.P>
                    </Col>
                    <Col w={[4, 8, 8]}>
                      <T.P>
                        {
                          tpOrganisations?.find(
                            tpOrg => tpOrg._id === user?.tpOrganisationId
                          )?.name
                        }
                      </T.P>
                    </Col>
                  </Row>
                )}
              </S.UserCard>
            </Col>
            {isInvitedUser && (
              <Col w={[4, 4, 4]}>
                <S.AwaitingSignupCard>
                  <T.H4 mb={2}>Awaiting sign up</T.H4>
                  <T.P mb={2}>
                    This user has not yet created their account. Please copy and
                    send them their unique invite link below.
                  </T.P>
                  <CopyLink big link={user?.invitationDetails?.link} />
                </S.AwaitingSignupCard>
              </Col>
            )}
          </Row>
          <S.Divider full />
        </>
      )}
      <Row>
        <Col w={[4, 6, 6]}>
          <S.Form>
            {!isEdit && (
              <I.BasicInput
                label="Name"
                required
                placeholder="Type here..."
                mb="5"
                value={formState.name}
                handleChange={name => setFormData({ name })}
                error={validationErrors.name}
              />
            )}
            <I.BasicInput
              label="Email"
              required
              placeholder="Type here..."
              mb="5"
              value={!isDeleted ? formState.email : ''}
              handleChange={email => setFormData({ email })}
              disabled={isDeleted}
              error={validationErrors.email}
            />
            <I.Dropdown
              m={{
                mb: '5'
              }}
              style={{
                textTransform: 'capitalize'
              }}
              label="Role(s)"
              required
              addNew={false}
              search={true}
              options={Object.entries(USER_TYPE_LABELS)
                .filter(([key]) => {
                  const isAdmin = authUser?.role === USER_TYPES.admin;

                  return (
                    key !== USER_TYPES.participant &&
                    (isAdmin ||
                      ![
                        USER_TYPES.TPOrgAdmin,
                        USER_TYPES.EmployerOrgAdmin
                      ].includes(key))
                  );
                })
                .map(([key, label]) => ({
                  label: USER_TYPE_LABELS[key],
                  value: key
                }))
                .sort((a, b) => {
                  if (a.label < b.label) return -1;
                  if (a.label > b.label) return 1;
                  return 0;
                })}
              multi
              selected={formState.roles}
              handleChange={roles => {
                if (
                  formState.roles.includes(USER_TYPES.participant) &&
                  !roles.includes(USER_TYPES.participant)
                ) {
                  return Swal.fire({
                    title: 'Error',
                    text: 'Participant role can not be removed!',
                    confirmButtonText: 'OK',
                    ...customSwal
                  });
                }

                if (
                  formState.roles.length === 1 &&
                  formState.roles[0] === USER_TYPES.participant &&
                  roles.length === 2 // Only on first role change
                ) {
                  return Swal.fire({
                    title: 'Warning',
                    text:
                      'This action will not have effect until the participant follows the link in the invitation email.',
                    confirmButtonText: 'OK',
                    showDenyButton: true,
                    denyButtonText: 'Cancel',
                    preConfirm: () => {
                      setFormData({ roles });
                    },
                    ...customSwal
                  });
                }

                const updatedFormData = {};
                if (
                  formState.roles.includes(USER_TYPES.tpOrgAdmin) &&
                  !roles.includes(USER_TYPES.tpOrgAdmin)
                ) {
                  updatedFormData.tpOrganisationId = undefined;
                }
                if (
                  formState.roles.includes(USER_TYPES.employerOrgAdmin) &&
                  !roles.includes(USER_TYPES.employerOrgAdmin)
                ) {
                  updatedFormData.employerOrganisationId = undefined;
                }
                updatedFormData.roles = roles;

                setFormData(updatedFormData);
              }}
              disabled={isDeleted}
              error={validationErrors.roles}
            />

            {formState.roles.includes(USER_TYPES.employerOrgAdmin) && (
              <I.Dropdown
                m={{
                  mb: '5'
                }}
                label="Organisation"
                required
                addNew={false}
                search={true}
                options={employerOrganisations?.map(employerOrganisation => ({
                  label: employerOrganisation.name,
                  value: employerOrganisation._id.toString()
                }))}
                multi={false}
                selected={formState.employerOrganisationId}
                handleChange={employerOrganisationId =>
                  setFormData({ employerOrganisationId })
                }
                dropdownRender={menu => (
                  <>
                    <div
                      style={{
                        padding: '8px 12px',
                        cursor: 'pointer'
                      }}
                      onMouseDown={handleAddNewEmployerOrganisation}
                    >
                      + Add New Organisation
                    </div>
                    {menu}
                  </>
                )}
                disabled={isDeleted}
                error={validationErrors.employerOrganisationId}
              />
            )}

            {formState.roles.includes(USER_TYPES.employerOrgAdmin) &&
              formState.employerOrganisationId === 'Add New Organisation' && (
                <S.SubFormContainer>
                  <I.BasicInput
                    label="Name of The New Organisation"
                    required
                    placeholder="Type here..."
                    mb="5"
                    value={!isDeleted ? formState.employerOrganisationName : ''}
                    handleChange={employerOrganisationName =>
                      setFormData({ employerOrganisationName })
                    }
                    disabled={isDeleted}
                    error={
                      validationErrors.employerOrganisationName ||
                      (isOrgNameDuplicated &&
                        'Organisation name already exists')
                    }
                  />
                </S.SubFormContainer>
              )}

            {formState.roles.includes(USER_TYPES.tpOrgAdmin) && (
              <I.Dropdown
                m={{
                  mb: '5'
                }}
                label="Training Provider"
                required
                addNew={false}
                search={true}
                options={tpOrganisations?.map(tpOrganisation => ({
                  label: tpOrganisation.name,
                  value: tpOrganisation._id.toString()
                }))}
                multi={false}
                selected={formState.tpOrganisationId}
                handleChange={tpOrganisationId =>
                  setFormData({ tpOrganisationId })
                }
                dropdownRender={menu => (
                  <>
                    <div
                      style={{
                        padding: '8px 12px',
                        cursor: 'pointer'
                      }}
                      onMouseDown={handleAddNewTPOrganisation}
                    >
                      + Add New Training Provider
                    </div>
                    {menu}
                  </>
                )}
                disabled={isDeleted}
                error={validationErrors.tpOrganisationId}
              />
            )}

            {formState.roles.includes(USER_TYPES.tpOrgAdmin) &&
              formState.tpOrganisationId === 'Add New Training Provider' && (
                <S.SubFormContainer>
                  <I.BasicInput
                    label="Organisation Name"
                    required
                    placeholder="Type here..."
                    mb="5"
                    value={!isDeleted ? formState.tpOrganisationName : ''}
                    handleChange={tpOrganisationName =>
                      setFormData({ tpOrganisationName })
                    }
                    disabled={isDeleted}
                    error={validationErrors.tpOrganisationName}
                  />
                  <I.Dropdown
                    m={{
                      mb: '5'
                    }}
                    label="Programmes"
                    required
                    addNew={false}
                    search={true}
                    options={sortedProgrammes?.map(programme => ({
                      label: programme.titleShort,
                      value: programme._id.toString()
                    }))}
                    multi
                    selected={formState.tpOrganisationProgrammes}
                    handleChange={tpOrganisationProgrammes =>
                      setFormData({ tpOrganisationProgrammes })
                    }
                    disabled={isDeleted}
                    error={validationErrors.tpOrganisationProgrammes}
                  />
                </S.SubFormContainer>
              )}

            {(isEdit || !formState.roles.includes(USER_TYPES.tpOrgAdmin)) &&
              (formState.roles.includes(USER_TYPES.programmeManager) ||
                formState.roles.includes(USER_TYPES.trainer)) && (
                <I.Dropdown
                  m={{
                    mb: '5'
                  }}
                  label="Programme(s)"
                  required
                  addNew={false}
                  search={true}
                  options={sortedProgrammes?.map(programme => ({
                    label: programme.titleShort,
                    value: programme._id.toString()
                  }))}
                  multi
                  selected={formState.managedProgrammes}
                  handleChange={managedProgrammes =>
                    setFormData({ managedProgrammes })
                  }
                  disabled={isDeleted}
                  error={validationErrors.managedProgrammes}
                />
              )}
            <Button
              label={isEdit ? 'Update' : 'Create'}
              type="primary"
              onClick={handleSubmit}
              loading={
                updateUserLoading ||
                sendUserInvitationLoading ||
                updateUserInvitationLoading
              }
              disabled={
                updateUserLoading ||
                isDeleted ||
                sendUserInvitationLoading ||
                isOrgNameDuplicated
              }
            />
          </S.Form>
        </Col>
      </Row>
      <Modal
        type="confirm"
        visible={isDeleteConfirmationModalOpen}
        setModalOpen={setIsDeleteConfirmationModalOpen}
        text="You are about to delete this user. All personal identifiable information will be removed and they will no longer be able to log in. This cannot be undone."
        parentFunc={handleDeleteUser}
      />
      <Modal
        type="confirm"
        visible={isUpdateAccessConfirmationModalOpen}
        setModalOpen={setIsUpdateAccessConfirmationModalOpen}
        text="You are about to update this user's access level - this may prevent them from accessing certain information."
        parentFunc={
          !isInvitedUser ? handleUpdateUser : handleUpdateUserInvitation
        }
      />
      <Modal
        type="success"
        visible={isDeleteSuccessModalOpen}
        setModalOpen={setIsDeleteSuccessModalOpen}
        text="This user has been successfully deleted."
        handleBack={
          !isInvitedUser
            ? () => {
                history.push(MANAGE_USERS);
              }
            : () => {}
        }
      />
      {!isUserUpgrade && (
        <Modal
          type={error ? 'error' : 'success'}
          visible={isUpdateSuccessModalOpen}
          setModalOpen={setIsUpdateSuccessModalOpen}
          text={
            error
              ? `Failed to update user: ${error}`
              : 'This user has been updated. Please notify them if required.'
          }
          handleBack={() => {
            window.location.reload();
          }}
        />
      )}
      <Modal
        type="success"
        title="Reset password"
        visible={isPasswordResetLinkModalOpen}
        setModalOpen={setIsPasswordResetLinkModalOpen}
      >
        <CopyLink
          big
          title="Please copy the link below and send to the user. This link will be valid for 24 hours."
          link={authUser?.resetPasswordLink}
        />
      </Modal>
      <Modal
        type="success"
        title="Success"
        visible={isInvitationSuccessModalOpen}
        setModalOpen={setIsInvitationSuccessModalOpen}
        handleBack={() => {
          history.push(MANAGE_USERS);
        }}
        theme="light"
      >
        <CopyLink
          big
          title="New user created. They have been emailed their invite link. You can also copy it below to send it to them manually."
          link={userInvitation?.link}
        />
      </Modal>
      <Modal
        type="success"
        title="Success"
        visible={isUserUpgradeInvitationSentModalOpen}
        setModalOpen={setIsUserUpgradeInvitationSentModalOpen}
      >
        <CopyLink
          big
          title="Participant upgrade invitation sent successfully! They have been emailed their invite link. You can also copy it below to send it to them manually."
          link={user?.invitationLink}
        />
      </Modal>
    </Layout>
  );
};

const mapStateToProps = state => ({
  fetchProgrammesLoading: state.loading.fetchProgrammesLoading,
  programmes: state.programmes.programmes,
  fetchUserLoading: state.loading.fetchUserLoading,
  user: state.users.user,
  error: state.users.error,
  updateUserLoading: state.loading.updateUserLoading,
  deleteUserLoading: state.loading.deleteUserLoading,
  sendUserInvitationLoading: state.loading.sendUserInvitationLoading,
  authUser: state.auth,
  userInvitation: state.users.userInvitation,
  deleteUserInvitationLoading: state.loading.deleteUserInvitationLoading,
  updateUserInvitationLoading: state.loading.updateUserInvitationLoading,
  employerOrganisations: state.employerOrganisations.employerOrganisations,
  tpOrganisations: state.tpOrganisations.tpOrganisations
});

const mapActionsToProps = {
  fetchProgrammesAction: fetchProgrammes,
  fetchUserAction: fetchUser,
  updateUserAction: updateUser,
  deleteUserAction: deleteUser,
  requestUserPasswordResetAction: requestUserPasswordReset,
  sendUserInvitationAction: sendUserInvitation,
  deleteUserInvitationDetailsByTokenAction: deleteUserInvitationDetailsByToken,
  updateUserInvitationAction: updateUserInvitation,
  fetchEmployerOrganisationsAction: fetchEmployerOrganisations,
  fetchTPOrganisationsAction: fetchTPOrganisations
};

export default connect(mapStateToProps, mapActionsToProps)(UserAddEdit);
