import { surveyTypesNew } from '../constants';
import _checkIfSessionIsCompleted from './checkIfSessionIsFinished';
import _getUsedFilters from './getUsedFilters';

import {
  copyTextToClipBoard as copyText,
  splitEmailsList as splitEmails
} from './copyTextToClipBoard';

export * from './emails';
export * from './employerOrganisation';

/**
 *
 * @param {string} surveyType
 * @returns {string}
 */
export const mapSurveyTypeWithBackwardCompatibility = surveyType => {
  switch (surveyType) {
    case 'alcohol-demographics':
    case 'pre-train-the-trainer':
    case 'self-assessment':
    case 'poverty-demographics':
      return surveyTypesNew.PRE;
    case 'post-train-the-trainer':
      return surveyTypesNew.POST;
    default:
      return surveyType;
  }
};

export const isDeprecatedSurveyType = surveyType => {
  return (
    surveyType === 'alcohol-demographics' ||
    surveyType === 'pre-train-the-trainer' ||
    surveyType === 'post-train-the-trainer'
  );
};

export const uppercaseSurvey = surveyType =>
  surveyType
    .split('-')
    .map(item => item[0].toLocaleUpperCase() + item.slice(1))
    .join(' ');

/**
 *  return the pre survey link
 * @param {String} shortId - session shortId
 */
export const getPreSurveyLink = ({ shortId }) => {
  const surveyURL = `${window.location.host}/survey/${shortId}/pre-course/0/:success`;

  let url = `https://${surveyURL}`;

  if (process.env.NODE_ENV === 'development') {
    url = `http://${surveyURL}`;
  }

  return url;
};

/**
 *  return the post survey link
 * @param {String} shortId - session shortId
 */

export const getPostSurveyLink = ({ shortId }) => {
  const surveyURL = `${window.location.host}/survey/${shortId}/post/0/:success`;
  let url = `https://${surveyURL}`;

  if (process.env.NODE_ENV === 'development') {
    url = `http://${surveyURL}`;
  }

  return url;
};

/**
 * return array of survey links post & pre if exists
 * @param {Array} modules - session type ["1","2","3","special-2-days"...]
 * @param {String} shortId - session shortId
 */
export const getAllSurveyLinks = (modules, shortId, course) => {
  return [getPreSurveyLink({ shortId }), getPostSurveyLink({ shortId })];
};

/**
 * create survey link using the survey type and the short id
 * @param {String} surveyType - survey type eg. pre-day-1, post-day-1,....
 * @param {Strin} shortId - the session short id
 */
export const getSurveyLink = (surveyType, shortId) => {
  const surveyURL = `${window.location.host}/survey/${surveyType}&${shortId}/0/:success`;
  let url = `https://${surveyURL}`;

  if (process.env.NODE_ENV === 'development') {
    url = `http://${surveyURL}`;
  }

  return url;
};

/**
 * return array of surveys for the session base on the survey type
 * @param {String} sessionType - session type ["1","2","3","special-2-days"...]
 */
export const getSessionSurveys = sessionType => {
  // return surveysTypes[sessionType];
};

export const validPostcode = postcode => {
  postcode.replace(/\s/g, '');
  const regex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
  return regex.test(postcode);
};

export const handleEnterKey = (event, cb) => {
  event.preventDefault();
  return cb(event);
};

export const getUsedFilters = _getUsedFilters;
export const copyTextToClipBoard = copyText;
export const splitEmailsList = splitEmails;
export const checkIfSessionIsFinished = _checkIfSessionIsCompleted;

export const convertAddressObjToString = address => {
  let fullAddress;
  if (address) {
    const { postcode, town, addressLine1, addressLine2 } = address;
    if (postcode || addressLine1 || addressLine2 || town) {
      fullAddress = [addressLine1, addressLine2, town, postcode]
        .filter(item => !!item)
        .join(', ');
    }
  }
  return fullAddress;
};

export const concatModules = ({ modules = [] }) => {
  if (!modules.length) return 'N/A';

  return modules.map(module => module.title).join(' & ');
};

export const addStatusToSessions = sessions => {
  const sessionsWithStatus = sessions?.map(session => {
    const hasFinished = checkIfSessionIsFinished(session);

    const module = session.modules?.[0];

    const completedPostSurvey = session?.completedSurvey?.find(
      survey => survey.surveyType === surveyTypesNew.POST
    );
    const completedAt = session.completedAt;

    const showInCompleted = module.isLive
      ? !!completedPostSurvey || hasFinished
      : !!completedAt || !!completedPostSurvey;

    const completedAtDate = module.isLive
      ? session.date
      : completedAt || completedPostSurvey?.createdAt;

    return {
      ...session,
      showInCompleted,
      completedAtDate
    };
  });

  return sessionsWithStatus;
};

export const checkArraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  return (
    arr1.every(value => arr2.includes(value)) &&
    arr2.every(value => arr1.includes(value))
  );
};
