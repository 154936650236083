import _userRoles from './userTypes';
import _organisations from './organisations';
import _errMsgs from './errors';
import * as _navRoutes from './navigationRoutes';

export {
  tpOrgTypes,
  employerOrgTypes,
  organisationSizes,
  organisationLocations,
  otherLocationWording
} from './dataTypes';
export const navRoutes = _navRoutes;
export const errMsgs = _errMsgs;
export const userRoles = _userRoles;
export const organisations = _organisations.map(org => ({
  value: org,
  label: org
}));

export const northEastAndNorthCumbriaICBOrganisations = [
  'Contracting and procurement',
  'Corporate governance, communications, and involvement',
  'Digital and infrastructure',
  'Finance',
  'Local delivery',
  'Medical',
  'Nursing, AHP, and people and OD',
  'Strategy and transformation'
];

export const readableSurveysNamePairs = {
  'pre-course': 'Pre-course',
  post: 'Post',
  '3-months-follow-up': '3 months follow up',
  '6-months-follow-up': '6 months follow up'
};

export const surveysHaveBehavQuestions = [
  'pre-course',
  '3-months-follow-up',
  '6-months-follow-up'
];

/**
 * an object with { "session-type" : "Session Name"}
 */
export const readableSessionNamePairs = {
  '1': 'Session 1',
  '2': 'Session 2',
  '3': 'Session 3',
  'special-2-days': '2-day Intensive',
  'train-trainers-s1': 'Train Trainers Session 1',
  'train-trainers-s2': 'Train Trainers Session 2',
  'train-trainers-event': 'Train Trainers Day Event'
};

export const localAuthoritiesBasic = [
  'Darlington',
  'Durham',
  'Gateshead',
  'Hartlepool',
  'Middlesbrough',
  'Newcastle',
  'North Cumbria',
  'North Tyneside',
  'Northumberland',
  'Redcar and Cleveland',
  'South Tyneside',
  'Sunderland',
  'Stockton-on-Tees'
];

export const localAuthoritiesWithOtherOption = [
  'Darlington',
  'Durham',
  'Gateshead',
  'Hartlepool',
  'Middlesbrough',
  'Newcastle',
  'North Cumbria',
  'North Tyneside',
  'Northumberland',
  'Redcar and Cleveland',
  'South Tyneside',
  'Sunderland',
  'Stockton-on-Tees',
  'Other, please specify'
];

export const courses = {
  C5: 'C5',
  MECC: 'MECC',
  ALCOHOL_STUDIES: 'Alcohol Studies',
  POVERTY_PROOFING: 'POVERTY_PROOFING',
  TOBACCO_DEPENDENCY: 'TOBACCO_DEPENDENCY',
  BOOST: 'BOOST',
  MECC_TRAIN_THE_TRAINER: 'MECC_TRAIN_THE_TRAINER',
  CONNECT_5_TRAIN_THE_TRAINER: 'CONNECT_5_TRAIN_THE_TRAINER',
  HEALTHY_WEIGHT_OBESITY: 'HEALTHY_WEIGHT_OBESITY',
  HEALTHCARE_INEQUALITIES: 'HEALTHCARE_INEQUALITIES',
  HEALTH_LITERACY: 'HEALTH_LITERACY',
  DISABILITY_AND_AUTISM: 'DISABILITY_AND_AUTISM'
};

export const surveyTypesNew = {
  PRE: 'pre-course',
  POST: 'post',
  FOLLOW_UP_3: '3-months-follow-up',
  FOLLOW_UP_6: '6-months-follow-up',
  ONBOARDING: 'onboarding'
};

export const questionCategories = {
  ACCESS_NEEDS: 'access-needs',
  DEMOGRAPHICS: 'demographics',
  PROGRAMME_SPECIFIC: 'programme-specific',
  GENERAL_POST_MODULE: 'general-post-module',
  GENERAL_TRAINER_FEEDBACK: 'general-trainer-feedback',
  GENERAL_FOLLOWUP: 'general-followup'
};

export const externalLinks = {
  NHS_FUTURE_LEARNS:
    'https://future.nhs.uk/system/login?nextURL=%2Fconnect%2Eti%2FNEConnect5MentalWellbeing%2Fgrouphome',
  EXPLAIN_TOOL_VIDEO:
    'https://yallacooperative.notion.site/North-East-and-North-Cumbria-ICS-Training-Platform-Tutorials-f87f448dc6144b90a581d3d000b36860',
  MECC_CONTACT_EMAIL: 'MECCRegional@northumbria-healthcare.nhs.uk',
  PRIVACY_POLICY:
    'https://boost.org.uk/website-terms-and-conditions/privacy-policy/',
  TERMS_OF_USE: 'https://boost.org.uk/website-terms-and-conditions/',
  HELP_EMAIL: 'MECCRegional@northumbria-healthcare.nhs.uk'
};

export const moduleDifficultyLevels = [
  'Introductory',
  'Intermediate',
  'Advanced'
];

export const moduleFormatOptions = [
  {
    label: 'Live',
    value: 'true'
  },
  {
    label: 'eLearning',
    value: 'false'
  }
];
