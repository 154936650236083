import {
  FETCH_CONTRACT_SUCCESS,
  FETCH_CONTRACT_FAIL
} from '../constants/actionTypes';

const initialState = {
  contracts: [],
  error: null
};

const contractsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTRACT_SUCCESS:
      return {
        ...state,
        contracts: action.payload,
        error: null
      };

    case FETCH_CONTRACT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export default contractsReducer;
