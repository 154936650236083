import axios from 'axios';

import { returnErrors } from './errorAction';

import {
  FETCH_MODULES,
  FETCH_MODULE,
  LOADING_TRUE,
  CREATE_MODULE_SUCCESS,
  CREATE_MODULE_FAIL,
  LOADING_FALSE,
  FETCH_MODULES_FAIL,
  FETCH_MODULE_FAIL,
  ARCHIVE_MODULES_FAIL,
  COMPLETE_MODULE_FAIL,
  WITHDRAW_FROM_MODULE_FAIL,
  SET_COURSE_CONTEXT,
  CLEAR_MODULE,
  LAUNCH_MODULE_SUCCESS,
  LAUNCH_MODULE_FAIL
} from '../constants/actionTypes';

export const fetchModules = ({
  forPublic,
  liveOnly,
  archived,
  programme,
  populate
}) => async dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'fetchModulesLoading'
  });
  return (
    axios
      .get(`/api/modules`, {
        params: { forPublic, archived, liveOnly, programme, populate }
      })
      .then(res => {
        dispatch({
          type: FETCH_MODULES,
          payload: res.data
        });
        dispatch({
          type: LOADING_FALSE,
          payload: 'fetchModulesLoading'
        });
      })
      // eslint-disable-next-line no-console
      .catch(err => {
        dispatch(
          returnErrors(
            err?.response?.data?.error || err.message,
            err?.response?.status,
            'FETCH_MODULES_FAIL'
          )
        );
        dispatch({
          type: LOADING_FALSE,
          payload: 'fetchModulesLoading'
        });
        dispatch({
          type: FETCH_MODULES_FAIL
        });
      })
  );
};

export const fetchModule = ({ id }) => async dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'fetchModuleLoading'
  });
  return (
    axios
      .get(`/api/modules/${id}`)
      .then(res => {
        dispatch({
          type: FETCH_MODULE,
          payload: res.data
        });
        dispatch({
          type: LOADING_FALSE,
          payload: 'fetchModuleLoading'
        });
        dispatch({
          type: SET_COURSE_CONTEXT,
          payload: res.data.type
        });
      })
      // eslint-disable-next-line no-console
      .catch(err => {
        dispatch(
          returnErrors(
            err?.response?.data?.error || err.message,
            err?.response?.status,
            'FETCH_MODULE_FAIL'
          )
        );
        dispatch({
          type: LOADING_FALSE,
          payload: 'fetchModuleLoading'
        });
        dispatch({
          type: FETCH_MODULE_FAIL
        });
      })
  );
};

export const archiveModule = ({ moduleId, data }) => async dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'archiveModuleLoading'
  });

  return (
    axios
      .patch(`/api/modules/${moduleId}/archive`, data)
      .then(() => {
        dispatch({
          type: LOADING_FALSE,
          payload: 'archiveModuleLoading'
        });
      })
      // eslint-disable-next-line no-console
      .catch(err => {
        dispatch(
          returnErrors(
            err?.response?.data?.error || err.message,
            err?.response?.status,
            ARCHIVE_MODULES_FAIL
          )
        );
        dispatch({
          type: LOADING_FALSE,
          payload: 'archiveModuleLoading'
        });
        dispatch({
          type: ARCHIVE_MODULES_FAIL
        });
      })
  );
};

export const completeModule = ({ moduleId, data }) => async dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'completeModuleLoading'
  });

  return (
    axios
      .patch(`/api/modules/${moduleId}/complete`, data)
      .then(() => {
        dispatch({
          type: LOADING_FALSE,
          payload: 'completeModuleLoading'
        });
      })
      // eslint-disable-next-line no-console
      .catch(err => {
        dispatch(
          returnErrors(
            err?.response?.data?.error || err.message,
            err?.response?.status,
            COMPLETE_MODULE_FAIL
          )
        );
        dispatch({
          type: LOADING_FALSE,
          payload: 'completeModuleLoading'
        });
        dispatch({
          type: COMPLETE_MODULE_FAIL,
          payload: err?.response?.data?.error || err.message
        });
      })
  );
};

export const createModule = formData => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'createModuleLoading'
    });
    await axios.post('/api/modules', formData);
    dispatch({
      type: CREATE_MODULE_SUCCESS
    });
    return {};
  } catch (error) {
    dispatch({
      type: CREATE_MODULE_FAIL,
      payload: error.response.data.error
    });
    return { error };
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'createModuleLoading'
    });
  }
};
export const updateModule = formData => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'createModuleLoading'
    });
    await axios.patch(`/api/modules/${formData._id}`, formData);
    dispatch({
      type: CREATE_MODULE_SUCCESS
    });
    return {};
  } catch (error) {
    dispatch({
      type: CREATE_MODULE_FAIL,
      payload: error.response.data.error
    });
    return { error };
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'createModuleLoading'
    });
  }
};

export const clearModule = () => async dispatch => {
  dispatch({
    type: CLEAR_MODULE,
    payload: {}
  });
};

export const withdrawFromModule = ({ moduleId, data }) => async dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'withdrawFromModuleLoading'
  });
  return axios
    .patch(`/api/modules/${moduleId}/withdraw`, data)
    .then(() => {
      dispatch({
        type: LOADING_FALSE,
        payload: 'withdrawFromModuleLoading'
      });
    })
    .catch(err => {
      dispatch(
        returnErrors(
          err?.response?.data?.error || err.message,
          err?.response?.status,
          WITHDRAW_FROM_MODULE_FAIL
        )
      );
      dispatch({
        type: LOADING_FALSE,
        payload: 'withdrawFromModuleLoading'
      });
      dispatch({
        type: WITHDRAW_FROM_MODULE_FAIL,
        payload: err?.response?.data?.error || err.message
      });
    });
};

export const launchElearningModule = ({ moduleId, data }) => async dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'launchModuleLoading'
  });

  return (
    axios
      .patch(`/api/modules/${moduleId}/launchElearning`, data)
      .then(async res => {
        await dispatch({
          type: LOADING_FALSE,
          payload: 'launchModuleLoading'
        });
        await dispatch({
          type: LAUNCH_MODULE_SUCCESS,
          payload: res.data
        });
        return res.data;
      })
      // eslint-disable-next-line no-console
      .catch(err => {
        dispatch(
          returnErrors(
            err?.response?.data?.error || err.message,
            err?.response?.status,
            LAUNCH_MODULE_FAIL
          )
        );
        dispatch({
          type: LOADING_FALSE,
          payload: 'launchModuleLoading'
        });
        dispatch({
          type: LAUNCH_MODULE_FAIL,
          payload: err?.response?.data?.error || err.message
        });
      })
  );
};
