import {
  FETCH_TP_ORGANISATIONS_SUCCESS,
  FETCH_TP_ORGANISATION_SUCCESS,
  FETCH_TP_ORGANISATIONS_FAIL,
  FETCH_TP_ORGANISATION_FAIL,
  CREATE_TP_ORGANISATION_SUCCESS,
  CREATE_TP_ORGANISATION_FAIL,
  UPDATE_TP_ORGANISATION_SUCCESS,
  UPDATE_TP_ORGANISATION_FAIL,
  DELETE_TP_ORGANISATION_SUCCESS,
  DELETE_TP_ORGANISATION_FAIL
} from '../constants/actionTypes';

const initialState = {
  tpOrganisations: [],
  tpOrganisation: null,
  loading: false,
  error: null
};

const tpOrganisationsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TP_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        tpOrganisations: payload.data,
        totalCount: payload.total,
        loading: false,
        error: null
      };
    case FETCH_TP_ORGANISATION_SUCCESS:
      return {
        ...state,
        tpOrganisation: payload,
        loading: false,
        error: null
      };

    case FETCH_TP_ORGANISATIONS_FAIL:
    case FETCH_TP_ORGANISATION_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };

    case CREATE_TP_ORGANISATION_SUCCESS:
      return {
        ...state,
        tpOrganisations: [...state.tpOrganisations, payload],
        tpOrganisation: payload,
        loading: false,
        error: null
      };

    case CREATE_TP_ORGANISATION_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };

    case UPDATE_TP_ORGANISATION_SUCCESS:
      return {
        ...state,
        tpOrganisations: state.tpOrganisations.map(provider =>
          provider._id === payload._id ? { ...provider, ...payload } : provider
        ),
        tpOrganisation:
          state.tpOrganisation && state.tpOrganisation._id === payload._id
            ? { ...state.tpOrganisation, ...payload }
            : state.tpOrganisation,
        loading: false,
        error: null
      };

    case UPDATE_TP_ORGANISATION_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };

    case DELETE_TP_ORGANISATION_SUCCESS:
      return {
        ...state,
        tpOrganisations: state.tpOrganisations.filter(
          provider => provider._id !== payload
        ),
        loading: false,
        error: null
      };

    case DELETE_TP_ORGANISATION_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };

    default:
      return state;
  }
};

export default tpOrganisationsReducer;
