const { audienceTypes } = require('./sessionData');

const topics = [
  { label: 'Alcohol', value: 'Alcohol' },
  { label: 'Anchor', value: 'Anchor' },
  { label: 'Cardiovascular Disease', value: 'Cardiovascular Disease' },
  { label: 'Co-Production', value: 'Co-Production' },
  { label: 'Coaching', value: 'Coaching' },
  { label: 'CORE20PLUS5', value: 'CORE20PLUS5' },
  { label: 'Digital Inclusion', value: 'Digital Inclusion' },
  {
    label: 'Equality, Diversity and Inclusion',
    value: 'Equality, Diversity and Inclusion'
  },
  { label: 'Health Literacy', value: 'Health Literacy' },
  { label: 'Healthcare Inequalities', value: 'Healthcare Inequalities' },
  { label: 'Healthy Weight', value: 'Healthy Weight' },
  { label: 'Improvement', value: 'Improvement' },
  { label: 'Inclusion Health', value: 'Inclusion Health' },
  { label: 'Innovation', value: 'Innovation' },
  { label: 'Leadership', value: 'Leadership' },
  { label: 'Learning Culture', value: 'Learning Culture' },
  { label: 'Maternity', value: 'Maternity' },
  { label: 'MECC', value: 'MECC' },
  { label: 'Mental Health', value: 'Mental Health' },
  { label: 'Poverty', value: 'Poverty' },
  { label: 'Pregnancy', value: 'Pregnancy' },
  { label: 'Safety', value: 'Safety' },
  { label: 'System Working', value: 'System Working' },
  { label: 'Team Working', value: 'Team Working' },
  { label: 'Tobacco', value: 'Tobacco' },
  { label: 'Treating Obesity', value: 'Treating Obesity' },
  { label: 'Waiting Well', value: 'Waiting Well' },
  { label: 'Wellbeing', value: 'Wellbeing' },
  { label: 'Quality Improvement', value: 'Quality Improvement' },
  {
    label: 'Learning Disability and Autism',
    value: 'Learning Disability and Autism'
  }
];

const eventLocations = [
  { label: 'County Durham', value: 'County Durham' },
  { label: 'Darlington', value: 'Darlington' },
  { label: 'Gateshead', value: 'Gateshead' },
  { label: 'Hartlepool', value: 'Hartlepool' },
  { label: 'Middlesbrough', value: 'Middlesbrough' },
  { label: 'Newcastle', value: 'Newcastle' },
  { label: 'North & West Cumbria', value: 'North & West Cumbria' },
  { label: 'North Tyneside', value: 'North Tyneside' },
  { label: 'Northumberland', value: 'Northumberland' },
  { label: 'Redcar & Cleveland', value: 'Redcar & Cleveland' },
  { label: 'South Tyneside', value: 'South Tyneside' },
  { label: 'Stockton-on-Tees', value: 'Stockton-on-Tees' },
  { label: 'Sunderland', value: 'Sunderland' },
  { label: 'Other', value: 'Other' }
];

const roles = [
  { label: 'Admin', value: 'admin' },
  { label: 'Programme Manager', value: 'programmeManager' },
  { label: 'Participant', value: 'participant' },
  { label: 'Trainer', value: 'trainer' },
  { label: 'TP Org Admin', value: 'tpOrgAdmin' },
  { label: 'Employer Org Admin', value: 'employerOrgAdmin' }
];

const modulesFilters = [
  {
    name: 'topics',
    label: 'Topics',
    options: topics,
    multi: true
  },
  // TODO update this to look into isLive
  {
    name: 'format',
    label: 'Format',
    options: [
      { label: 'Live', value: 'Live' },
      { label: 'eLearning', value: 'eLearning' }
    ],
    multi: false,
    customMapping: {
      fieldName: 'isLive',
      valueMap: {
        Live: true,
        eLearning: false
      }
    }
  }
];

const programmesFilters = [
  {
    name: 'topics',
    label: 'Topics',
    options: topics,
    multi: true
  },
  {
    name: 'format',
    label: 'Format',
    options: [
      { label: 'Live', value: 'Live' },
      { label: 'eLearning', value: 'eLearning' }
    ],
    multi: false,
    customMapping: {
      fieldName: 'isLive',
      valueMap: {
        Live: true,
        eLearning: false
      }
    }
  },
  {
    name: 'locations',
    label: 'Locations',
    options: eventLocations,
    multi: true
  }
];

const sessionsFilters = [
  {
    name: 'topics',
    label: 'Topics',
    options: topics,
    multi: true
  },
  {
    name: 'audience',
    label: 'Audience',
    options: audienceTypes.map(value => ({
      label: value,
      value
    })),
    multi: true
  },
  {
    name: 'format',
    label: 'Type',
    options: [
      { label: 'Remote / Online', value: 'Remote / Online' },
      { label: 'Face to Face', value: 'Face to Face' }
    ],
    multi: false
  },
  {
    name: 'location',
    label: 'Locations',
    options: eventLocations,
    multi: true
  }
];

const usersFilters = programmes => [
  {
    name: 'programme',
    label: 'Programme',
    options: programmes.map(p => ({ label: p.titleShort, value: p._id })),
    multi: true
  },
  {
    name: 'role',
    label: 'Role',
    options: roles,
    multi: true
  }
];

const tpOrganisationsFilters = programmes => [
  {
    name: 'programmes',
    label: 'Programme',
    options: programmes.map(p => ({ label: p.titleShort, value: p._id })),
    multi: true
  }
];

module.exports = {
  modulesFilters,
  programmesFilters,
  sessionsFilters,
  topics,
  eventLocations,
  usersFilters,
  tpOrganisationsFilters
};
