import { fields, createSchema, validate as _validate } from '..';

const {
  email,
  password,
  name,
  arrayOfString,
  organisationOther,
  requiredText,
  checkbox,
  optionalText,
  passwordConfirmation
} = fields;

const validate = data => {
  const trainerSchema = createSchema({
    email,
    name,
    password: data.allowWeakPassword ? requiredText : password,
    confirmPassword: passwordConfirmation,
    localAuthorities: arrayOfString,
    organisationOther,
    jobRole: requiredText,
    phone: optionalText,
    newsletterSubscription: checkbox
  });

  const upgradeToTrainerSchema = createSchema({
    jobRole: requiredText,
    organisationOther,
    localAuthorities: arrayOfString
  });

  const signUpSchema = createSchema({
    email,
    password: data.allowWeakPassword ? requiredText : password,
    confirmPassword: passwordConfirmation,
    name,
    phone: optionalText,
    newsletterSubscription: checkbox
  });

  if (data.userUpgrade && data.includeTrainer) {
    return _validate(upgradeToTrainerSchema, data);
  } else if (data.userUpgrade) {
    return true;
  } else if (data.includeTrainer) {
    return _validate(trainerSchema, data);
  } else {
    return _validate(signUpSchema, data);
  }
};

export default validate;
