import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '../../common/Button';
import Spin from '../../common/Spin';
import * as T from '../../common/Typography';

import { ButtonsWrapper } from './style';

import {
  fetchModule,
  launchElearningModule
} from '../../../actions/modulesActions';

const ElfhModule = ({
  module,
  launchElearningModule,
  fetchModule,
  launchModuleLoading
}) => {
  const { id } = useParams();

  const inProgress = module.latestMandatoryAttempt?.status === 'in-progress';

  const launchElearning = async shouldRestart => {
    const result = await launchElearningModule({
      moduleId: module._id,
      data: {
        sessionParticipantId: module.participantAttendance._id,
        shouldRestart
      }
    });
    if (result.launchUrl) {
      window.open(result.launchUrl, '_blank');
    }
  };

  useEffect(() => {
    function fetchOnFocus() {
      if (!document.hidden) {
        fetchModule({ id });
      }
    }
    document.addEventListener('visibilitychange', fetchOnFocus);
    return () => document.removeEventListener('visibilitychange', fetchOnFocus);
  }, []);

  return (
    <>
      <T.P mb="3" color="gray">
        This will open the link in a new tab on your browser.
      </T.P>
      <ButtonsWrapper>
        <Button
          label={inProgress ? 'Continue module' : 'Begin module'}
          type="primary"
          width="200px"
          onClick={() => launchElearning(false)}
        />
        {inProgress && (
          <Button
            label="Restart module"
            type="primary"
            width="200px"
            onClick={() => launchElearning(true)}
          />
        )}
        {launchModuleLoading && <Spin />}
      </ButtonsWrapper>
    </>
  );
};

const mapStateToProps = state => ({
  module: state.modules.module,
  launchUrl: state.modules.launchUrl,
  launchModuleLoading: state.loading.launchModuleLoading
});

export default connect(mapStateToProps, {
  launchElearningModule,
  fetchModule
})(ElfhModule);
