import { connect } from 'react-redux';
import { useEffect } from 'react';
import * as T from '../../common/Typography';
import Button from '../../common/Button';
import CopyLinkComponent from '../../common/CopyLink';
import { fetchEmployerOrganisation } from '../../../actions/employerOrganisationActions';
import * as S from './style';
import { ORG_EVENTS_EVENTS_URL } from '../../../constants/navigationRoutes';

const EmployerOrgShareSessions = ({
  authUser,
  fetchEmployerOrganisation,
  fetchEmployerOrganisationLoading,
  fetchedEmployerOrganisation,
  httpError,
  mb
}) => {
  useEffect(() => {
    fetchEmployerOrganisation(authUser.employerOrganisation);
  }, [authUser.employerOrganisation, fetchEmployerOrganisation]);

  if (fetchEmployerOrganisationLoading) {
    return (
      <S.ShareSessionContainer mb={mb}>
        <T.P smallMedium weight="600">
          Loading...
        </T.P>
      </S.ShareSessionContainer>
    );
  }

  if (httpError) {
    return (
      <S.ShareSessionContainer mb={mb}>
        <T.P color="error">
          There was an error fetching your organisation - {httpError}
        </T.P>
      </S.ShareSessionContainer>
    );
  }

  return (
    <S.ShareSessionContainer mb={mb}>
      <S.ShareSessionText maxWidth="430px">
        <T.P smallMedium weight="600" mb="2">
          Share your pre-booked events
        </T.P>
        <T.P color="gray" small>
          Use this page to share sessions you have set up exclusively for the
          employees at your organisation.
        </T.P>
      </S.ShareSessionText>
      <S.ShareSessionText maxWidth="460px">
        <CopyLinkComponent
          small
          link={`${
            process.env.NODE_ENV === 'production' ? 'https://' : 'http://'
          }${window.location.host}${ORG_EVENTS_EVENTS_URL.replace(
            ':slug',
            fetchedEmployerOrganisation?.slug
          )}`}
          previewLink={`${window.location.host}${ORG_EVENTS_EVENTS_URL.replace(
            ':slug',
            fetchedEmployerOrganisation?.slug
          )}`}
        />
        <Button
          mt="5"
          to={ORG_EVENTS_EVENTS_URL.replace(
            ':slug',
            fetchedEmployerOrganisation?.slug
          )}
          type="tertiary"
          label="View Private Page"
        />
      </S.ShareSessionText>
    </S.ShareSessionContainer>
  );
};

const mapStateToProps = state => {
  return {
    authUser: state.auth,
    fetchEmployerOrganisationLoading:
      state.loading.fetchEmployerOrganisationLoading,
    fetchedEmployerOrganisation:
      state.employerOrganisations.employerOrganisation,
    httpError: state.employerOrganisations.error
  };
};

export default connect(mapStateToProps, {
  fetchEmployerOrganisation
})(EmployerOrgShareSessions);
