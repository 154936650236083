import { connect } from 'react-redux';
import Unauthorized from '../ErrorPages/403';
import { userRoles } from '../../../constants';
import TpOrganisation from './TpOrganisation';
import EmployerOrganisation from './EmployerOrganisation';

const CompleteYourAccount = ({ authUser }) => {
  switch (authUser?.role) {
    case userRoles.tpOrgAdmin:
      return <TpOrganisation />;
    case userRoles.employerOrgAdmin:
      return <EmployerOrganisation />;

    default:
      return <Unauthorized />;
  }
};

const mapStateToProps = state => ({
  authUser: state.auth
});

export default connect(mapStateToProps, {})(CompleteYourAccount);
