import React from 'react';
import { DotButton, useDotButton } from './EmblaCarouselDotButton';
import { usePrevNextButtons } from './hooks';
import useEmblaCarousel from 'embla-carousel-react';
import * as T from '../Typography';

import Button from '../Button';
import Icon from '../Icon';

import {
  Carousel,
  CarouselContainer,
  CarouselControls,
  CarouselViewPort,
  DotsContainer,
  Slide,
  SlideContentContainer,
  SlideInfoContainer,
  SlidesContainer,
  StyledSlideHeading
} from './style';

const EmblaCarousel = props => {
  const { slides, options, onSkip } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
    emblaApi
  );

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi);

  const nextSlideOrComplete = () => {
    if (nextBtnDisabled) {
      onSkip();
    } else {
      onNextButtonClick();
    }
  };

  return (
    <CarouselContainer>
      <Carousel>
        <CarouselViewPort ref={emblaRef}>
          <SlidesContainer>
            {slides.map((slide, index) => (
              <Slide key={index}>
                <SlideContentContainer>
                  {slide.slideContent}
                </SlideContentContainer>
              </Slide>
            ))}
          </SlidesContainer>
        </CarouselViewPort>

        <DotsContainer>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              selected={index === selectedIndex}
            />
          ))}
        </DotsContainer>
        <SlideInfoContainer>
          <StyledSlideHeading style={{ fontSize: '118px' }} mb={1}>
            {slides[selectedIndex].title}
          </StyledSlideHeading>
          <T.P color="neutralGray">{slides[selectedIndex].description}</T.P>
        </SlideInfoContainer>
        <CarouselControls>
          <Button
            style={{ maxWidth: 'fit-content', padding: '0 1.25rem' }}
            size="xs"
            type="text"
            label="Skip Tour"
            onClick={onSkip}
          />
          <Button
            style={{ maxWidth: 'fit-content', padding: '0 1.25rem' }}
            disabled={prevBtnDisabled}
            onClick={onPrevButtonClick}
            type="primary"
            label={
              <>
                <Icon fliped icon="arrow" color={'primary'} />
                Previous
              </>
            }
          />
          <Button
            style={{ maxWidth: 'fit-content', padding: '0 1.25rem' }}
            onClick={nextSlideOrComplete}
            type="primary"
            label={
              <>
                Next
                <Icon icon="arrow" color={'primary'} />
              </>
            }
          />
        </CarouselControls>
      </Carousel>
    </CarouselContainer>
  );
};

export default EmblaCarousel;
