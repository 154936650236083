import { Modal, message } from 'antd';
import axios from 'axios';

import * as types from '../constants/actionTypes';
import { checkAuth } from './authAction';
import { returnErrors } from './errorAction';

let cancelTokenSource;

export const fetchUserResults = (id, filters, history) => async dispatch => {
  try {
    dispatch({
      type: types.LOADING_TRUE,
      payload: 'fetchUserResultsLoading'
    });

    if (cancelTokenSource) {
      cancelTokenSource.cancel('Operation canceled due to new request.');
    }

    cancelTokenSource = axios.CancelToken.source();

    const res = await axios.post(
      `/api/users/${id}/results`,
      {
        id,
        filters
      },
      {
        cancelToken: cancelTokenSource.token
      }
    );

    dispatch({
      type: types.LOADING_FALSE,
      payload: 'fetchUserResultsLoading'
    });

    return dispatch({
      type: types.FETCH_USER_RESULTS_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return console.log('Request canceled', err.message);
    }

    if (err.response && err.response.status === 403) {
      return Modal.error({
        title: 'No access permission',
        content:
          "This trainer didn't give an access permission to his/her data",
        onOk: () => history.goBack(),
        centered: true,
        icon: null
      });
    }
    if (err.response && err.response.status === 404) {
      return Modal.error({
        title: 'Trainer not found',
        content: 'trainer data not found',
        onOk: history.goBack(),
        centered: true,
        icon: null
      });
    }
    return history.push('/500err');
  }
};

export const fetchStatsData = userType => async dispatch => {
  try {
    const res = await axios.post('/api/all/dashboard', { userType });
    const { data } = res;

    dispatch({
      type: types.FETCH_STATS,
      payload: data.stats
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const requestUserPasswordReset = (
  email,
  history,
  manualInvite = false
) => async dispatch => {
  dispatch({
    type: types.LOADING_TRUE,
    payload: 'forgetPasswordLoading'
  });

  axios
    .get(`/api/users/forget-password/?email=${email}`, {
      params: {
        manualInvite
      }
    })
    .then(res => {
      dispatch({
        type: types.REQUEST_USER_PASSWORD_RESET_SUCCESS,
        payload: res.data
      });
      dispatch({
        type: types.LOADING_FALSE,
        payload: 'forgetPasswordLoading'
      });
    })
    .then(() => {
      Modal.success({
        title: 'Password reset email sent!',
        content:
          'We just sent a message to the email you provided with a link to reset your password. Please check your inbox and follow the instructions in the email.',
        onOk: history.push('/login'),
        centered: true,
        icon: null
      });
    })
    .catch(() => {
      history?.push('/404err');

      dispatch({
        type: types.LOADING_FALSE,
        payload: 'forgetPasswordLoading'
      });
    });
};

export const resetPassword = (resetPasswordData, history) => async dispatch => {
  dispatch({
    type: types.LOADING_TRUE,
    payload: 'resetPasswordLoading'
  });

  axios
    .post('/api/users/reset-password', resetPasswordData)
    .then(res => {
      dispatch({
        type: types.RESET_PASSWORD_SUCCESS,
        payload: res.data
      });
      dispatch({
        type: types.LOADING_FALSE,
        payload: 'resetPasswordLoading'
      });
    })
    .then(() =>
      Modal.success({
        title: 'Done!',
        content: 'Password reset successfully',
        onOk: history.push('/login'),
        centered: true,
        icon: null
      })
    )
    .catch(err => {
      dispatch(
        returnErrors(
          err.response.data,
          err.response.status,
          'RESET_PASSWORD_FAIL'
        )
      );
      dispatch({
        type: types.LOADING_FALSE,
        payload: 'resetPasswordLoading'
      });

      dispatch({
        type: types.RESET_PASSWORD_FAIL
      });
    });
};

export const updateUserInfo = (data, updateState) => async dispatch => {
  dispatch({
    type: types.LOADING_TRUE,
    payload: 'updateUserLoading'
  });

  axios
    .patch('/api/users', data)
    .then(() => {
      updateState({ visible: false });

      dispatch({
        type: types.LOADING_FALSE,
        payload: 'updateUserLoading'
      });
      dispatch(checkAuth());
    })
    .then(() => message.success('Updated successfully'))
    .catch(err => {
      const error =
        err.response && err.response.data && err.response.data.error;
      message.error(error || 'Something went wrong');

      dispatch({
        type: types.LOADING_FALSE,
        payload: 'updateUserLoading'
      });
    });
};

export const fetchUsers = page => async dispatch => {
  dispatch({
    type: types.LOADING_TRUE,
    payload: 'fetchUsersLoading'
  });

  try {
    const res = await axios.get('/api/users/all', {
      params: {
        page
      }
    });
    dispatch({
      type: types.FETCH_USERS_SUCCESS,
      payload: res.data[0],
      error: null
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_USERS_FAIL,
      payload: {
        data: null,
        error: error?.response?.data?.error || error.message
      }
    });
  } finally {
    dispatch({ type: types.LOADING_FALSE, payload: 'fetchUsersLoading' });
  }
};

export const fetchUser = credId => async dispatch => {
  dispatch({
    type: types.LOADING_TRUE,
    payload: 'fetchUserLoading'
  });

  try {
    const res = await axios.get(`/api/users/${credId}`);
    dispatch({
      type: types.FETCH_USER_SUCCESS,
      payload: res.data,
      error: null
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_USER_SUCCESS,
      payload: {
        data: null,
        error: error?.response?.data?.error || error.message
      }
    });
  } finally {
    dispatch({ type: types.LOADING_FALSE, payload: 'fetchUserLoading' });
  }
};

export const updateUser = (credId, payload) => async dispatch => {
  dispatch({
    type: types.LOADING_TRUE,
    payload: 'updateUserLoading'
  });

  try {
    const res = await axios.put(`/api/users/${credId}`, payload);
    dispatch({
      type: types.UPDATE_USER_SUCCESS,
      payload: res.data,
      error: null
    });
    if (payload.tpOrganisationId || payload.tpOrganisationName) {
      dispatch({
        type: !payload.tpOrganisationId
          ? types.CREATE_TP_ORGANISATION_SUCCESS
          : types.UPDATE_TP_ORGANISATION_SUCCESS,
        payload: {
          _id: payload.tpOrganisationId,
          ...(payload.tpOrganisationName && {
            name: payload.tpOrganisationName
          }),
          programmes: payload.programmes
        }
      });
    }
    if (payload.employerOrganisationId || payload.employerOrganisationName) {
      dispatch({
        type: !payload.employerOrganisationId
          ? types.CREATE_EMPLOYER_ORGANISATION_SUCCESS
          : types.UPDATE_EMPLOYER_ORGANISATION_SUCCESS,
        payload: {
          _id: payload.employerOrganisationId,
          ...(payload.employerOrganisationName && {
            name: payload.employerOrganisationName
          })
        }
      });
    }
  } catch (error) {
    dispatch({
      type: types.UPDATE_USER_FAIL,
      payload: error?.response?.data?.error || error.message
    });
  } finally {
    dispatch({ type: types.LOADING_FALSE, payload: 'updateUserLoading' });
  }
};

export const deleteUser = credId => async dispatch => {
  dispatch({
    type: types.LOADING_TRUE,
    payload: 'deleteUserLoading'
  });

  try {
    const res = await axios.delete(`/api/users/${credId}`);
    dispatch({
      type: types.DELETE_USER_SUCCESS,
      payload: res.data,
      error: null
    });
  } catch (error) {
    dispatch({
      type: types.DELETE_USER_SUCCESS,
      payload: {
        data: null,
        error: error?.response?.data?.error || error.message
      }
    });
  } finally {
    dispatch({ type: types.LOADING_FALSE, payload: 'deleteUserLoading' });
  }
};

export const fetchUserInvitationDetailsByToken = token => async dispatch => {
  try {
    dispatch({
      type: types.LOADING_TRUE,
      payload: 'fetchUserInvitationLoading'
    });
    const res = await axios.get(`/api/user-invitation/${token}`);
    const { data } = res;
    dispatch({
      type: types.FETCH_USER_INVITATION_DETAILS_SUCCESS,
      payload: data
    });
    return data;
  } catch (error) {
    dispatch({
      type: types.FETCH_USER_INVITATION_DETAILS_FAIL,
      payload: error
    });
  } finally {
    dispatch({
      type: types.LOADING_FALSE,
      payload: 'fetchUserInvitationLoading'
    });
  }
};

export const sendUserInvitation = payload => async dispatch => {
  try {
    dispatch({
      type: types.LOADING_TRUE,
      payload: 'sendUserInvitationLoading'
    });
    const res = await axios.post(`/api/user-invitation`, payload);
    const { data } = res;
    dispatch({
      type: types.SEND_USER_INVITATION_SUCCESS,
      payload: data
    });
    if (payload.tpOrganisationId || payload.tpOrganisationName) {
      dispatch({
        type: !payload.tpOrganisationId
          ? types.CREATE_TP_ORGANISATION_SUCCESS
          : types.UPDATE_TP_ORGANISATION_SUCCESS,
        payload: {
          _id: payload.tpOrganisationId,
          ...(payload.tpOrganisationName && {
            name: payload.tpOrganisationName
          }),
          programmes: payload.programmes
        }
      });
    }
    if (payload.employerOrganisationId || payload.employerOrganisationName) {
      dispatch({
        type: !payload.employerOrganisationId
          ? types.CREATE_EMPLOYER_ORGANISATION_SUCCESS
          : types.UPDATE_EMPLOYER_ORGANISATION_SUCCESS,
        payload: {
          _id: payload.employerOrganisationId,
          ...(payload.employerOrganisationName && {
            name: payload.employerOrganisationName
          })
        }
      });
    }
    return data;
  } catch (error) {
    dispatch({
      type: types.SEND_USER_INVITATION_FAIL,
      payload: error
    });
  } finally {
    dispatch({
      type: types.LOADING_FALSE,
      payload: 'sendUserInvitationLoading'
    });
  }
};

export const deleteUserInvitationDetailsByToken = token => async dispatch => {
  try {
    dispatch({
      type: types.LOADING_TRUE,
      payload: 'deleteUserInvitationLoading'
    });
    const res = await axios.delete(`/api/user-invitation/${token}`);
    const { data } = res;
    dispatch({
      type: types.DELETE_USER_INVITATION_SUCCESS,
      payload: data
    });
    return data;
  } catch (error) {
    dispatch({
      type: types.DELETE_USER_INVITATION_FAIL,
      payload: error
    });
  } finally {
    dispatch({
      type: types.LOADING_FALSE,
      payload: 'deleteUserInvitationLoading'
    });
  }
};

export const updateUserInvitation = (token, payload) => async dispatch => {
  try {
    dispatch({
      type: types.LOADING_TRUE,
      payload: 'updateUserInvitationLoading'
    });
    const res = await axios.put(`/api/user-invitation/${token}`, payload);
    const { data } = res;
    dispatch({
      type: types.UPDATE_USER_INVITATION_SUCCESS,
      payload: data
    });
    if (payload.tpOrganisationId || payload.tpOrganisationName) {
      dispatch({
        type: !payload.tpOrganisationId
          ? types.CREATE_TP_ORGANISATION_SUCCESS
          : types.UPDATE_TP_ORGANISATION_SUCCESS,
        payload: {
          _id: payload.tpOrganisationId,
          ...(payload.tpOrganisationName && {
            name: payload.tpOrganisationName
          }),
          programmes: payload.programmes
        }
      });
    }
    if (payload.employerOrganisationId || payload.employerOrganisationName) {
      dispatch({
        type: !payload.employerOrganisationId
          ? types.CREATE_EMPLOYER_ORGANISATION_SUCCESS
          : types.UPDATE_EMPLOYER_ORGANISATION_SUCCESS,
        payload: {
          _id: payload.employerOrganisationId,
          ...(payload.employerOrganisationName && {
            name: payload.employerOrganisationName
          })
        }
      });
    }
    return data;
  } catch (error) {
    dispatch({
      type: types.UPDATE_USER_INVITATION_FAIL,
      payload: error
    });
  } finally {
    dispatch({
      type: types.LOADING_FALSE,
      payload: 'updateUserInvitationLoading'
    });
  }
};

export const updateIncompleteProfile = payload => async dispatch => {
  try {
    dispatch({
      type: types.LOADING_TRUE,
      payload: 'updateIncompleteProfileLoading'
    });
    await axios.post('/api/users/update-incomplete-profile', payload);
    await dispatch(checkAuth());
  } finally {
    dispatch({
      type: types.LOADING_FALSE,
      payload: 'updateIncompleteProfileLoading'
    });
  }
};
