import axios from 'axios';

import {
  FETCH_CONTRACT_SUCCESS,
  FETCH_CONTRACT_FAIL,
  LOADING_FALSE,
  LOADING_TRUE
} from '../constants/actionTypes';

export const fetchContracts = (options = {}) => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'fetchContractsLoading'
    });
    const res = await axios.get('/api/contracts', { params: options });
    dispatch({
      type: FETCH_CONTRACT_SUCCESS,
      payload: res.data
    });
    return {};
  } catch (error) {
    dispatch({
      type: FETCH_CONTRACT_FAIL,
      payload: error.response.data.error
    });
    return { error };
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'fetchContractsLoading'
    });
  }
};
